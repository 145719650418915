import * as React from "react";
import {useCallback, useState} from "react";
import {useSelector} from "react-redux";

/**
 * Matches 11,2 ^\d{0,9}(\.\d{0,2})?$
 * Accepts 123456789. , 123456789.11 123456789.1
 */


// Matches 5,2 ^\d{0,3}\.?\d{1,2}$

export function InputNumber({
                                type,
                                initValue,
                                stateChanger,
                                setFocusedId,
                                dataTableItem,
                                getNextFocusableField,
                                field,
                                ...props
                            }) {
    const {isReadOnly} = useSelector(state => state.damageBill)
    const [value, setValue] = useState(initValue ? initValue : 0);

    function handleOnChange(e) {
        const {value: v} = e.target
        if (isValid(v)()) {
            setValue(v);
            stateChanger(field, v);
        }
    }

    function formatValue(e) {
        switch (type) {
            case 'deduction': {
                setValue(parseFloat(e.target.value).toFixed(0));
                return;
            }
            default: {
                setValue(parseFloat(e.target.value).toFixed(2));
            }

        }
    }

    const isValid = useCallback((v) => {
        switch (type) {
            case "quantity" : {
                return () => {
                    const r = new RegExp('^\\d{0,9}(\[\.\,]\\d{0,2})?$')
                    return r.test(v);
                }
            }
            case "price" : {
                return () => {
                    const r = new RegExp('^-?\\d{0,9}(\[\.\,]\\d{0,2})?$')
                    return r.test(v);
                }
            }
            case 'discount': {
                return () => {
                    const r = new RegExp('^\\d{0,3}(\[\.\,]\\d{0,2})?$')
                    if (isNaN(parseFloat(v))) return r.test(v);
                    return r.test(v) && parseFloat(v) <= 100
                }
            }
            case 'deduction': {
                return () => {
                    const r = new RegExp('^\\d{0,5}$')
                    return r.test(v)
                }
            }
            case "total_for_paying" : {
                return () => {
                    const r = new RegExp('^-?\\d{0,9}(\[\.\,]\\d{0,2})?$')
                    return r.test(v);
                }
            }
            default:
                return () => true
        }
    }, [type])

    return (<input type="number"
                   onKeyDown={(e) => {
                       if (e.key === 'Enter') {
                           e.preventDefault()
                           setFocusedId(getNextFocusableField(dataTableItem))
                       }
                   }}
                   data-table-item={`${dataTableItem[0]}${dataTableItem[1]}`}
                   name={props['name']}
                   value={value}
                   step="0.01"
                   className="form-control"
                   onChange={handleOnChange}
                   onBlur={formatValue}
                   disabled={isReadOnly}
                   {...props}
    />);
}
