// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import dateFormat from 'dateformat';
import {usePopper} from 'react-popper'
import {validateCustomDateFieldByParameters} from "./RepairDate";
import {useSelector} from "react-redux";

type Props = {
    errorMessage?: string,
};


export function Date({errorMessage: errorMessageProp}: Props) {
    const {bill, isReadOnly} = useSelector(state => state.damageBill)
    const [errorMessage, setErrorMessage] = useState<any>(errorMessageProp);
    const [validFieldClass, setValidFieldClass] = useState('');
    const [date, setDate] = useState(bill?.bill_date?.date ? dateFormat(bill.bill_date.date, "dd.mm.yyyy") : '')

    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });

    useEffect(() => {
        setErrorMessage(errorMessageProp)
        return () => {
            setErrorMessage(undefined)
            if (update) update()
        }
    }, [errorMessageProp, update])


    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setDate('')
        setErrorMessage(undefined)
        setValidFieldClass('')
        if (update) update()
    }, [bill, update])

    useEffect(() => {
        if (!bill?.bill_date?.date) {
            setDate('')
        } else {
            setDate(dateFormat(bill.bill_date.date, "dd.mm.yyyy"))
        }
        setErrorMessage(undefined)
        setValidFieldClass('')
    }, [bill])

    function handleChange(e) {
        setDate(e.target.value);
        setErrorMessage(undefined);
        setValidFieldClass('')
        update().then()
    }

    function handleBlur(e) {
        if (validateCustomDateFieldByParameters(e.target.value, e, '.', '210')) {
            setValidFieldClass('');
            setErrorMessage(undefined)
        } else {
            setValidFieldClass('is-invalid');
            setErrorMessage({isEmpty: 'Invalid date format'})
            update().then()
        }
        setDate(e.target.value);
    }

    return (
        <div className={"Field-Input-Drop"} style={{maxWidth: '111px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>Rechnungsdatum {bill?.does_car_dealer_use_rg_funds===true ? '*': ''}</Form.Label>
                <div className={"Field-Input-Text"}>
                    <Form.Control className={validFieldClass + " Input-text"} type="dateText"
                                  name="bill_date" id="bill_date"
                                  value={date}
                                  onBlur={(e) => handleBlur(e)}
                                  onChange={(e) => handleChange(e)}
                                  ref={inputRef}
                                  disabled={isReadOnly}
                    />
                </div>

                <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                     style={styles.popper} {...attributes.popper}>
                    {errorMessage && errorMessage.isEmpty}
                    <div className="arrow" data-popper-arrow style={styles.arrow} {...attributes.arrow}/>
                </div>


            </Form.Group>
        </div>
    );
}
