// @flow
import React, {useState} from "react";
import {Button} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {useDispatch, useSelector} from "react-redux";
import {GeneralTranslations} from "../../translations/General/GeneralTranslations";
import BillDocumentTranslations from "../../translations/BillDocument";
import {resolveBill, updateBill} from "../../features/DamageBill/bill.actions";
import {checkLoggedUser} from "../../features/Authentication/authSlice";


export function ResolveBillButton({formRef}) {

    const {bill, damage} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isMarked, setMarked] = useState<boolean>(false);

    const closeResolveModal = () => setOpen(false);

    const openResolveModal = () => setOpen(true);

    const resolve = async () => {
        await dispatch(checkLoggedUser());
        dispatch(resolveBill({
            billId: bill.damage_bill_id,
            isMarked
        }))
        closeResolveModal();
    }
    const saveBill = async () => {
        await dispatch(checkLoggedUser());
        if (formRef && formRef.current) {
            dispatch(
                updateBill(
                    {
                        billId: bill.damage_bill_id,
                        data: (() => {
                            const data = new FormData(formRef.current);
                            const l = {}
                            for (const pair of data.entries()) {
                                l[pair[0]] = pair[1];
                            }
                            return l;
                        })()
                    }
                )
            )
        }
    };
    const preSaveAndOpenModal = async () => {
        await dispatch(checkLoggedUser());
        saveBill();
        const paymentRecipient = formRef.current.querySelector('[name="billed_by_type"]');
        const autohouse = formRef.current.querySelector('[name="billed_by_car_dealer_id"]');
        const billNumber = formRef.current.querySelector('[name="bill_number"]');
        const km = formRef.current.querySelector('[name="vehicle_mileage_after_reparation"]');
        if (paymentRecipient?.value.trim() !== '' && autohouse?.value.trim() !== '' && billNumber?.value.trim() !== '' && km?.value.trim() !== '') {
            openResolveModal();
        }

    }

    return (
        <>
            <Modal className={''} show={isOpen} onHide={closeResolveModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{BillDocumentTranslations.de.auth_bill_modal_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            value={isMarked}
                            onChange={(e) => setMarked(e.target.checked)}
                            type={'checkbox'}
                            id={`default-checkbox`}
                            label={BillDocumentTranslations.de.auth_bill_modal_mark_closed}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"btn-success"}
                            style={{alignSelf: "flex-end", width: 'auto'}}
                            onClick={resolve}>
                        {BillDocumentTranslations.de.auth_bill_modal_button}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button
                size={'sm'}
                style={{
                    background: '#FF7700',
                    borderColor: '#FF7700',
                    display: damage && (damage.release_status === 0 || damage.release_status === 3) ? 'inline-block' : 'none'
                }}
                onClick={preSaveAndOpenModal}>
                {GeneralTranslations.de.label_resolve}
            </Button>
        </>
    );
}
