// @flow
import * as React from 'react';
import {Button} from "react-bootstrap"
import {checkLoggedUser} from "../../features/Authentication/authSlice";
import {useDispatch} from "react-redux";

export function SaveBillB({formRef}) {
    const dispatch = useDispatch();

    const onClickForm = async () => {
        await dispatch(checkLoggedUser());
        if (formRef && formRef.current) {
            formRef.current.requestSubmit();
        }
    };

    return (
        <Button variant="primary"
                size={'sm'}
                onClick={onClickForm}>Rechnung speichern</Button>
    );
}
