import React, {createContext, useState} from "react";
import BillDocumentTranslations from "../translations/BillDocument";

export const STATUS = {
    VIEW: 1,
    EDIT: 2,
    SAVING: 3,
    SAVED: 4,
    LOADING: 5
};
export const BILL_PROCESSING_STATUSES = {
    BILL_RESOLVED: -2,
    DOCUMENT_DELETED: -1,
    DOCUMENT_CREATED: 1,
    DOCUMENT_SENT_TO_OCR: 2,
    DOCUMENT_PARSED_BY_OCR: 3,
    DOCUMENT_REQUEST_MANUAL_SETUP: 4,
    BILL_IN_PROGRESS: 5,
    BILL_READY_FOR_PAYMENT: 6,
    BILL_AUTHORIZED: 7,
    BILL_PAYED: 8
};
export const BILL_PROCESSING_STATUS_LABELS = {
    "-2": BillDocumentTranslations.de.bill_status_resolved,
    "-1": BillDocumentTranslations.de.bill_status_doc_deleted,
    "1": BillDocumentTranslations.de.bill_status_doc_created,
    "2": BillDocumentTranslations.de.bill_status_doc_sent,
    "3": BillDocumentTranslations.de.bill_status_doc_parsed,
    "4": BillDocumentTranslations.de.bill_status_setup,
    "5": BillDocumentTranslations.de.bill_status_progress,
    "6": BillDocumentTranslations.de.bill_status_for_payment,
    "7": BillDocumentTranslations.de.bill_status_authorized,
    "8": BillDocumentTranslations.de.bill_status_payed,
};
/**
 * @deprecated replaced with bill.slice.js
 * @type {React.Context<unknown>}
 */
const StatusContext = createContext();
export default StatusContext;

/**
 * @deprecated replaced with bill.slice.js
 * @type {React.Context<unknown>}
 */
export const StatusSetContext = createContext();


/**
 * @deprecated replaced with bill.slice.js
 * @type {React.Context<unknown>}
 */
export function StatusProvider({children}) {
    const [appStatus, setAppStatus] = useState({appStatus: STATUS.VIEW});

    return (
        <StatusContext.Provider value={ appStatus }>
            <StatusSetContext.Provider value={ setAppStatus }>
                {children}
            </StatusSetContext.Provider>
        </StatusContext.Provider>
    );
}
