import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Modal from "react-modal";
import {ListRow} from "./ListRow";
import useApi from "../../hooks/useApi";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";
import "../../components/users/Users.css";
import InView from "../InView";
import {createTeam, deleteTeam, getTeams, updateTeam} from "../../services/API/teams.service";
import {TeamForm} from "./UserForm";
import {getUser} from "../../services/API/users.service";

const initState = {
    id: 0,
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    title_id: 0,
    email: '',
    mobile: '',
    phone: '',
    address: '',
    zip_code_id: 0,
    language_id: 0,
    is_active: true,
    is_deleted: false
};

export function List() {
    let [userState, setUserState] = useState(initState);

    const [isOpen, setIsOpen] = useState(false);
    const {data: addTeamData, request: addTeamRequest, error: addTeamError} = useApi(createTeam);
    const {data: deleteTeamData, request: deleteTeamRequest, error: deleteTeamError} = useApi(deleteTeam);
    const {data: updateUserData, request: updateUserRequest, error: updateUserError} = useApi(updateTeam);

    const [keyword] = useState('');
    const [statuses] = useState([]);
    const [, hasError, allTeams, hasMore, nextPage, refresh] = useSearchPaginator(keyword, statuses, getTeams);
    const [leaderNames, setLeaderNames] = useState([])

    useEffect(() => {

        if (allTeams) {
            Promise.all(allTeams.map(({team_lead_user_id}) => getUser(team_lead_user_id)))
                .then(l => {
                    setLeaderNames(l.map(({data}) => data.username || ''))
                })

        }

    }, [allTeams])


    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);


    useEffect(() => {
        if (addTeamData && addTeamData.code === 200) {
            refresh();
        } else if (addTeamError) {
            console.log(addTeamError)
        }
    }, [addTeamData, addTeamError, refresh]);

    useEffect(() => {
        if (deleteTeamData && deleteTeamData.code === 200) {
            refresh();
        } else if (deleteTeamError) {
            console.log(deleteTeamError)
        }
    }, [deleteTeamData, deleteTeamError, refresh]);

    useEffect(() => {
        if (updateUserData !== null && updateUserData.code === 200) {
            refresh();
        } else if (updateUserError) {
            alert(updateUserError);
        }
    }, [updateUserData, updateUserError, refresh]);


    const toggleModal = useCallback(() => {
        if (!isOpen) {
            setUserState({...initState});
        }
        setIsOpen(isOpen => !isOpen);
    }, [isOpen]);


    const onTeamFormSubmit = useCallback((data) => {
        const team = {
            name: data.get('name'),
            department: +data.get('department'),
            team_lead_user_id: +data.get('team_lead_user_id'),
        }
        if (data.get('id') > 0) {
            updateUserRequest({team, id: data.get('id')});
            toggleModal(!isOpen);
        } else {
            addTeamRequest({team});
            toggleModal(!isOpen);
        }
    }, [updateUserRequest, addTeamRequest, isOpen, toggleModal]);

    const loadForEdit = useCallback((item) => {
        console.log('edit', item)
        setUserState({...item});
        setIsOpen(true);
    }, []);


    const deleteTeamAction = useCallback((item) => {
        deleteTeamRequest({id: item.id})
    }, []);

    return (<div className={"list-container"}>
        <div className={"toolbox"}>
            <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModal}>Add team</button>
        </div>
        <div>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Team lead</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>

                {allTeams !== null && allTeams.map((item, index) => {
                    const i = {
                        ...item, fullname: leaderNames.length > index ? leaderNames[index] : ''
                    }
                    return (index !== Math.round(allTeams.length / 2)) ? <ListRow key={"user_item_" + i.id} item={i}
                                                                                  onEdit={loadForEdit.bind(this, i)}
                                                                                  onDelete={deleteTeamAction.bind(this, i)}
                    /> : <InView as={ListRow} key={"user_item_" + i.id} item={i}
                                 onEdit={loadForEdit.bind(this, i)}
                                 onDelete={deleteTeamAction.bind(this, i)}
                                 onceOnIntersect={requestNextPage}
                    />

                })

                }


                </tbody>
            </table>
        </div>
        <Modal isOpen={isOpen}
               onRequestClose={toggleModal}
               overlayClassName={"modal-overlay"}
               className={"modal-wide"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="New team">
            <TeamForm toggleModal={toggleModal} formSubmit={onTeamFormSubmit} fields={userState}/>
        </Modal>
    </div>);
}
