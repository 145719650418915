//@flow
/**
 * Global configuration about axios client
 * It handles credentials for each request timeout
 * It has direct access to redux store and AuthenticationSlice for handling authentication for the requests
 *
 */


import axios from "axios";
import {authUrl} from "../services/ApiService";
import {checkLoggedUser, logout} from "../features/Authentication/authSlice";

/**
 * Headers used by all requests that use this client
 * @type {{"Content-type": string, Accept: string}}
 */
export const headers = {
    'Accept': 'application/json',
    'Content-type': 'multipart/form-data'
}

/**
 * Custom axios Instance, use only this in the application to make requests.
 * Provides Auth Bearer automatically when using this.
 * @type {AxiosInstance}
 */
export const client = axios.create({
    withCredentials: true,
    headers: {
        ...headers
    },
    timeout: 20000 // 20 seconds
})

// helper variables for delaying request until the next valid access token has been fetched
let hasRequestedAccessToken = false;

type RequestCallback =  (token: string) => void

// Stores an array of callback functions that execute requests
let requestSubscribers: [RequestCallback] = [];


/**
 * Connects Redux Store with our axios instance
 * Provides interceptors for attaching access token to requests & handling request of an expired access token
 * @param store
 */
export const setupAxiosClient = (store) => {

    // Accessing Redux Store
    const {dispatch} = store;


    /**
     * For each request we are sending, attach the access token of logged user
     */
    client.interceptors.request.use(
        (config) => {
            const {loggedUser: {token}} = store.getState().auth
            if (token) {
                    config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );


    /**
     * For each response we are receiving, if it returns a failed status or error code
     * try to ask for new access token or log user out
     */
    client.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            // if request was canceled by the application just ignore and pass the error
            if (err?.message === 'cancel') return Promise.reject(err)

            // if config of error does not exist just ignore and pass the error
            const originalConfig = err.config;
            if (!originalConfig) return Promise.reject(err);


            /**
             * we are interested in requesting for new access token only if we have not tried before for this specific request (infinite loop)
             * or user is  not trying to log in or
             */
            if (originalConfig.url !== `${authUrl}/login` && originalConfig.url !== `${authUrl}/accesstokenrefresh` && err.response) {


                // Access Token was expired or does not exist
                if ((err.response.status === 401 || err.response.status === 404) && !originalConfig._retry) {

                    // flag that we tried to resolve the request
                    originalConfig._retry = true;


                    /**
                     * Only allow one request to ask for new access token
                     * Once you get access token (and saved on redux store) allow all other request to be re-submitted
                     */
                    if (!hasRequestedAccessToken) {
                        hasRequestedAccessToken = true;
                        dispatch(checkLoggedUser()).unwrap()
                            .then(({loggedUser}) => {

                                /**
                                 * On receive new token successfully
                                 */
                                executeRequestsOnHold(loggedUser.token)
                            })
                            .catch((_error) => {
                                return Promise.reject(_error);
                            })
                    }

                    // In the meantime, store the request and re-run it once you have the token
                    return new Promise(async resolve => {
                        subscribeForAccessToken(token => {
                            // replace the expired token and retry
                            originalConfig.headers['Authorization'] = 'Bearer ' + token;
                            resolve(client(originalConfig));
                        });
                    });
                } else if (err.response.status === 401) { // Refresh Token has expired, we are using 401 for this reason (convention)
                    dispatch(logout())
                }
            }
            return Promise.reject(err);
        }
    );
}

/**
 * Saves the callback function which contains the configuration of the request
 * in order to call it later once the access token is available
 * @param cb
 */
function subscribeForAccessToken(cb: RequestCallback): void {
    requestSubscribers.push(cb);
}


/**
 * Calls all callbacks that are saved in requestSubscribers. This means that all requests that are on hold will be executed
 * @param token string as access token to be used in configuration of requests that are on hold
 */
function executeRequestsOnHold(token: string): void {
    requestSubscribers.forEach((cb: RequestCallback) => cb(token));
    requestSubscribers = [];

    // reset back to default value to allow repeating of this behavior
    hasRequestedAccessToken = false;
}
