// @flow
import {AxiosResponse} from "axios";
import {sepaUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

export function getSepaFiles(pageNumber?: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${sepaUrl}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

export function getSepaXmlFile(sepaFileId: number, cancelToken?: any): Promise<AxiosResponse<{ file: string, filename: string, downloads_counter: number }>> {
    return client.get<{ file: string, filename: string, downloads_counter: number }>(`${sepaUrl}/${sepaFileId}/xml`, {
        headers: {
            ...headers,
        },
        cancelToken
    })
}

export function getSepaFileDownloads(sepaFileId: number, cancelToken?: any): Promise<AxiosResponse<{ file: string, filename: string, downloads_counter: number }>> {
    return client.get<{ file: string, filename: string, downloads_counter: number }>(`${sepaUrl}/${sepaFileId}/downloads`, {
        headers: {
            ...headers,
        },
        cancelToken
    })
}