import React from 'react';
import {makeStyles, withStyles} from '@mui/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
    label: {
        height: "14px",
        alignSelf: "stretch",
        flexGrow: 0,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.2px",
        textAlign: "left",
        color: "#a4a9ad",
        width: "auto",
        display: "block",
        marginLeft: "0px",
        marginTop: "-2px",
    },
    fieldInputSwitch: {
        width: "96px",
        alignSelf: "stretch",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        gap: "10px",
        padding: 0,
    },
    frame9: {
        height: "26px",
        alignSelf: "stretch",
        flexGrow: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "15px",
        padding: 0,
    }

}));

const IOSSwitch = withStyles((theme) => ({

    label: {
        height: "14px",
        alignSelf: "stretch",
        flexGrow: 0,
        fontFamily: "Roboto",
        fontSize: "14px!important",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.2px",
        textAlign: "left",
        color: "#a4a9ad",
        width: "auto",
        display: "block",
        marginLeft: "0px",
        marginTop: "-2px",
    },
    root: {
        width: 36,
        height: 20,
        padding: 0,
        margin: "23px 8px 8px",
        fontSize: "14px",
        position: "absolute"
    },
    switchBase: {
        padding: 1,
        "&.Mui-checked": {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#52d869",
            opacity: 1
        },
        "&.Mui-disabled": {
            color: theme.palette.grey[400],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
            border: 'none'
        },
        // '&$checked': {
        //     transform: 'translateX(16px)',
        //     color: theme.palette.common.white,
        //     // '& + $track': {
        //     //     backgroundColor: '#52d869',
        //     //     opacity: 1,
        //     //     border: 'none',
        //     // },
        // },
        // '&$focusVisible $thumb': {
        //     color: '#52d869',
        //     border: '6px solid #fff',
        // },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function CustomizedSwitches() {
    const {isReadOnly, billTax} = useSelector(state => state.damageBill)
    const classes = useStyles();

    // const handleIncludeTaxChange = (event) => {
    //     dispatch(includeTax(event.target.checked))
    // };

    return (
        <div className={"includeTaxHolder"}>
            <FormControl component="fieldset" className={classes.fieldInputSwitch}>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        label="Inkl. MwSt."
                        labelPlacement="top"
                        className={classes.label}
                        control={<IOSSwitch
                            checked={parseFloat(billTax) !== 0 && !isNaN(parseFloat(billTax))}
                            // onChange={handleIncludeTaxChange}
                            name="is_tax_included"
                            disabled={isReadOnly}
                            value={1}
                        />}
                    />
                </FormGroup>
            </FormControl>
        </div>
    );
}
