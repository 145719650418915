// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import dateFormat from 'dateformat';
import {useSelector} from "react-redux";

function is_invalid_february(data, separator, order) {
    const strings = data.split(separator);

    if (parseInt(strings[order.indexOf("1")]) === 2 || strings[order.indexOf("1")] === "02") {
        if (((strings[order.indexOf("0")] % 4 === 0) && (strings[order.indexOf("0")] % 100 !== 0))
            || (strings[order.indexOf("0")] % 400 === 0)) {
            if (strings[order.indexOf("2")] > 29) {
                return false;
            }
        } else {
            if (strings[order.indexOf("2")] > 28) {
                return false;
            }
        }
    }

    return true;
}

export function validateCustomDateFieldByParameters(data, date_obj, separator, order) {
    let validField = true;
    let date_regex_string;
    let date_regex;
    switch (order) {
        case '012':
            date_regex_string = '(19|20)\\d{2}\\' + separator + '(0[1-9]|1[0-2])\\' + separator + '(0[1-9]|1\\d|2\\d|3[01])$';
            date_regex = new RegExp(date_regex_string);
            break;
        case '120':
            date_regex_string = '^(0[1-9]|1[0-2])\\' + separator + '(0[1-9]|1\\d|2\\d|3[01])\\' + separator + '(19|20)\\d{2}$';
            date_regex = new RegExp(date_regex_string);
            break;
        case '210':
            date_regex_string = '^(0[1-9]|1\\d|2\\d|3[01])\\' + separator + '(0[1-9]|1[0-2])\\' + separator + '(19|20)\\d{2}$';
            date_regex = new RegExp(date_regex_string);
            break;
        default:
            console.log('not covered order');
            date_regex_string = '^(0[1-9]|1\\d|2\\d|3[01])\\' + separator + '(0[1-9]|1[0-2])\\' + separator + '(19|20)\\d{2}$';
            date_regex = new RegExp(date_regex_string);
            break;
    }

    if (data.length === 10) {
        if (date_regex.test(data) && is_invalid_february(data, separator, order)) {
            // date_obj.css("color", "green");
        } else {
            validField = false;
            // date_obj.css("color", "red");
        }
    } else if (data.length > 10) {
        validField = false;
        // date_obj.css("color", "red");
    } else {
        validField = false;
        // date_obj.css("color", "inherit");
    }
    return validField;
}

export function RepairDate() {
    const {bill, isReadOnly} = useSelector(state => state.damageBill);
    const [validFieldClass, setValidFieldClass] = useState('');
    const [repairDate, setRepairDate] = useState(bill?.repair_date?.date ? dateFormat(bill.repair_date.date, "dd.mm.yyyy") : '')


    useEffect(() => {
        setRepairDate(bill?.repair_date?.date ? dateFormat(bill.repair_date.date, "dd.mm.yyyy") : '')
        setValidFieldClass('')
    }, [bill])

    function handleChange(e) {
        setValidFieldClass('')
        setRepairDate(e.target.value);
    }

    function handleBlur(e) {
        if (validateCustomDateFieldByParameters(e.target.value, e, '.', '210')) {
            setValidFieldClass('');
        } else {
            setValidFieldClass('is-invalid');
        }
        setRepairDate(e.target.value);
    }

    return (
        <div className={"Field-Input-Drop"} style={{maxWidth: '94px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>Reparaturdatum</Form.Label>
                <div className={"Field-Input-Text"}>
                    <Form.Control className={validFieldClass + " Input-text"} type="dateText"
                                  name="repair_date" id="repair_date"
                                  value={repairDate}
                                  onBlur={(e) => handleBlur(e)}
                                  onChange={(e) => handleChange(e)}
                                  disabled={isReadOnly}
                    />
                </div>


            </Form.Group>
        </div>
    );
}
