// @flow
import React, {FC, useRef, useState} from "react";
import {BILL_PROCESSING_STATUSES} from "../../../StatusContext";
import {
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import {FormBillDetails2} from "../FormBillDetails2";
import {FormBillItems} from "../FormBillItems";
import {ManualSetUpFilePicker} from "./ManualSetUpFilePicker";
import {useSelector} from "react-redux";
import {theme} from "../SameAsBilledByCarDealerToggle";
import {numberFormat} from "../FormBillItemsRow";
import {Overlay, Tooltip} from "react-bootstrap";
import {convertForeignToDomesticCurrency} from "../../../../utils/damageItemsCalculations";


export const FormBills: FC = () => {
    const {bill, currency} = useSelector(state => state.damageBill);
    const [show, setShow] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const target = useRef(null);
    const showConvertedValue = (e, value) => {
        if (!currency?.foreignCurrency || !currency?.rate) return;
        const convertedValue =  convertForeignToDomesticCurrency(value,currency?.rate);
        target.current = e.target;
        setTooltipText(`${numberFormat(convertedValue)} ${currency?.domesticCurrency}`);
        setShow(true);
    };

    const hideConvertedValue = (e) => {
        target.current = e.target;
        setTooltipText('');
        setShow(false);
    };
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Overlay target={target.current} show={show} placement="top">
                        <Tooltip className={'currency_rates'} id="currency-tooltip">{tooltipText}</Tooltip>
                    </Overlay>
                    {bill && bill.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_REQUEST_MANUAL_SETUP
                        ? <ManualSetUpFilePicker/>
                        : <>
                            <FormBillDetails2 showConvertedValue={showConvertedValue}
                                              hideConvertedValue={hideConvertedValue}/>
                            <FormBillItems showConvertedValue={showConvertedValue}
                                           hideConvertedValue={hideConvertedValue}/>
                        </>}
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
}
