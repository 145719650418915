//@flow
import React, {useEffect, useState} from 'react'
import toast, {Toaster} from "react-hot-toast";
import rgisLogo from "../../assets/images/RealGrantLogo.svg";
import Form from "react-bootstrap/Form";
import {inputErrorStyle} from "../Login";
import Button from "react-bootstrap/Button";
import {Link, useHistory} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";
import useApi from "../../hooks/useApi";
import {checkResetPasswordToken, changePassword} from "../../services/API/auth.service";

const SetNewPassword = () => {

    const query = useQuery();
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const [tokenError, setTokenError] = useState<string>('');
    const history = useHistory();

    const {request, error} = useApi(checkResetPasswordToken);

    useEffect(() => {
        if(error) {
            setTokenError('Your token to reset your password has expired.')
        }
    }, [error]);

    useEffect(() => {
        const token = query.get('token');
        if (token) {
            const fd = new FormData();
            fd.append('token', token);
            request(fd);
        } else {
            setTokenError('Your token to reset your password has expired.')
        }

    }, [query, request])

    const onLoginSubmit = async (e: Event) => {
        e.preventDefault();

        if (password.trim() === '' || cPassword.trim() === '') {
            setFailed(true);
            toast.error('Password(s) are empty')
            return;
        }else if(password!== cPassword) {
            setFailed(true);
            toast.error('Passwords are not identical')
            return;
        }


        toast.promise(changePassword(query.get('token'), password, cPassword), {
            loading: 'Requesting ...',
            success: () => {
                return 'Password has been successfully reset'
            }, error: (err) => {
                const {password} = err.response.data.message
                return Object.values(password).reduce((acc, val) => acc +` ${val}`,'');
            }
        }).then(_ => {
            history.push('/login')
        })
    }
    return (
        <>
            <Toaster position={'bottom-center'}/>
            <main style={{
                width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left',
            }}>
                <div style={{
                    width: '100%', padding: '1rem', maxWidth: '400px', display: "flex", flexDirection: 'column'
                }}>

                    <img style={{
                        width: '100%', marginBottom: '1.5rem'
                    }} alt={'Real Garant logo'} src={rgisLogo}/>

                    {!tokenError ?
                    <Form style={{
                        display: 'flex', flexDirection: 'column', gap: '.8em'
                    }} onSubmit={onLoginSubmit}>
                        <Form.Group style={{margin: 0}} controlId="password">
                            <Form.Label>New password</Form.Label>
                            <Form.Control type={showPassword ? 'text' : 'password'}
                                          style={failed ? inputErrorStyle : {}} value={password}
                                          onChange={(e) => {
                                              setPassword(e.target.value)
                                          }}/>
                        </Form.Group>
                        <Form.Group style={{margin: 0}} controlId="confirmPassword">
                            <Form.Label>Confirm new password</Form.Label>
                            <Form.Control type={showPassword ? 'text' : 'password'}
                                          style={failed ? inputErrorStyle : {}} value={cPassword}
                                          onBlur={() => {
                                              setFailed(password !== cPassword);
                                              password !== cPassword && toast.error('Passwords are not identical')
                                          }}
                                          onChange={(e) => {
                                              setCPassword(e.target.value)
                                          }}/>
                        </Form.Group>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '.5em'
                        }}>
                            <input name={'show-password'} id={'show-password'} type={'checkbox'} checked={showPassword}
                                   onChange={() => setShowPassword(a => !a)}/>
                            <label style={{margin: 0}} htmlFor={'show-password'}>Show password</label>
                        </div>
                        <Button variant="primary" style={{
                            width: '100%'
                        }} type="submit">
                            Change password
                        </Button>
                    </Form>
                        :
                        <>
                        {tokenError}
                            <Link to={'/reset-password/request'}>Request instructions again</Link>
                        </>
                        }
                </div>
            </main>
        </>)
}

export default SetNewPassword;