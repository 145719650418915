import {createSlice} from "@reduxjs/toolkit";
import type {BillState} from "../../components/BillContext";
import {
    fetchBillsLinkedToDamageFullFilled,
    fetchBillsLinkedToFahrzeugidentnummerFullFilled,
    fetchBillsLinkedToSchadensnummerFullFilled,
    fetchBillsLinkedToVertragsvereinbarungFullFilled,
    fetchCarDealerSoldBillFullFilled,
    fetchCarDealerSoldPolicyFullFilled,
    fetchContractSpentAmountFullFilled,
    fetchDamageBill,
    fetchDamageBillFailed,
    fetchDamageBillFullFilled,
    fetchDamageFullFilled,
    fetchExternalProviderFullFilled,
    fetchMaterialWageFullFilled,
    fetchPreviousDamageFullFilled,
    fetchWarrantyContractFullFilled,
    markBillAuthorizedForPayment,
    markBillAuthorizedForPaymentFailed,
    resolveBill,
    resolveBillFailed,
    updateBill,
    updateBillBackToPayment,
    updateBillFailed,
    updateBillForPayment,
    updateBillForPaymentFailed,
    fetchCurrencyRatesFullFilled, updateCurrencyFullFilled
} from "./bill.actions";
import {BILL_PROCESSING_STATUSES} from "../../components/StatusContext";
import {
    BILLED_BY_TYPE_UNSET,
    getAutoCompleteBehavior,
} from "../../components/BillContext";


export const initialState: BillState = {
    bill: undefined,
    billsWithSameDamage: [],
    errors: {
        formValidation: undefined,
    },
    paymentTotal: '',
    billTax: '',
    isLoading: false,
    error: '',
    damage: undefined,
    warrantyContract: undefined,
    materialWage: undefined,
    previousDamage: undefined,
    carDealerWhoSentBill: undefined,
    carDealerWhoSoldPolicy: undefined,
    externalProvider: undefined,
    isReadOnly: false,
    carDealerSource: BILLED_BY_TYPE_UNSET,
    isSold: false,
    isSoldToggleDisabled: false,
    isShopDisabled: true,
    shopAutoCompleteValue: '',
    billEmail: '',
    shopAutoCompleteId: null,
    billWithError: undefined,
    allowPaymentButton: false,
    allowAuthorizeButton: false,
    allowDeclineButton: false,
    allowPDFExport: false,
    maxPaymentLimit: 0,
    allowResolveButton: false,
    isManualSetup: false,
    isTaxIncluded: false,
    validationCodes: [],
    payout_inconsistencies: '',
    validation_codes_warning: '',
    is_damage_closed_by_this_bill: '',
    damageBillId: undefined,
    billsForMerge: {},
    currency: undefined
};

const billSliceName = 'damageBill';

const billSlice = createSlice({
    name: billSliceName, initialState, reducers: {
        updateTax(state: BillState, {payload}) {
            if (!state.bill) return;
            state.billTax = payload;
        },
        setShop(state: BillState, {payload}) {
            state.shopAutoCompleteValue = payload.value;
            state.shopAutoCompleteId = payload.id;
        },
        includeTax(state: BillState, {payload}) {
            state.isTaxIncluded = payload
        },
        updateBillEmail(state: BillState, {payload}) {
            state.billEmail = payload
        },
        updateCarDealerSource(state: BillState, {payload}) {
            return getAutoCompleteBehavior({
                ...state, carDealerSource: payload
            })
        },
        updateEditAccess(state: BillState, {payload}) {
            state.lockedBy = payload.bill_unlocked_for_edit_by_user_id;
            state.hasEditRight = payload.bill_unlocked_for_edit_by_user_id === payload.fetchedBy;
            state.isReadOnly = payload.bill_unlocked_for_edit_by_user_id === null || payload.bill_unlocked_for_edit_by_user_id !== payload.fetchedBy || state.bill.processing_status > BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS;
        },
        updateTotalValue(state: BillState, {payload}) {
            state.paymentTotal = payload
        },
        setDamageBillId(state: BillState, {payload}){
            state.damageBillId=payload
        },
        toggleBillsForMerge(state: BillState, {payload}){
            state.billsForMerge[payload]=!state.billsForMerge[payload];
        },
        markAsSentToMerge(state: BillState, {payload}) {
            state.billsWithSameDamage = state.billsWithSameDamage.map((bill) =>
                payload.includes(bill.id.toString()) ? {
                    ...bill,
                    processing_status: BILL_PROCESSING_STATUSES.DOCUMENT_REQUEST_MANUAL_SETUP
                } : bill)


            state.billsForMerge = []
        },
        resetBillsForMerge(state: BillState) {
            state.billsForMerge = {}
        },
        resetBillsPreparedForMerge(state: BillState) {
            state.billsWithSameDamage = {}
        },

    }, extraReducers: builder => {
        builder
            .addCase(fetchDamageBill, (state: BillState) => {
                state.isLoading = true;
            })
            .addCase(fetchDamageBillFullFilled, (state: BillState, {payload}) => {
                return {
                    ...initialState,
                    isLoading: false,
                    bill: {...payload},
                    paymentTotal: payload.total_for_payment || '',
                    billTax: payload.tax,
                    isTaxIncluded: !!payload.is_tax_included,
                    lockedBy: payload.bill_unlocked_for_edit_by_user_id,
                    hasEditRight: payload.bill_unlocked_for_edit_by_user_id === payload.fetchedBy,
                    hadEditOption: payload.processing_status < BILL_PROCESSING_STATUSES.BILL_PAYED && payload.processing_status >= BILL_PROCESSING_STATUSES.DOCUMENT_CREATED || payload.processing_status === BILL_PROCESSING_STATUSES.BILL_RESOLVED,
                    isReadOnly: payload.bill_unlocked_for_edit_by_user_id === null || payload.bill_unlocked_for_edit_by_user_id !== payload.fetchedBy || payload.processing_status > BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS,
                    allowPaymentButton: payload.does_car_dealer_use_rg_funds === true && (payload.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_PARSED_BY_OCR || (payload.processing_status === BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS)),
                    allowAuthorizeButton: payload.does_car_dealer_use_rg_funds === true && ((payload.processing_status === BILL_PROCESSING_STATUSES.BILL_READY_FOR_PAYMENT && payload.fetchedBy !== payload.prepared_for_payment_by_user_id) || (payload.processing_status === BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS)),
                    allowDeclineButton: payload.does_car_dealer_use_rg_funds !== null && (payload.processing_status === BILL_PROCESSING_STATUSES.BILL_READY_FOR_PAYMENT || payload.processing_status === BILL_PROCESSING_STATUSES.BILL_RESOLVED || (payload.processing_status === BILL_PROCESSING_STATUSES.BILL_AUTHORIZED && payload.fetchedBy === payload.authorized_by_user_id)),
                    allowResolveButton: payload.does_car_dealer_use_rg_funds === false && payload.processing_status === BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS,
                    allowPDFExport: payload.processing_status === BILL_PROCESSING_STATUSES.BILL_PAYED,
                    maxPaymentLimit: payload.max_payment_limit,

                    /**
                     * If status is 'Manual setup' then don't show up save button
                     */
                    isManualSetup: payload.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_REQUEST_MANUAL_SETUP,

                    isSold: payload.is_car_dealer_same_as_from_policy,
                    isShopDisabled: payload.is_car_dealer_same_as_from_policy,
                    carDealerSource: payload.billed_by_type === null ? -1 : payload.billed_by_type,

                    billEmail: payload.default_email_address || '',
                    merge_ids: payload.merge_ids,
                    currency: {
                        damageBillVersionId: payload.currency?.damageBillVersionId,
                        domesticCurrency: payload.currency?.domesticCurrency,
                        foreignCurrency: payload.currency?.foreignCurrency,
                        id: payload.currency?.id,
                        rate: payload.currency?.rate
                    }
                }
            })
            .addCase(fetchDamageBillFailed, (state: BillState, {payload}) => {
                state.isLoading = false;
                state.error = payload
            })

            .addCase(fetchDamageFullFilled, (state: BillState, {payload}) => {
                state.damage = payload
            })

            .addCase(fetchBillsLinkedToDamageFullFilled, (state: BillState, {payload}) => {
                state.billsWithSameDamage = [...payload]
            })
            .addCase(fetchBillsLinkedToSchadensnummerFullFilled, (state: BillState, {payload}) => {
                state.billsWithSameDamage = [...payload]
            })
            .addCase(fetchBillsLinkedToVertragsvereinbarungFullFilled, (state: BillState, {payload}) => {
                state.billsWithSameDamage = [...payload]
            })
            .addCase(fetchBillsLinkedToFahrzeugidentnummerFullFilled, (state: BillState, {payload}) => {
                state.billsWithSameDamage = [...payload]
            })

            .addCase(fetchWarrantyContractFullFilled, (state: BillState, {payload}) => {
                state.warrantyContract = {
                    ...payload,
                    spentAmount: state?.warrantyContract?.spentAmount
                }
            })


            .addCase(fetchContractSpentAmountFullFilled, (state: BillState, {payload}) => {
                if (state.warrantyContract) {
                    state.warrantyContract.spentAmount = payload
                } else {
                    state.warrantyContract = {
                        spentAmount: payload
                    }
                }
            })

            .addCase(fetchMaterialWageFullFilled, (state: BillState, {payload}) => {
                state.materialWage = payload;
            })

            .addCase(fetchPreviousDamageFullFilled, (state: BillState, {payload}) => {
                state.previousDamage = payload;
            })

            .addCase(fetchCarDealerSoldPolicyFullFilled, (state: BillState, {payload}) => {
                return getAutoCompleteBehavior({
                    ...state,
                    carDealerWhoSoldPolicy: payload
                })
            })
            .addCase(fetchCarDealerSoldBillFullFilled, (state: BillState, {payload}) => {
                return getAutoCompleteBehavior({
                    ...state,
                    carDealerWhoSentBill: payload
                })
            })
            .addCase(fetchExternalProviderFullFilled, (state: BillState, {payload}) => {
                return getAutoCompleteBehavior({
                    ...state,
                    externalProvider: payload
                })
            })

            .addCase(updateBillFailed, (state: BillState, {payload}) => {
                state.errors.formValidation = payload?.errors
                state.isLoading = false;
            })

            .addCase(fetchCurrencyRatesFullFilled, (state: BillState, {payload}) => {
                state.currency = {
                    damageBillVersionId: payload.damageBillVersionId,
                    domesticCurrency: payload.domesticCurrency,
                    foreignCurrency: payload.foreignCurrency,
                    id: payload.id,
                    rate: payload.rate
                }
            })

            .addCase(updateCurrencyFullFilled, (state: BillState, {payload}) => {
                state.currency = {
                    damageBillVersionId: payload.data.damageBillVersionId,
                    domesticCurrency: payload.data.domesticCurrency,
                    foreignCurrency: payload.data.foreignCurrency,
                    id: payload.data.id,
                    rate: payload.data.rate
                }
            })

            .addMatcher(
                action => [updateBillForPaymentFailed.type, markBillAuthorizedForPaymentFailed.type, resolveBillFailed.type].includes(action.type),
                (state: BillState, {payload}) => {
                    state.isLoading = false;
                    state.validationCodes = payload.validationCodes || [];
                    state.payout_inconsistencies = payload.payout_inconsistencies || '';
                    state.validation_codes_warning = payload.validation_codes_warning || '';
                    state.is_damage_closed_by_this_bill = payload.is_damage_closed_by_this_bill || '';
                }
            )

            .addMatcher(
                action => [updateBillForPayment.type, updateBillBackToPayment.type, updateBill.type, markBillAuthorizedForPayment.type, resolveBill.type].includes(action.type),
                state => {
                    state.isLoading = true;
                    state.validationCodes = [];
                    state.payout_inconsistencies = '';
                    state.validation_codes_warning = '';
                    state.is_damage_closed_by_this_bill = '';
                }
            )


    }
})

const {reducer: billSliceReducer, actions} = billSlice;
export const {
    updateEditAccess,
    updateTotalValue,
    setShop,
    includeTax,
    markAsSold,
    updateTax,
    updateCarDealerSource,
    updateBillEmail,
    setDamageBillId,
    toggleBillsForMerge,
    markAsSentToMerge,
    resetBillsForMerge,
    resetBillsPreparedForMerge
} = actions;
export default billSliceReducer;
