import {AxiosResponse} from "axios";
import {client, headers} from "../../app/axiosClient";
import {carDealersUrl, damageBillUrl, externalProvidersUrl} from "../ApiService";

/**
 * Gets a car dealer
 * @param carDealerId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getCarDealer(carDealerId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/${carDealerId}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}


/**
 * Gets a car dealer
 * @param extProviderId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getExternalProvider(extProviderId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${externalProvidersUrl}/${extProviderId}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

/**
 * Returns a list of car dealer that matched with the keyword
 * @param keyword
 * @param pageNumber
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function searchCarDealers(keyword: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${keyword}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

/**
 * Returns a list of car dealer that matched with the keyword
 * @param keyword
 * @param pageNumber
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function searchExternalProviders(keyword: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${externalProvidersUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${keyword}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber,
        },
        cancelToken
    })
}

/**
 * Gets a list of bank account that are associated with a car dealer
 * @param carDealerId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getCarDealerBankAccounts(carDealerId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/${carDealerId}/bankaccounts`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function getCarDealerContacts(carDealerId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${carDealersUrl}/${carDealerId}/contacts`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}


export function getContactReasons(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${damageBillUrl}/contactreasons`, {
        headers: {
            ...headers
        },
        cancelToken: cancelToken
    })
}


/**
 * Gets a list of bank account that are associated with a external provider
 * @param extProviderId
 * @param cancelToken
 * @returns {Promise<AxiosResponse>}
 */
export function getExternalProviderAccounts(extProviderId: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${externalProvidersUrl}/${extProviderId}/bankaccounts`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}