import {BsPencilSquare, BsTrashFill} from "react-icons/bs";
import * as React from "react";

export const ListRow = React.forwardRef(({item, onEdit, onEditRoles, onDelete}, ref) => {
    return (
        <tr ref={ref} className={"Table-Row someClass"}>
            <td>{item.user.first_name} {item.user.last_name}</td>
            <td>{item.user.username}</td>
            <td>{item.country_name}</td>
            <td className={"Last-Table-Cell"}>

                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Delete"}
                        onClick={onDelete}>
                    <BsTrashFill
                        style={{
                            color: "red",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                </button>

                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Edit"}
                        onClick={onEdit}>
                    <BsPencilSquare
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                </button>


            </td>
        </tr>
    );

});
