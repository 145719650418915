//@flow

import * as React from 'react'
import dateFormat from "dateformat";
import {useDispatch, useSelector} from "react-redux";
import BillDocumentTranslations from "../../../translations/BillDocument";
import {
    fetchBillsLinkedToFahrzeugidentnummer,
    fetchBillsLinkedToSchadensnummer,
    fetchBillsLinkedToVertragsvereinbarung,
    fetchDamageBill
} from "../../../features/DamageBill/bill.actions";
import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import {SameDamageCount} from "../../contract/BillsList/ListItem";
import {BILL_PROCESSING_STATUS_LABELS, BILL_PROCESSING_STATUSES} from "../../StatusContext";
import {Button} from "react-bootstrap";
import {
    setDamageBillId,
    toggleBillsForMerge,
    markAsSentToMerge,
    resetBillsForMerge
} from "../../../features/DamageBill/bill.slice";
import useApi from "../../../hooks/useApi";
import {getBillCount} from "../../../services/API/damageBill.service";
import toast from "react-hot-toast";
import {sendBillsForManuelMerge, sendBillsForAutomaticMerge} from "../../../services/API/damageBills.service";
import {AxiosError, AxiosResponse} from "axios";
import {
    fetchBills,
    updateFilters
} from "../../../features/SearchPagination/searchPagination.slice";
import useOpenWindow from "../../../hooks/useOpenWindow";

function CounterAndList({count, flag, vin}) {
    const bill = useSelector(state => state.damageBill.bill)
    const {damageBillId, lockedBy} = useSelector(state => state.damageBill)
    const {billsForMerge} = useSelector(state => state.damageBill)
    const damage = useSelector(state => state.damageBill.damage)
    const billsWithSameDamage = useSelector(state => state.damageBill.billsWithSameDamage)
    const dispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const {openWindow: openDocumentsWindow} = useOpenWindow(`/documents/${damageBillId}`)
    const uid = useSelector(state => state.auth.loggedUser.uid);

    const handleClickedCheckbox = (e, itemId) => {
        dispatch(toggleBillsForMerge(itemId));
    }
    const sendSelectedBillsForAutomaticMerge = async () => {
        const checkBills = Object.keys(billsForMerge).filter(id => billsForMerge[id])
        await toast.promise(
            sendBillsForAutomaticMerge(checkBills, bill?.id),
            {
                loading: 'Sending for merge', success: (response: AxiosResponse) => {
                    if (response.status !== 200) return;
                    const d = checkBills?.length;
                    dispatch(markAsSentToMerge(checkBills));
                    setShow(false);
                    dispatch(fetchDamageBill(bill?.damage_bill?.id));
                    dispatch(updateFilters({}));
                    dispatch(fetchBills());
                    return `${d > 1 ? 'Bills' : 'Bill'} successfully merged`
                }, error: (err: AxiosError) => {
                    console.log(err)
                    return err.response?.data?.message || 'Oops'
                }
            })

    };
    const sendSelectedBillsForManuelMerge = async () => {
        const checkBills = Object.keys(billsForMerge).filter(id => billsForMerge[id])
        await toast.promise(
            sendBillsForManuelMerge(checkBills, bill?.id),
            {
                loading: 'Sending for merge', success: (response: AxiosResponse) => {
                    if (response.status !== 200) return;
                    const d = checkBills?.length;
                    dispatch(markAsSentToMerge(checkBills));
                    setShow(false);
                    dispatch(fetchDamageBill(bill?.damage_bill?.id));
                    dispatch(updateFilters({}));
                    dispatch(fetchBills());
                    return `${d > 1 ? 'Bills' : 'Bill'} successfully merged`
                }, error: (err: AxiosError) => {
                    console.log(err)
                    return err.response?.data?.message || 'Oops'
                }
            })

    };

    useEffect(() => {
        if(show){
            dispatch(toggleBillsForMerge(bill?.id));
        }
    }, [show]);

    return (
        <>
            <SameDamageCount
                style={{
                    cursor: lockedBy === uid ? 'pointer' : 'default',
                    background: lockedBy === uid ? '#007BFF' : '#9aa0a2'
                }}
                onClick={() => {
                    if (lockedBy !== uid) return;
                    if (flag === 'damage') {
                        dispatch(fetchBillsLinkedToSchadensnummer({damageNumber: damage?.damage_number, billNumber: bill?.id}))
                    }
                    if (flag === 'contract') {
                        dispatch(fetchBillsLinkedToVertragsvereinbarung({contractNumber: damage?.contract_id, billNumber: bill?.id}))
                    }
                    if (flag === 'vin') {
                        dispatch(fetchBillsLinkedToFahrzeugidentnummer({vin: vin, billNumber: bill?.id}))
                    }
                    setShow(true)
                }}
                count={count}
            />

            <Modal className={"wide-modal"} show={show} onHide={() => {
                setShow(false);
                dispatch(resetBillsForMerge());
            }}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{
                        textTransform: 'capitalize'
                    }}>{BillDocumentTranslations.de.claims_modal_search_claims}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'payment-page'} style={{
                        width: 'calc(100% - 2rem)',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '1rem',
                        maxHeight: '70vh',
                        border: '1px solid rgba(0,0,0,.12)',
                        borderRadius: '11px',
                        overflow: 'auto'
                    }}>
                        <table>
                            <thead>
                            <tr>
                                <th style={{padding: '20px', marginLeft: '10px'}}/>
                                <th>{'Rechnungs ID '}</th>
                                <th>Status</th>
                                <th>Schadensnummer</th>
                                <th style={{
                                    textTransform: 'capitalize'
                                }}>{BillDocumentTranslations.de.bill_number}</th>
                                <th>Vertragsnummer</th>
                                <th>Rechungssteller</th>
                                <th>Rechnungssume netto</th>
                                <th>Rechnungsdatum</th>
                            </tr>
                            </thead>
                            <tbody>
                            {billsWithSameDamage.map(({
                                                          damage_bill_id,
                                                          id,
                                                          damage_bill,
                                                          total_after_deduction,
                                                          processing_status,
                                                          bill_date,
                                                          billed_by_car_dealer_id,
                                                          bill_number,
                                                          bill_unlocked_for_edit_by_user_id
                                                      }) => {
                                return <tr
                                    style={{cursor: 'pointer'}}
                                    key={damage_bill_id}
                                    onClick={() => {
                                        dispatch(setDamageBillId(damage_bill_id));
                                        // redirectToBill(damage_bill_id);
                                        openDocumentsWindow(`/documents/${damage_bill_id}`);
                                    }}>
                                    <td id={'check_id_td'} style={{padding: '10px'}}>
                                        <input
                                            id={'check_id' + id}
                                            type="checkbox"
                                            data-itemid={id}
                                            checked={bill.id === id ? true : billsForMerge[id]}
                                            style={{
                                                display: processing_status === BILL_PROCESSING_STATUSES.BILL_IN_PROGRESS ? "block" : "none"
                                            }}
                                            data-processing_status={processing_status}
                                            onChange={(e) => {
                                                handleClickedCheckbox(e, id);
                                            }}
                                            disabled={bill_unlocked_for_edit_by_user_id !== uid && bill_unlocked_for_edit_by_user_id !== null}
                                        />
                                    </td>
                                    <td>{damage_bill_id}</td>
                                    <td>{BILL_PROCESSING_STATUS_LABELS[`${processing_status}`] || '-'}</td>
                                    <td>{damage_bill?.damage_number || '-'}</td>
                                    <td>{bill_number || '-'}</td>
                                    <td>{damage_bill?.contract_id || '-'}</td>
                                    <td>{billed_by_car_dealer_id || '-'}</td>
                                    <td>{total_after_deduction || '-'}</td>
                                    <td>
                                        {bill_date?.date ? dateFormat(bill_date.date, 'dd.mm.yyyy') : '-'}
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button variant="outline-primary"
                                size={'sm'}
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                onClick={sendSelectedBillsForAutomaticMerge}
                        >
                            Automatic merge
                        </Button>
                        <Button variant="outline-primary"
                                size={'sm'}
                                style={{
                                    textTransform: 'capitalize',
                                }}
                                onClick={sendSelectedBillsForManuelMerge}
                        >
                            Zusammenfassen
                        </Button>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

function DamageInfoItems() {

    const {warrantyContract, damage, materialWage, previousDamage, bill} = useSelector(state => state.damageBill)

    const {data, request, loading} = useApi(getBillCount);
    useEffect(() => {
        if (!!bill?.damage_bill_id && !!damage) {
            request({id: bill?.damage_bill_id})

        }
    }, [damage, bill?.damage_bill_id, request])

    return (
        <>
            <div className={'damage-info-container'}>
                <div className={'damage-info-row'}>
                    <div className={'flex-row'} style={{gap: '4px'}}>
                        <div className={'damage-info-new-item'} style={{maxWidth: ''}}>
                            <div className={'damage-info-name-container'}>{BillDocumentTranslations.de.damage_contract_id}</div>

                            <div className={'damage-info-item-value-container text-align-numbers'}>{damage?.contract_id || ''}</div>
                        </div>
                        <div className={'flex-column'} style={{gap: '4px', alignSelf: 'flex-end', marginBottom: '4px'}}>
                            {loading === false && (
                                <CounterAndList count={data?.data?.count_of_bills_linked_to_contract} flag={'contract'}
                                                vin={''}/>)}
                    </div>
                </div>
                <div className={'damage-info-new-item'} style={{minWidth: '280px'}}>
                    <div className={'damage-info-name-container'}>Vertragsprogramm</div>
                    <div className={'damage-info-item-value-container text-align-text-damage'}>{warrantyContract?.warrantyProgramName || ''} {warrantyContract?.fahrzart && '-'} {warrantyContract?.fahrzart === 'GW' ? 'Gebrauchtwagen' : warrantyContract?.fahrzart === 'NW' ? 'Neuwagen' : warrantyContract?.fahrzart === 'GM' ? 'Gebrauchtmotorrad' : warrantyContract?.fahrzart === 'NM' ? "Neumotorrad" : warrantyContract?.fahrzart }</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Vertragsbeginn</div>
                    <div
                        className={'damage-info-item-value-container'}>{warrantyContract?.start_date?.date ? dateFormat(warrantyContract?.start_date?.date, 'dd.mm.yyyy') : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Vertragsende</div>
                    <div
                        className={'damage-info-item-value-container'}>{warrantyContract?.end_date?.date ? dateFormat(warrantyContract?.end_date?.date, 'dd.mm.yyyy') : null}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Auslieferung</div>
                    <div
                        className={'damage-info-item-value-container'}>{warrantyContract?.fzkmvk ? `${warrantyContract?.fzkmvk} km` : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>MKT</div>
                    <div
                        className={'damage-info-item-value-container'}>{materialWage?.material?.data_exist ?materialWage?.material?.amount : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>LKT</div>
                    <div
                        className={'damage-info-item-value-container'}>{materialWage?.wage?.data_exist ? materialWage?.wage?.amount : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <span className={'damage-info-name-container'}>Selbstbehalt</span>
                    <span
                        className={'damage-info-item-value-container'}>{!!warrantyContract && (
                        `${warrantyContract.selbs1 || ''}/${warrantyContract.selbs2 || ''}/${warrantyContract.selbsf || ''}`
                    )}</span>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Max Reg. Betr.</div>
                    <div className={'damage-info-item-value-container'}>
                        {!!warrantyContract && (
                            `${warrantyContract.maxreg || ''}/${warrantyContract.maxreg7 || ''}/${warrantyContract.maxreglz || ''}`
                        )}
                    </div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Max Rest Betr.</div>
                    <div
                        className={'damage-info-item-value-container'}>{warrantyContract?.maxreglz && warrantyContract?.spentAmount ? (warrantyContract?.maxreglz - warrantyContract?.spentAmount) : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Materialrabatt</div>
                    <div
                        className={'damage-info-item-value-container'}>{materialWage?.material_rabat?.data_exist ? materialWage?.material_rabat?.amount : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Lohnrabatt</div>
                    <div
                        className={'damage-info-item-value-container'}>{materialWage?.wage_rabat?.data_exist ? materialWage?.wage_rabat?.amount : ''}</div>
                </div>
                </div>
                <div className={'damage-info-row'}>
                <div className={'flex-row'} style={{gap: '4px'}}>
                    <div className={'damage-info-new-item'}>
                        <div className={'damage-info-name-container'}>Schadensnummer</div>

                        <div className={'damage-info-item-value-container'}>{damage && damage.damage_number}</div>
                    </div>
                    <div className={'flex-column blue-number-info'}>
                        {loading === false && damage?.damage_number && (
                            <CounterAndList count={data?.data?.count_of_bills_linked_to_same_damage} flag={'damage'}
                                            vin={''}/>)}
                    </div>
                </div>
                <div className={'flex-row'} style={{gap: '4px'}}>
                    <div className={'damage-info-new-item'}>
                        <div className={'damage-info-name-container'}>Fahrzeugidentnummer</div>

                        <div className={'damage-info-item-value-container'}>{warrantyContract?.fzfgnr || ''}</div>
                    </div>
                    <div className={'flex-column blue-number-info'}>
                        {loading === false && damage?.damage_number && (
                            <CounterAndList count={data?.data?.count_of_bills_linked_to_same_vin} flag={'vin'}
                                            vin={warrantyContract?.fzfgnr?.slice(0, 17).trim()}/>)}
                    </div>
                </div>

                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Schadendatum</div>
                    <div className={'damage-info-item-value-container'}>
                        {damage?.date_damage_happened?.date ? dateFormat(damage.date_damage_happened.date, 'dd.mm.yyyy') : null}
                    </div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Meldedatum</div>
                    <div className={'damage-info-item-value-container'}>
                        {damage?.date_damage_reported?.date ? dateFormat(damage?.date_damage_reported?.date, 'dd.mm.yyyy') : ''}
                    </div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Schadenmeldung</div>
                    <div className={'damage-info-item-value-container'}>
                        {damage?.vehicle_mileage ? `${damage?.vehicle_mileage} km` : ''}
                    </div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Vorh.Schaden</div>
                    <div
                        className={'damage-info-item-value-container'}>{previousDamage?.vehicle_mileage ? `${previousDamage?.vehicle_mileage} km` : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Vorh.Differenz</div>
                    <div
                        className={'damage-info-item-value-container km_capitalize'}>{(damage?.vehicle_mileage && previousDamage?.vehicle_mileage) ? `${damage?.vehicle_mileage-previousDamage?.vehicle_mileage} km` : ''}</div>
                </div>
                <div className={'damage-info-new-item'}>
                    <div className={'damage-info-name-container'}>Reserve</div>
                    <div className={'damage-info-item-value-container'}>{damage?.tax_amount_reserved}</div>
                </div>
                </div>
            </div>
        </>
    )
}

export default DamageInfoItems;
