import {useEffect, useRef, useState, useCallback} from "react";
import Modal from "react-modal"
import {Form} from "react-bootstrap";
import * as React from "react"
import {ListRow} from "./ListRow";
import useApi from "../../hooks/useApi";
import {addPermission, getPermissions, updatePermission} from "../../services/API/permissions.service";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";
import InView from "../InView";
import toast from "react-hot-toast";

const initState = {name: '', description: '', id: 0}

export function List() {
    let [permissionState, setPermissionState] = useState(initState);

    const [isOpen, setIsOpen] = useState(false);
    const permissionFormRef = useRef(null);
    const [keyword] = useState('');
    const [statuses] = useState([]);
    const [, hasError, allPermissions, hasMore, nextPage, refresh] = useSearchPaginator(keyword, statuses, getPermissions);
    const {data: addPermissionData, request: addPermissionRequest, error: addPermissionError} = useApi(addPermission);
    const {
        data: updatePermissionData, request: updatePermissionRequest, error: updatePermissionError
    } = useApi(updatePermission);


    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);


    const toggleModal = useCallback(() => {
        setPermissionState(initState);
        setIsOpen(prevOpen => !prevOpen);
    }, [])

    const updateItem = useCallback((event) => {
        setPermissionState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }, []);

    const onPermissionFormSubmit = useCallback((e) => {
        e.preventDefault();
        const data = new FormData(permissionFormRef.current);
        if (data.get('id') > 0) {
            updatePermissionRequest({data, id: data.get('id')});
        } else {
            addPermissionRequest({data});
        }
    }, [updatePermissionRequest, addPermissionRequest]);

    const loadItemForEdit = useCallback((item) => {
        setPermissionState({...item})
        setIsOpen(true);
    }, [])


    useEffect(() => {
        if (!addPermissionData) return;
        if (addPermissionError) {
            toast.error(addPermissionError);
        } else {
            refresh();
            toggleModal();
        }
    }, [addPermissionData, toggleModal, addPermissionError, refresh]);

    useEffect(() => {
        if (!updatePermissionData) return;
        if (updatePermissionError) {
            toast.error(updatePermissionError);
        } else {
            refresh();
            toggleModal();
        }
    }, [updatePermissionData, toggleModal, updatePermissionError, refresh]);

    return (<div className={"list-container"}>
        <div className={"toolbox"}>
            <button className={"btn btn-primary"} onClick={toggleModal}>
                Add permission
            </button>
        </div>
        <div>
            <table>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {allPermissions.map((item, index) => {
                    return (
                        (index !== Math.round(allPermissions.length / 2)) ?
                            <ListRow onEdit={loadItemForEdit.bind(this, item)}
                                     key={"permission_item_" + index} item={item} index={index}
                            />
                            : <InView as={ListRow} onEdit={loadItemForEdit.bind(this, item)}
                                      key={"permission_item_" + index} item={item} index={index}
                                      onceOnIntersect={requestNextPage}
                            />

                    )

                })}
                </tbody>
            </table>
        </div>
        <Modal isOpen={isOpen}
               onRequestClose={toggleModal}
               overlayClassName={"modal-overlay"}
               className={"modal-narrow"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="Role">
            <Form ref={permissionFormRef} onSubmit={onPermissionFormSubmit}>
                <input type={"hidden"} name={"id"} value={permissionState.id || ''}/>
                <div className={"form-group"}>
                    Name: <input type={"text"} className={"form-control"} name={"name"}
                                 onChange={updateItem} value={permissionState.name || ''}/>
                </div>
                <div className={"form-group"}>
                    Description: <textarea className={"form-control"} name={"description"}
                                           onChange={updateItem} value={permissionState.description || ''}/>
                </div>
                <button type={'submit'} className={"btn btn-primary"}>Save</button>
                <button onClick={toggleModal} className={"btn btn-danger"}>Cancel</button>
            </Form>
        </Modal>
    </div>);
}