import {Header} from "../components/general/Header";
import {List} from "../components/roles/List";

export function RolesPage(props) {
    return(
        <div style={{background: '#e5ebf4'}}  className={"roles-container"}>
            <Header title={"Roles"}/>
            <List />
        </div>
    );
}