import {Header} from "../components/general/Header";
import {List} from "../components/users/List";
import {QueryClientProvider, QueryClient} from 'react-query'

export const qc = new QueryClient();

export function UsersPage(props: Props) {

    return (
        <QueryClientProvider client={qc}>
            <div style={{background: '#e5ebf4'}} className={"users-container"}>
                <Header title={"Users"}/>
                <List/>
            </div>
        </QueryClientProvider>
    );
}
