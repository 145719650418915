// @flow
import * as React from 'react';
import {useEffect, useState} from "react";
import preloaderImage from "../images/preloader.gif";

type
Props = {

};

export function Loader(props: Props) {
    let [state, setState] = useState(
        {
            isLoading: props['isLoading']
        }
    );

    useEffect(() => {
        if (props['isLoading'] !== state.isLoading) {
            setState({
                isLoading: props['isLoading']
            })
        }
    }, [props, state.isLoading])

    return (
        <div className={"loaderHolder " + (state.isLoading ? 'shown' : '')}>
            <div className={"loaderHolderWrapper"}/>
            <img alt={'loading spinner icon'} src={preloaderImage} />
        </div>
    );
};
