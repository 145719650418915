// @flow

import {createElement, useCallback, useEffect, useRef} from "react";

const destroyObserver = observer => observer && observer.disconnect()


/**
 * This is for ....
 * if we need something more performant or more features use this instead https://github.com/thebuilder/react-intersection-observer
 * @param as
 * @param onceOnIntersect
 * @param children
 * @param props
 * @returns {React.ReactElement<*&{ref: (function(*): void)}>}
 * @constructor
 */
export default function InView({as, onceOnIntersect, children, ...props}) {
    const observer = useRef();
    const isFirst = useRef<boolean>(true);

    /**
     * Destroy observer on 'unmount'
     */
    useEffect(() => {
        return () => {
            destroyObserver(observer.current);
            isFirst.current = true
        }
    }, [])

    /**
     * When the selected element is visible on screen then request the next page of paginated data
     */
    const lastSearchItemRef = useCallback(node => {
        destroyObserver(observer.current);
        observer.current = new IntersectionObserver((entries) => {
            // firing this only once prevents infinite scroll in ShopAutocomplete
            if (entries[0].isIntersecting) {
                if (typeof onceOnIntersect === 'function') {
                    onceOnIntersect()
                }
                isFirst.current = false
            }
        })
        if (node) observer.current.observe(node)
    }, [onceOnIntersect]);

    if (typeof as === 'function') {
        return <as ref={lastSearchItemRef} {...props}>{children}</as>
    }

    return createElement(
        as || 'div',
        {ref: lastSearchItemRef, ...props},
        children,
    );
}
