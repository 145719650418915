// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap"
import {usePopper} from 'react-popper'
import {useSelector} from "react-redux";
import BillDocumentTranslations from "../../../translations/BillDocument";


type Props = {
    errorMessage?: string,
};

export function BillNumber({errorMessage: errorMessageProp}: Props) {

    const {bill, isReadOnly} = useSelector(state => state.damageBill)
    const [errorMessage, setErrorMessage] = useState<any>(errorMessageProp);

    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });
    const [billNumber, setBillNumber] = useState<string>(bill?.bill_number || '');

    useEffect(() => {
        setErrorMessage(errorMessageProp)
        if (update) update().then()
    }, [errorMessageProp, update])

    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setBillNumber('')
        setErrorMessage(undefined)
        if (update) update().then()
    }, [bill, update])

    useEffect(() => {
        setBillNumber(bill?.bill_number || '')
    }, [bill])

    function handleChange(e) {
        setBillNumber(e.target.value);
        setErrorMessage(undefined)
        update().then()
    }

    return (
        <div className={"Field-Input-Drop"} style={{position: 'relative', maxWidth: '122px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"} style={{
                    textTransform: 'capitalize'
                }}>{BillDocumentTranslations.de.bill_number} *</Form.Label>
                <div className={"Field-Input-Text"} >
                    <Form.Control className={"Input-text"} type="shopText"
                                  value={billNumber}
                                  name="bill_number"
                                  onChange={(e) => handleChange(e)}
                                  ref={inputRef}
                                  disabled={isReadOnly}
                    />
                </div>


                <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                     style={styles.popper} {...attributes.popper}>
                    {errorMessage && (errorMessage.stringLengthTooLong || errorMessage.isEmpty)}
                    <div className="arrow" data-popper-arrow style={styles.arrow} {...attributes.arrow}/>
                </div>


            </Form.Group>
        </div>

    );
}
