import {catchError, filter, map, switchMap, takeUntil, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {fromAxios} from "../../utils/observables/fromAxios.observable";
import {
    fetchBillsLinkedToDamage,
    fetchBillsLinkedToDamageFailed,
    fetchBillsLinkedToDamageFullFilled,
    fetchCarDealerSoldBill,
    fetchCarDealerSoldBillFailed,
    fetchCarDealerSoldBillFullFilled,
    fetchCarDealerSoldPolicy,
    fetchCarDealerSoldPolicyFailed,
    fetchCarDealerSoldPolicyFullFilled,
    fetchContractSpentAmount,
    fetchContractSpentAmountFailed,
    fetchContractSpentAmountFullFilled,
    fetchDamage,
    fetchDamageBill,
    fetchDamageBillFailed,
    fetchDamageBillFullFilled,
    fetchDamageFailed,
    fetchDamageFullFilled,
    fetchExternalProvider,
    fetchExternalProviderFailed,
    fetchExternalProviderFullFilled,
    fetchMaterialWage,
    fetchMaterialWageFailed,
    fetchMaterialWageFullFilled,
    fetchPreviousDamage,
    fetchPreviousDamageFailed,
    fetchPreviousDamageFullFilled,
    fetchWarrantyContract,
    fetchWarrantyContractFailed,
    fetchWarrantyContractFullFilled,
    markBillAuthorizedForPayment,
    markBillAuthorizedForPaymentFailed,
    markBillAuthorizedForPaymentFullFilled,
    reFetchDamageBill,
    resolveBill,
    resolveBillFailed,
    resolveBillFullFilled,
    toggleDelete,
    toggleDeleteFailed,
    toggleDeleteFullFilled,
    updateBill,
    updateBillBackToPayment,
    updateBillBackToPaymentFailed,
    updateBillBackToPaymentFullFilled,
    updateBillFailed,
    updateBillForPayment,
    updateBillForPaymentFailed,
    updateBillForPaymentFullFilled,
    updateBillFullFilled,
    updateBillManualSelection,
    updateBillManualSelectionFailed,
    updateBillManualSelectionFullFilled,
    fetchBillsLinkedToSchadensnummerFullFilled,
    fetchBillsLinkedToSchadensnummerFailed,
    fetchBillsLinkedToVertragsvereinbarung,
    fetchBillsLinkedToVertragsvereinbarungFullFilled,
    fetchBillsLinkedToVertragsvereinbarungFailed,
    fetchBillsLinkedToFahrzeugidentnummer,
    fetchBillsLinkedToFahrzeugidentnummerFullFilled,
    fetchBillsLinkedToFahrzeugidentnummerFailed, fetchBillsLinkedToSchadensnummer,
    fetchCurrencyRates,
    fetchCurrencyRatesFullFilled,
    fetchCurrencyRatesFailed, updateCurrency, updateCurrencyFullFilled, updateCurrencyFailed
} from "./bill.actions";
import {
    updateBill as updateBillReq,
    toggleDelete as toggleDeleteReq,
    resolveBill as resolveBillReq,
    getBill,
    getContractSpentAmount,
    getDamage,
    getPreviousDamage,
    getWarrantyContract,
    getWarrantyContractMaterialWageReimbursements,
    updateBillManualSetupData,
    markBillAsDeclinedForPayment,
    markBillAsAuthorizedForPayment,
    markBillForPayment,
    getBillsLinkedToDamage,
    getBillsLinkedToSchadensnummer,
    getBillsLinkedToVertragsvereinbarung,
    getBillsLinkedToFahrzeugidentnummer,
    getBillCurrencyRate, putUpdateCurrency,
} from "../../services/API/damageBill.service";
import {getCarDealer, getExternalProvider} from "../../services/API/carDealer.service";
import {BILLED_BY_TYPE_EXTERNAL, BILLED_BY_TYPE_SENT_BILL} from "../../components/BillContext";

/**
 * Epic used to fetch a Damage
 * @param action$
 * @returns {*}
 */
export const fetchDamageEpic = (action$) => action$.pipe(filter(fetchDamage.match), // if an action for fetching Damage was dispatched
    switchMap(({payload}) => // map to new observable
        fromAxios(getDamage, [payload.damage_id]) // create Cancellable observable from Axios promise
            .pipe(takeUntil( // cancel previous request
                    action$
                        .pipe(filter(fetchDamage.match) // if another action for fetching damage was dispatched
                        )), /*
                        When we get response dispatch the follow-up actions sequentially
                     */
                mergeMap(({data}) => of(fetchDamageFullFilled(data), fetchMaterialWage({
                    contract_id: data.contract_id, vehicle_mileage: payload.mileage
                }), fetchPreviousDamage({
                    contract_id: data.contract_id, damage_id: payload.damage_id,
                }), fetchContractSpentAmount(data.contract_id), fetchWarrantyContract(data.contract_id))), catchError(() => of(fetchDamageFailed(payload))), // dispatch action with error
            )),)


/**
 * Epic used to fetch a Damage
 * @param action$
 * @returns {*}
 */
export const fetchBillsLinkedToDamageEpic = (action$) => action$.pipe(filter(fetchBillsLinkedToDamage.match), // if an action for fetching Damage was dispatched
    switchMap(({payload}) => // map to new observable
        fromAxios(getBillsLinkedToDamage, [payload]) // create Cancellable observable from Axios promise
            .pipe(takeUntil( // cancel previous request
                    action$
                        .pipe(filter(fetchBillsLinkedToDamage.match) // if another action for fetching damage was dispatched
                        )), /*
                        When we get response dispatch the follow-up actions sequentially
                     */
                map(({data}) => data._embedded.records),
                mergeMap((data) =>
                    of(fetchBillsLinkedToDamageFullFilled(data))),
                catchError(() => of(fetchBillsLinkedToDamageFailed(payload))), // dispatch action with error
            )),)
export const fetchBillsLinkedToSchadensnummerEpic = (action$) => action$.pipe(filter(fetchBillsLinkedToSchadensnummer.match), // if an action for fetching Damage was dispatched
    switchMap(({payload}) => // map to new observable
        fromAxios(getBillsLinkedToSchadensnummer, [payload.damageNumber, payload.billNumber]) // create Cancellable observable from Axios promise
            .pipe(takeUntil( // cancel previous request
                    action$
                        .pipe(filter(fetchBillsLinkedToSchadensnummer.match) // if another action for fetching damage was dispatched
                        )), /*
                        When we get response dispatch the follow-up actions sequentially
                     */
                map(({data}) => data._embedded.records),
                mergeMap((data) =>
                    of(fetchBillsLinkedToSchadensnummerFullFilled(data))),
                catchError(() => of(fetchBillsLinkedToSchadensnummerFailed(payload))), // dispatch action with error
            )),)
export const fetchBillsLinkedToVertragsvereinbarungEpic = (action$) => action$.pipe(filter(fetchBillsLinkedToVertragsvereinbarung.match), // if an action for fetching Damage was dispatched
    switchMap(({payload}) => // map to new observable
        fromAxios(getBillsLinkedToVertragsvereinbarung, [payload.contractNumber, payload.billNumber]) // create Cancellable observable from Axios promise
            .pipe(takeUntil( // cancel previous request
                    action$
                        .pipe(filter(fetchBillsLinkedToVertragsvereinbarung.match) // if another action for fetching damage was dispatched
                        )), /*
                        When we get response dispatch the follow-up actions sequentially
                     */
                map(({data}) => data._embedded.records),
                mergeMap((data) =>
                    of(fetchBillsLinkedToVertragsvereinbarungFullFilled(data))),
                catchError(() => of(fetchBillsLinkedToVertragsvereinbarungFailed(payload))), // dispatch action with error
            )),)
export const fetchBillsLinkedToFahrzeugidentnummerEpic = (action$) => action$.pipe(filter(fetchBillsLinkedToFahrzeugidentnummer.match), // if an action for fetching Damage was dispatched
    switchMap(({payload}) => // map to new observable
        fromAxios(getBillsLinkedToFahrzeugidentnummer, [payload.vin, payload.billNumber]) // create Cancellable observable from Axios promise
            .pipe(takeUntil( // cancel previous request
                    action$
                        .pipe(filter(fetchBillsLinkedToFahrzeugidentnummer.match) // if another action for fetching damage was dispatched
                        )), /*
                        When we get response dispatch the follow-up actions sequentially
                     */
                map(({data}) => data._embedded.records),
                mergeMap((data) =>
                    of(fetchBillsLinkedToFahrzeugidentnummerFullFilled(data))),
                catchError(() => of(fetchBillsLinkedToFahrzeugidentnummerFailed(payload))), // dispatch action with error
            )),)


/**
 * Epic used to fetch the spent amount of a Contract
 * @param action$
 * @returns {*}
 */
export const fetchContractSpentAmountEpic = (action$) => action$.pipe(filter(fetchContractSpentAmount.match), filter(action => !!(action.payload)), // if payload exists continue
    switchMap(({payload}) => fromAxios(getContractSpentAmount, [payload])
        .pipe(takeUntil(action$
                .pipe(filter(fetchContractSpentAmount.match))), map(({data}: { data: { spent_amount: number } }) => fetchContractSpentAmountFullFilled(data.spent_amount)), // dispatch action with response data
            catchError(() => of(fetchContractSpentAmountFailed(payload))), // dispatch action with error
        )),)

/**
 * Epic used to fetch Damage Bill
 * @param action$
 * @param state$ is used to populate the request with the proper params
 * @returns {*}
 */
export const fetchDamageBillEpic = (action$, state$) => action$.pipe( // listening to action
    filter(fetchDamageBill.match), mergeMap(({payload}) => of({
        uid: state$.value.auth.loggedUser?.uid || '',
        max_payment_limit: state$.value.auth.loggedUser?.user?.max_payment_limit || 0,
    }) // now listening to state
        .pipe(switchMap(({uid, max_payment_limit}) => fromAxios(getBill, [payload])
            .pipe(takeUntil(action$
                    .pipe(filter(fetchDamageBill.match))), /*
                                When we get response dispatch the follow-up actions sequentially
                            */
                mergeMap(({data}) => of(fetchDamageBillFullFilled({
                        ...data, fetchedBy: uid, max_payment_limit,
                    }), // dispatch action with response data
                    fetchCurrencyRates({
                        billId: data.id
                    }),

                    [BILLED_BY_TYPE_SENT_BILL, BILLED_BY_TYPE_EXTERNAL].includes(data.billed_by_type) ? data.billed_by_type === BILLED_BY_TYPE_SENT_BILL ? fetchCarDealerSoldBill(data.billed_by_car_dealer_id) : fetchExternalProvider(data.billed_by_car_dealer_id) : () => null)), catchError(() => of(fetchDamageBillFailed(payload))), // dispatch action with error
            )),)))

/**
 * Epic used to fetch Damage Bill
 * @param action$
 * @param state$ is used to populate the request with the proper params
 * @returns {*}
 */
export const reFetchDamageBillEpic = (action$, state$) => action$.pipe( // listening to action
    filter(reFetchDamageBill.match), mergeMap(() => of({
        damage_bill_id: state$.value.damageBill.bill.damage_bill_id || '',
    })
        .pipe(mergeMap(({damage_bill_id}) => of(fetchDamageBill(damage_bill_id))))))


/**
 * Epic used to fetch (warranty) Contract
 * @param action$
 * @returns {*}
 */
export const fetchWarrantyContractEpic = (action$) => action$.pipe(filter(fetchWarrantyContract.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(getWarrantyContract, [payload])
    .pipe(takeUntil(action$
        .pipe(filter(fetchWarrantyContract.match))), mergeMap(({data}) => of(fetchWarrantyContractFullFilled(data), fetchCarDealerSoldPolicy(data.car_dealer_id))), catchError(() => of(fetchWarrantyContractFailed(payload))), // dispatch action with error
    )),)


/**
 * Epic used to fetch car dealer who sold/sent the bill
 * @param action$
 * @returns {*}
 */
export const fetchCarDealerSoldBillEpic = (action$) => action$.pipe(filter(fetchCarDealerSoldBill.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(getCarDealer, [payload])
    .pipe(takeUntil(action$
            .pipe(filter(fetchCarDealerSoldBill.match))), map(({data}) => fetchCarDealerSoldBillFullFilled(data)), // dispatch action with response data
        catchError(() => of(fetchCarDealerSoldBillFailed(payload))), // dispatch action with error
    )));

/**
 * Epic used to fetch external provider
 * @param action$
 * @returns {*}
 */
export const fetchExternalProviderEpic = (action$) => action$.pipe(filter(fetchExternalProvider.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(getExternalProvider, [payload])
    .pipe(takeUntil(action$
            .pipe(filter(fetchExternalProvider.match))), map(({data}) => fetchExternalProviderFullFilled(data)), // dispatch action with response data
        catchError(() => of(fetchExternalProviderFailed(payload))), // dispatch action with error
    )));


/**
 * Epic used to fetch car dealer who sold the policy
 * @param action$
 * @returns {*}
 */
export const fetchCarDealerSoldPolicyEpic = (action$) => action$.pipe(filter(fetchCarDealerSoldPolicy.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(getCarDealer, [payload])
    .pipe(takeUntil(action$
            .pipe(filter(fetchCarDealerSoldPolicy.match))), map(({data}) => fetchCarDealerSoldPolicyFullFilled(data)), // dispatch action with response data
        catchError(() => of(fetchCarDealerSoldPolicyFailed(payload))), // dispatch action with error
    )),)

/**
 * Epic used to fetch reimbursements of a contract
 * @param action$
 * @returns {*}
 */
export const fetchMaterialWageEpic = (action$) => action$.pipe(filter(fetchMaterialWage.match), filter(action => !!(action.payload.contract_id) && !!(action.payload.vehicle_mileage)), switchMap(({payload}) => fromAxios(getWarrantyContractMaterialWageReimbursements, [payload.contract_id, payload.vehicle_mileage])
    .pipe(takeUntil(action$
            .pipe(filter(fetchMaterialWage.match))), map(({data}) => fetchMaterialWageFullFilled(data)), // dispatch action with response data
        catchError(() => of(fetchMaterialWageFailed(payload))), // dispatch action with error
    )),)

/**
 * Epic used to fetch currency rates of a bill
 * @param action$
 * @returns {*}
 */
export const fetchCurrencyRatesEpic = (action$) => action$.pipe(filter(fetchCurrencyRates.match), filter(action => !!(action.payload.billId)), switchMap(({payload}) => fromAxios(getBillCurrencyRate, [payload.billId])
    .pipe(takeUntil(action$
            .pipe(filter(fetchCurrencyRates.match))), map(({data}) => fetchCurrencyRatesFullFilled(data)), // dispatch action with response data
        catchError(() => of(fetchCurrencyRatesFailed(payload))), // dispatch action with error
    )),)

export const updateCurrencyEpic = (action$) => action$.pipe(filter(updateCurrency.match), filter(action => !!(action.payload.billId) && !!(action.payload.domestic_currency)), switchMap(({payload}) => fromAxios(putUpdateCurrency, [payload.billId, payload.domestic_currency, payload.foreign_currency, payload.rate])
    .pipe(takeUntil(action$
            .pipe(filter(updateCurrency.match))), map(({data}) => updateCurrencyFullFilled(data)), // dispatch action with response data
        catchError(() => of(updateCurrencyFailed(payload))), // dispatch action with error
    )),)

/**
 * Epic used to fetch information about previous damage
 * @param action$
 * @returns {*}
 */
export const fetchPreviousDamageEpic = (action$) => action$.pipe(filter(fetchPreviousDamage.match), filter(action => !!(action.payload.contract_id) && !!(action.payload.damage_id)), switchMap(({payload}) => fromAxios(getPreviousDamage, [payload.damage_id, payload.contract_id])
    .pipe(takeUntil(action$
            .pipe(filter(fetchPreviousDamage.match))), map(({data}) => fetchPreviousDamageFullFilled(data)), // dispatch action with response data
        catchError(() => of(fetchPreviousDamageFailed(payload))), // dispatch action with error
    )),)

export const updateBillEpic = (action$) => action$.pipe(filter(updateBill.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(updateBillReq, [payload.billId, payload.data])
    .pipe(takeUntil(action$
        .pipe(filter(updateBill.match))), mergeMap(({data}) => of(updateBillFullFilled(data),// dispatch action with response data
        fetchDamageBill(payload.billId))), catchError((err) => of(updateBillFailed(err?.isAxiosError ? err.response.data : err))), // dispatch action with error
    )),)

export const updateBillManualSelectionEpic = (action$) => action$.pipe(filter(updateBillManualSelection.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(updateBillManualSetupData, [payload.billId, payload.data])
    .pipe(takeUntil(action$
        .pipe(filter(updateBillManualSelection.match))), mergeMap(({data}) => of(updateBillManualSelectionFullFilled(data),// dispatch action with response data
        fetchDamageBill(payload.billId))), catchError((err) => of(updateBillManualSelectionFailed(err?.isAxiosError ? err.response.data : err))), // dispatch action with error
    )),)

export const markBillAsAuthorizedEpic = (action$) => action$.pipe(filter(markBillAuthorizedForPayment.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(markBillAsAuthorizedForPayment, [payload.billId, payload.isMarked, payload.user_notified])
    .pipe(takeUntil(action$
        .pipe(filter(markBillAuthorizedForPayment.match))), mergeMap(({data}) => of(markBillAuthorizedForPaymentFullFilled(data),// dispatch action with response data
        fetchDamageBill(payload.billId))), catchError((err) => of(markBillAuthorizedForPaymentFailed(err?.isAxiosError ? err.response.data : err))), // dispatch action with error
    )),)


export const updateBillForPaymentEpic = (action$) => action$.pipe(
    filter(updateBillForPayment.match),
    filter(action => !!(action.payload)),
    switchMap(({payload}) => fromAxios(updateBillReq, [payload.billId, payload.data])
        .pipe(
            switchMap(() => fromAxios(markBillForPayment, [payload.billId, payload.user_notified])),
            takeUntil(action$
                .pipe(filter(updateBillForPayment.match))), mergeMap(({data}) => of(updateBillForPaymentFullFilled(data),// dispatch action with response data
                fetchDamageBill(payload.billId))), catchError((err) => of(updateBillForPaymentFailed(err ? err.isAxiosError ? err.response.data : err : payload.billId))), // dispatch action with error
        )),)


export const updateBillBackToPaymentEpic = (action$) => action$.pipe(filter(updateBillBackToPayment.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(markBillAsDeclinedForPayment, [payload])
    .pipe(takeUntil(action$
        .pipe(filter(updateBillBackToPayment.match))), mergeMap(({data}) => of(updateBillBackToPaymentFullFilled(data),// dispatch action with response data
        fetchDamageBill(payload))), catchError(() => of(updateBillBackToPaymentFailed(payload))), // dispatch action with error
    )),)


export const toggleDeleteEpic = (action$) => action$.pipe(filter(toggleDelete.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(toggleDeleteReq, [payload.billId, payload.data])
    .pipe(takeUntil(action$
        .pipe(filter(toggleDelete.match))), mergeMap(({data}) => of(toggleDeleteFullFilled(data),// dispatch action with response data
        fetchDamageBill(payload.billId))), catchError((err) => of(toggleDeleteFailed(err?.isAxiosError ? err.response.data : err))), // dispatch action with error
    )),)

export const resolveBillEpic = (action$) => action$.pipe(filter(resolveBill.match), filter(action => !!(action.payload)), switchMap(({payload}) => fromAxios(resolveBillReq, [payload.billId, payload.isMarked])
    .pipe(takeUntil(action$
        .pipe(filter(resolveBill.match))), mergeMap(({data}) => of(resolveBillFullFilled(data),// dispatch action with response data
        fetchDamageBill(payload.billId))), catchError((err) => of(resolveBillFailed(err?.isAxiosError ? err.response.data : err))), // dispatch action with error
    )),)
