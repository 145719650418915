// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Input} from "reactstrap";
import useApi from "../../../hooks/useApi";
import {useSelector} from "react-redux";
import {getCarDealerContacts} from "../../../services/API/carDealer.service";
import {GeneralTranslations} from "../../../translations/General/GeneralTranslations";

export function ContactPerson() {
    const {bill, shopAutoCompleteId, isReadOnly} = useSelector(state => state.damageBill)
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState<number>(bill?.shop_contact_user_id || null)
    const {request, data} = useApi(getCarDealerContacts)

    useEffect(() => {
        if (!data || !data._embedded) return;
        setContacts(data._embedded.records)
    }, [data])

    useEffect(() => {
        setSelectedContact(bill?.shop_contact_user_id || null);
    }, [bill?.shop_contact_user_id])

    useEffect(() => {
        if (!shopAutoCompleteId) {
            setContacts([])
            setSelectedContact(bill?.shop_contact_user_id || null)
            return;
        }
        request({shopAutoCompleteId});
    }, [shopAutoCompleteId, request, bill?.shop_contact_user_id ])

    return (
        <div className={"Field-Input-Drop"} style={{
            flex: '1 1 140px'
        }}>
            <label className={" Input-label"}  style={{marginBottom: 0}}>Kontaktperson</label>
            <div className={"Frame-19"}>
                <div className={"Field-Filters"}>
                    <label className={"No-filters"}>
                        <Input type="select"
                               name="shop_contact_user_id"
                               id="shop_contact_user_id"
                               value={contacts.find(c => c.id === selectedContact) ? selectedContact : -1}
                               disabled={isReadOnly}
                               style={{height: "32px", textTransform: 'capitalize'}}
                               onChange={(e) => setSelectedContact(parseInt(e.target.value))}
                        >
                            <option value={-1} disabled>{GeneralTranslations.de.label_please} {GeneralTranslations.de.label_select}</option>
                            {
                                contacts.map((item) => {
                                    if (item.id === bill?.shop_contact_user_id) {
                                        return (
                                            <option selected="selected"
                                                    key={item.id}
                                                    value={item.id}>{item.first_name} {item.last_name}</option>
                                        )
                                    }
                                    return (
                                        <option key={item.id}
                                                value={item.id}>{item.first_name} {item.last_name}</option>
                                    )
                                })
                            }
                        </Input>
                    </label>
                </div>
            </div>
        </div>
    );
}
