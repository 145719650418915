//@flow
import {Redirect, Route} from "react-router-dom";
import React from "react";
import {RouteProps} from "react-router";
import {useSelector} from "react-redux";

export function AuthenticatedRoute({roles, ...props}: RouteProps) {

    // Changing from useAuth & React Context to Redux
    // const {state} = useAuth();
    const { loggedUser } = useSelector(state => state.auth)

    if (!loggedUser.uid) return <Redirect to="/login"/>;
    return <Route {...props} />;
}
