//@flow
import React, {FC, useCallback, useState} from "react";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import {pdfjs} from 'react-pdf';
import './all-pages-pdf-viewer.css'

// ways to import pdfjs worker for production
// with cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// self-hosting ** you need to copy `pdf.worker.js` file from `pdfjs-dist/build` inside public
// pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

type AllPagesPDFViewerProps = {
    pdfFile: string
}

const AllPagesPDFViewer: FC<AllPagesPDFViewerProps> = ({pdfFile}) => {

    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = useCallback(({numPages}) => {
        setNumPages(numPages);
    }, [])

    return (
        <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode={'canvas'}
        >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} renderAnnotationLayer={false} renderTextLayer={false}
                      pageNumber={index + 1} width={1080}/>
            ))}
        </Document>
    )
}

export default AllPagesPDFViewer;
