// @flow
import * as React from 'react';
import IncludeTaxToggle from "./IncludeTaxToggle";
import {ContactPerson} from "./ContactPerson";
import {ContactReason} from "./ContactReason";
import {ContactFrom} from "./ContactFrom";
import {BillNumber} from "./BillNumber";
import {Date} from "./Date";
import {TotalValue} from "./TotalValue";
import {ShopAutoComplete} from "./ShopAutoComplete";
import {FirstVat} from "./FirstVat";
import {KmInRepair} from "./KmInRepair";
import {RepairDate} from "./RepairDate";
import {AcceptanceDate} from "./AcceptanceDate";

import './form-bill-details.css';
import {AccountNumber} from "./AccountNumber";
import {useSelector} from "react-redux";
import SelectCarDealer from "./SelectCarDealer";
import BillEmailField from "./BillEmailField/BillEmailField";

export function FormBillDetails2({showConvertedValue, hideConvertedValue}) {

    const {bill, errors: {formValidation}} = useSelector(state => state.damageBill)

    return (
        <>
            <div className={'form-bill-details-container'}>
                    <SelectCarDealer errorMessage={formValidation?.carDealerSource}/>
                    <ShopAutoComplete name="billed_by_car_dealer_id" errorMessage={formValidation?.billed_by_car_dealer_id}/>
                    <AccountNumber errorMessage={formValidation?.shop_bank_account_id}/>
                    <BillNumber errorMessage={formValidation?.bill_number}/>
                    <Date errorMessage={formValidation?.bill_date}/>
                    <AcceptanceDate acceptance_date={bill?.acceptance_date?.date}/>
                    <RepairDate/>
                    <BillEmailField/>
                    <ContactPerson/>
                    <ContactReason errorMessage={formValidation?.bill_contact_reason}/>
                    <ContactFrom/>
                    <KmInRepair errorMessage={formValidation?.vehicle_mileage_after_reparation}/>
                    <TotalValue showConvertedValue={showConvertedValue}
                                hideConvertedValue={hideConvertedValue}/>
                    <FirstVat/>
                    <IncludeTaxToggle/>
            </div>
        </>

    );
}

