// @flow
import React, {useCallback, useMemo, useState} from 'react'
import {Bill} from "../../components/BillContext";
import {Link} from "react-router-dom";
import {useTablePaginator} from "./useTablePaginator";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import toast, {Toaster} from "react-hot-toast";
import InView from "../../components/InView";
import {getReadForPaymentBills, sendBillsForPayment} from "../../services/API/damageBills.service";
import img from '../../assets/images/attentionIcon.png';

import './pay_table.css'
import {useSelector} from "react-redux";

const payoutInconsistencies = {
    1: 'ooh, Outside office hours ',
    10: 'K, Kulanz ',
    20: 'dop, Doppelzahlung',
    21: 'dop, Doppelzahlung',
    22: 'dop, Doppelzahlung',
    30: 'AuZa, AuslandsZahlung',
    40: 'MwSt, Zahlung ohne Mehrwertsteuer',
    50: 'MwSt, Zahlung ohne Mehrwertsteuer',
}

const formatIban = (inputString: string) => {
    let newString = inputString.toUpperCase();
    newString = newString.replace(/[^\dA-Z]/g, '');
    const reg = new RegExp('.{' + 4 + '}', 'g');
    return newString
        .replace(reg, function (a) {
            return a + ' ';
        })
        .replace(/[^0-9A-Z]+$/, '');
};

function AuthBillItem({
                          bill,
                          isChecked,
                          onToggle,
                      }: { bill: Bill & {
        "domestic_currency": string;
        "foreign_currency": string;
        "rate": string;
    }, isChecked: boolean, onToggle: () => void }) {
    const uid = useSelector(state => state.auth.loggedUser.uid);
    return (<>
        <td>
            {uid!==bill?.authorized_by_user_id && <input type={"checkbox"} checked={isChecked} className={'ml-3'}
                   disabled={!(bill.billed_by_car_dealer_id && bill.car_dealer_name && bill.shop_bank_account_id && bill.bank_account_data && bill.iban && bill.bic) || bill.total_after_deduction === '0.00' || bill.total_after_taxes === '0.00'}
                   onChange={onToggle.bind(this, bill.damage_bill_id, bill.payout_inconsistencies)}/>}
        </td>
        <td>{bill.payout_inconsistencies && bill.payout_inconsistencies.split(',').map((inc) => {
            return <div><OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                    <Tooltip {...props}>
                        {`${payoutInconsistencies[inc] ? payoutInconsistencies[inc].split(',')[1] : ''}`}
                    </Tooltip>
                )}
                placement="right"
            >
                <div>{`${payoutInconsistencies[inc] ? payoutInconsistencies[inc].split(',')[0] : ''}`}</div>
            </OverlayTrigger></div>
        })}</td>

        <td>{bill.damage_bill_id}</td>
        <td>{bill.damage_number}</td>
        <td>{bill.prepared_for_payment_date}</td>
        <td>{bill.prepared_for_payment_by_user}</td>
        <td>{bill.car_dealer_name}</td>
        <td>{bill.bill_number}</td>
        <td>{'-'}</td>
        <td>{formatIban(bill.iban)}</td>
        <td>{bill.bic}</td>
        <td>{bill.bank_name}</td>
        <td className={'text-right pr-2'}>{bill.foreign_currency} {bill.total_after_deduction}</td>
        <td className={'text-right pr-5'}>{bill.foreign_currency} {bill.total_after_taxes}</td>
        <td>{bill.is_tax_included ? 'Ja' : 'Nein'}</td>
        <td>
            <Link to={`/bills/${bill.damage_bill_id}`} target={'_blank'}>
                <i className="material-icons-round">open_in_new</i>
            </Link>
        </td>
    </>)
}

const AuthBillItemMemo = React.memo(AuthBillItem)

const getWithTruthyValues = (obj) => Object.entries(obj).filter(([, v]) => v);

function PayPage() {
    const [, hasError, billsPaginated, hasMore, total, refresh, nextPage] = useTablePaginator(getReadForPaymentBills);
    const [isMasterChecked, setMasterChecked] = useState<boolean>(false);
    const [selectedBills, setSelectedBills] = useState({});
    const [selectedBillsInconsistencies, setSelectedBillsInconsistencies] = useState({});

    const onInView = useCallback(() => {
        if (hasMore && !hasError) nextPage()
    }, [hasMore, hasError, nextPage]);

    const selectedBillsArr = useMemo(() => {
        return getWithTruthyValues(selectedBills).map(([k]) => k)
    }, [selectedBills]);

    const selectedBillsInconsistenciesArr = useMemo(() => {
        return getWithTruthyValues(selectedBillsInconsistencies).map(([k]) => k);
    }, [selectedBillsInconsistencies]);

    const toggleBillForPayment = useCallback((billId, payout_inconsistencies) => {
        function getNewSelected(obj) {
            return ({
                ...obj, [billId]: !obj[billId]
            })
        }

        if (payout_inconsistencies) {
            setSelectedBillsInconsistencies(getNewSelected);
        } else {
            setMasterChecked(false);
            setSelectedBills(getNewSelected);
        }
    }, [selectedBills, billsPaginated])

    const sendToPayment = async () => {
        await toast.promise(sendBillsForPayment(selectedBillsArr, isMasterChecked, selectedBillsInconsistenciesArr),
            {
                loading: 'Sending',
                success: () => {
                    setSelectedBills({});
                    setSelectedBillsInconsistencies({});
                    refresh();
                    return `Successfully sent ${selectedBillsArr.length + selectedBillsInconsistenciesArr.length} ${selectedBillsArr.length > 1 || selectedBillsInconsistenciesArr.length > 1 ? 'items' : 'item'}`
                },
                error: (err) => err.response?.data?.message || `This just happened: ${err.toString()}`,
            }
        ).then(console.log)
            .catch((e) => console.log(e.message))
    }


    const handleMasterCheckbox = (e) => {
        setMasterChecked(e.target.checked)
        if (e.target.checked) {
            let obj = {}
            billsPaginated.forEach((bill) => {
                if (bill.billed_by_car_dealer_id && bill.car_dealer_name && bill.shop_bank_account_id && bill.bank_account_data && bill.iban && bill.bic && !bill.payout_inconsistencies && bill.total_after_deduction !== '0.00' && bill.total_after_taxes !== '0.00' && bill.gvv_contract_chk===0) return obj[bill.damage_bill_id] = (bill.billed_by_car_dealer_id && bill.car_dealer_name && bill.shop_bank_account_id && bill.bank_account_data)
            })
            setSelectedBills(obj)
        } else {
            setSelectedBills({})
        }
    }


    return (<>
        <div className={"payment-page"}>
            <Toaster/>
            <h1>Schäden zur Zahlung freigeben</h1>
            <div style={{
                display: 'flex',
                gap: '1rem'
            }}>
                <Link to="/bills">Zürück zur Startseite</Link>
                <Link to="/sepa">Sepa Files</Link>
            </div>

            <div style={{
                width: 'calc(100% - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                margin: '1rem',
                maxHeight: '80vh',
                border: '1px solid rgba(0,0,0,.12)',
                borderRadius: '11px',
                overflow: 'auto'
            }}>

                <table>
                    <thead>
                    <tr>
                        <th><input type={'checkbox'} checked={isMasterChecked} onChange={handleMasterCheckbox}/>
                        </th>
                        <th><img src={img} width={25} height={22} alt={'Attention'}/></th>
                        <th>GA Nr.</th>
                        <th>SchNr</th>
                        <th>Abr.Dat U. Uhrzeit</th>
                        <th>Abr.durch</th>
                        <th>Empfanger</th>
                        <th>Re Nr.</th>
                        <th>Hdl Nr.</th>
                        <th>IBAN</th>
                        <th>BIC</th>
                        <th>Bankname</th>
                        <th>Betrag netto</th>
                        <th>Betrag brutto</th>
                        <th>MwSt.</th>
                        <th>Aktion</th>
                    </tr>
                    </thead>
                    <tbody>
                    {billsPaginated.map((bill, index) => {
                        return index !== Math.round(billsPaginated.length / 1.2) ?
                            <tr style={{background: (bill.payout_inconsistencies || !bill.iban || !bill.bic || bill.total_after_deduction === '0.00' || bill.total_after_taxes === '0.00'  || bill.gvv_contract_chk===1) && 'red'}}
                                key={bill.damage_bill_id}>
                                <AuthBillItemMemo
                                    key={bill.damage_bill_id} onToggle={toggleBillForPayment}
                                    isChecked={bill.payout_inconsistencies ? !!selectedBillsInconsistencies[bill.damage_bill_id] : !!selectedBills[bill.damage_bill_id]}
                                    bill={bill}/>
                            </tr> :
                            <InView
                                style={{background: (bill.payout_inconsistencies || !bill.iban || !bill.bic || bill.total_after_deduction === '0.00' || bill.total_after_taxes === '0.00' || bill.gvv_contract_chk===1) && 'red'}}
                                onceOnIntersect={onInView} as={'tr'} key={bill.damage_bill_id}>
                                <AuthBillItemMemo
                                    key={bill.damage_bill_id} onToggle={toggleBillForPayment}
                                    isChecked={bill.payout_inconsistencies ? !!selectedBillsInconsistencies[bill.damage_bill_id] : !!selectedBills[bill.damage_bill_id]}
                                    bill={bill}/>
                            </InView>
                    })}
                    </tbody>
                </table>
                <div className={'table-footer'}>
                    <div className={'table-footer-inner'}>Results 1 - {billsPaginated.length} of {total}</div>
                </div>

            </div>

            <Button onClick={sendToPayment}
                    disabled={selectedBillsArr.length === 0 && selectedBillsInconsistenciesArr.length === 0}>
                <label className={"label"} style={{height: "13px"}}>Send to payment</label>
            </Button>

        </div>
    </>)
}


export default PayPage;