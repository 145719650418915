// @flow
import * as React from 'react';
import {Button} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux";
import {updateBillBackToPayment} from "../../features/DamageBill/bill.actions";
import BillDocumentTranslations from "../../translations/BillDocument";


export function DeclineBillButton() {
    const {bill} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();

    const onClickForm = () => {
        dispatch(updateBillBackToPayment(bill.damage_bill_id))
    };

    return (
        <Button className={"btn-danger"}
                style={{alignSelf: "flex-end", marginRight: "10px", textTransform: 'capitalize'}} onClick={onClickForm}>
            {BillDocumentTranslations.de.back_to_progress}
        </Button>
    );
}
