//@flow
import {AxiosResponse} from "axios";
import {authUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

export function getUsers(keyword: string, page: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${authUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${keyword}&page=${page}`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}

export function getUser(id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.get(`${authUrl}/${id}`, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function createUser(user, cancelTokenParam?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    // fd.append('username', user.username);
    // fd.append('password', user.password);
    // fd.append('first_name', user.first_name);
    // fd.append('last_name', user.last_name);
    // fd.append('mobile', user.mobile);
    // fd.append('phone', user.phone);
    // fd.append('email', user.email);
    // fd.append('address', user.address);
    // fd.append('country_ids', user.working_countries);

    Object.entries(user).forEach(([k, v]) => {
        if (v) {
            fd.append(k, v);
        }
    })
    return client.post(`${authUrl}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function updateUser(user, id, cancelTokenParam?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    // fd.append('username', user.username);
    // fd.append('password', user.password);
    // fd.append('first_name', user.first_name);
    // fd.append('last_name', user.last_name);
    // fd.append('mobile', user.mobile);
    // fd.append('phone', user.phone);
    // fd.append('email', user.email);
    // fd.append('address', user.address);
    // fd.append('country_ids', user.working_countries);

    Object.entries(user).forEach(([k, v]) => {
        if (v) {
            fd.append(k, v);
        }
    })


    return client.put(`${authUrl}/${id}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function updateUserWorkingCountries(country_ids, id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.put(`${authUrl}/${id}/workingcountry`, JSON.stringify(country_ids), {
        headers: {
            ...headers,
            'Content-type': 'application/json'
        },
        cancelToken: cancelTokenParam
    });
}

export function updateUserRoles(fd: FormData, id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.put(`${authUrl}/${id}/roles`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function getUserRoles(id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.get(`${authUrl}/${id}/roles`, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}
