/**
 * One place to import all epics and export them as one using `combineEpics`
 */

import {combineEpics} from 'redux-observable';
import {
    fetchBillsEpic,
    advancedFetchBillsEpic,
    filtersUpdatedEpic,
    nextPageEpic,
} from "../features/SearchPagination/searchPagination.epic";
import {
    fetchBillsLinkedToDamageEpic,
    fetchBillsLinkedToSchadensnummerEpic,
    fetchBillsLinkedToVertragsvereinbarungEpic,
    fetchBillsLinkedToFahrzeugidentnummerEpic,
    fetchCarDealerSoldBillEpic,
    fetchCarDealerSoldPolicyEpic,
    fetchContractSpentAmountEpic,
    fetchDamageBillEpic,
    fetchDamageEpic, fetchExternalProviderEpic,
    fetchMaterialWageEpic,
    fetchPreviousDamageEpic,
    fetchWarrantyContractEpic, markBillAsAuthorizedEpic, reFetchDamageBillEpic, resolveBillEpic, toggleDeleteEpic,
    updateBillBackToPaymentEpic,
    updateBillEpic,
    updateBillForPaymentEpic,
    updateBillManualSelectionEpic,
    fetchCurrencyRatesEpic,
    updateCurrencyEpic
} from "../features/DamageBill/bill.epics";

export default combineEpics(
    fetchBillsEpic,
    advancedFetchBillsEpic,
    filtersUpdatedEpic,
    nextPageEpic,
    fetchBillsLinkedToDamageEpic,
    fetchBillsLinkedToSchadensnummerEpic,
    fetchBillsLinkedToVertragsvereinbarungEpic,
    fetchBillsLinkedToFahrzeugidentnummerEpic,

    fetchDamageBillEpic,
    reFetchDamageBillEpic,
    fetchDamageEpic,
    fetchWarrantyContractEpic,
    fetchCarDealerSoldPolicyEpic,
    fetchCarDealerSoldBillEpic,
    fetchExternalProviderEpic,

    fetchContractSpentAmountEpic,
    fetchMaterialWageEpic,
    fetchPreviousDamageEpic,

    updateBillEpic,
    updateBillManualSelectionEpic,
    markBillAsAuthorizedEpic,
    updateBillForPaymentEpic,
    updateBillBackToPaymentEpic,

    toggleDeleteEpic,
    resolveBillEpic,
    fetchCurrencyRatesEpic,
    updateCurrencyEpic
);
