// @flow
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import React, {useEffect} from "react";
import {Spinner} from "react-bootstrap";
import {BrowserRouter as Router} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {checkLoggedUser} from "./features/Authentication/authSlice";
import {TopLevelRoutes} from "./routes";


require('dotenv').config();

const SpinnerContainer = () => (
    <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Spinner animation="border" role="status" variant={'primary'}>
            <span style={{visibility: 'hidden'}}>Loading...</span>
        </Spinner>
    </div>
)


function App() {
    const {loadingInitial} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkLoggedUser())
    }, [dispatch])


    return (<div className="App">
        <Router>
            {!loadingInitial
                ? <TopLevelRoutes/>
                : <SpinnerContainer/>
            }
        </Router>

    </div>)
}

export default App;
