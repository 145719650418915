import React from "react";
import Button from "react-bootstrap/Button"
import {useDispatch} from "react-redux";
import {logout} from "../../features/Authentication/authSlice";

function Logout() {
    const dispatch = useDispatch()
    const l = () => dispatch(logout())

    return (<div style={{display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '1rem'}}>
        <Button className={""} variant="primary" onClick={l}>Logout</Button>
    </div>)
}

export default Logout
