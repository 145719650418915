import {useCallback, useEffect, useRef, useState} from "react";
import Modal from "react-modal"
import {Form} from "react-bootstrap";
import * as React from "react"
import InView from ".././InView";
import {ListRow} from "./ListRow";
import {ListRowCheck} from "../permissions/ListRowCheck";
import "../users/Users.css";
import {
    getRoles,
    createRole,
    updateRole,
    getPermissions,
    getRolePermissions,
    updateRolePermission
} from '../../services/API/roles.service';
import useApi from "../../hooks/useApi";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";
import toast from "react-hot-toast";

const initState = {name: '', description: '', id: 0}

export function List() {
    let [roleState, setRoleState] = useState(initState);
    let [checkedIds, setCheckedIds] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPermissionsModal, setIsOpenPermissionsModal] = useState(false);
    const roleFormRef = useRef(null);
    const rolePermissionsFormRef = useRef(null);

    const {data: addRoleData, request: addRoleRequest, error: addRoleError} = useApi(createRole);
    const {data: updateRoleData, request: updateRoleRequest, error: updateRoleError} = useApi(updateRole);
    const [keyword] = useState('');
    const [statuses] = useState([]);
    const [, hasError, allRoles, hasMore, nextPage, refresh] = useSearchPaginator(keyword, statuses, getRoles);
    const [, hasErrorPermissions, allPermissions, hasMorePermissions, nextPagePermissions] = useSearchPaginator(keyword, statuses, getPermissions);
    // const {data: permissionsData, request: getPermissionsRequest, error: permissionsError} = useApi(getPermissions);
    const {
        data: rolePermissionsData,
        request: getRolePermissionsRequest,
        error: rolePermissionsError
    } = useApi(getRolePermissions);
    const {
        data: updateRolePermissionData,
        request: updateRolePermissionsRequest,
        error: updateRolePermissionsError
    } = useApi(updateRolePermission);


    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);

    const requestNextPageForPermissions = useCallback(() => {
        if (hasMorePermissions && !hasErrorPermissions) {
            nextPagePermissions()
        }
    }, [hasMorePermissions, hasErrorPermissions, nextPagePermissions]);


    const toggleModal = useCallback(() => {
        setRoleState(initState);
        setIsOpen(prevOpen => !prevOpen);
    }, [])

    const toggleModalPermissions = useCallback(() => {
        setIsOpenPermissionsModal(prevOpen => !prevOpen);
    }, [])


    const updateItem = useCallback((event) => {
        setRoleState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }, []);


    const onRoleFormSubmit = useCallback((e) => {
        e.preventDefault();
        const data = new FormData(roleFormRef.current);//izmenaaaa

        const role = {
            name: data.get('name'),
            description: data.get('description')
        }

        if (data.get('id') > 0) {
            const id = data.get('id');
            updateRoleRequest(id, role);
        } else {
            addRoleRequest(role);
        }

    }, [updateRoleRequest, addRoleRequest]);

    const onRolePermissionsFormSubmit = useCallback((e) => {
        e.preventDefault();

        const data = new FormData(rolePermissionsFormRef.current);

        data.set("permission_ids", checkedIds.join());
        console.log(data.get("permission_ids"));
        console.log(data.get("id"));
        updateRolePermissionsRequest({data, id: data.get("id")});

    }, [updateRolePermissionsRequest, checkedIds]);


    const loadItemForEdit = useCallback((item) => {
        setRoleState({...item})
        setIsOpen(true);
    }, [])

    const loadItemForEditPermission = useCallback((item) => {
        setCheckedIds([]);
        setRoleState(prevState => ({
            ...prevState,
            id: item.id,
            name: item.name,
            description: item.description,
        }));
        getRolePermissionsRequest({id: item.id});
    }, [getRolePermissionsRequest])

    const handleOnCheckChange = (id, isChecked) => {
        let tmpCheckedIds = checkedIds;
        if (isChecked) {
            tmpCheckedIds.push(id);
        } else {
            var index = tmpCheckedIds.indexOf(id);
            if (index !== -1) {
                tmpCheckedIds.splice(index, 1);
            }
        }
        setCheckedIds(tmpCheckedIds);
        console.log(checkedIds);
    }
    useEffect(() => {
        if (!addRoleData) return;
        if (addRoleError) {
            toast.error(addRoleError);
        } else {
            refresh();
            toggleModal();
        }
    }, [addRoleData, toggleModal, addRoleError, refresh]);

    useEffect(() => {
        if (!updateRoleData) return;
        if (updateRoleError) {
            toast.error(updateRoleError);
        } else {
            refresh();
            toggleModal();
        }
    }, [updateRoleData, toggleModal, updateRoleError, refresh]);


    // useEffect(() => {
    //     if (!permissionsData) return;
    //     if(permissionsError){
    //         alert(permissionsError);
    //     }
    //     else{
    //         setPermissionsList([...permissionsData._embedded.records]);
    //         toggleModalPermissions();
    //     }
    //
    // }, [permissionsData, permissionsError, toggleModalPermissions]);
    //
    useEffect(() => {
        if (!rolePermissionsData) return;
        if (rolePermissionsError) {
            toast.error(rolePermissionsError);
        } else {
            if (!rolePermissionsData._embedded) return;
            setCheckedIds(rolePermissionsData._embedded.records.map(({id}) => id));
            toggleModalPermissions();
            // getPermissionsRequest()
        }

    }, [rolePermissionsData, rolePermissionsError]);

    useEffect(() => {
        if (!updateRolePermissionData) return;
        if (updateRolePermissionsError) {
            toast.error(updateRolePermissionsError);
        } else {
            refresh();
            toggleModalPermissions();
        }

    }, [updateRolePermissionData, updateRolePermissionsError, toggleModalPermissions, refresh]);

    return (
        <div className={"list-container"}>
            <div className={"toolbox"}>
                <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModal}>Add role</button>
            </div>
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {

                        allRoles !== null && allRoles.map((item, index) => {
                                return (
                                    (index !== Math.round(allRoles.length / 2)) ?
                                        <ListRow key={"role_item_" + index} item={item} index={index}
                                                 onEdit={loadItemForEdit.bind(this, item)}
                                                 onEditPermissions={loadItemForEditPermission.bind(this, item)}/>
                                        : <InView as={ListRow} key={"role_item_" + index}
                                                  item={item} index={index}
                                                  onEdit={loadItemForEdit.bind(this, item)}
                                                  onEditPermissions={loadItemForEditPermission.bind(this, item)}
                                                  onceOnIntersect={requestNextPage}/>

                                )
                            }
                        )


                    }
                    </tbody>
                </table>
            </div>
            <Modal isOpen={isOpen}
                   onRequestClose={toggleModal}
                   overlayClassName={"modal-overlay"}
                   className={"modal-narrow"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Role">
                <Form id="roleForm" ref={roleFormRef} onSubmit={onRoleFormSubmit}>
                    <input type={"hidden"} name={"id"} value={roleState.id}/>
                    <div className={"form-group"}>
                        Name: <input type={"text"} className={"form-control"} name={"name"}
                                     onChange={updateItem} value={roleState.name}/>
                    </div>
                    <div className={"form-group"}>
                        Description: <textarea className={"form-control"} name={"description"}
                                               onChange={updateItem} value={roleState.description}/>
                    </div>
                    <button className={"btn btn-primary"}>Save</button>
                    <button onClick={toggleModal} className={"btn btn-danger"}>Cancel</button>
                </Form>
            </Modal>
            <Modal isOpen={isOpenPermissionsModal}
                   className={"modal-normal modal-height"}
                   onRequestClose={toggleModalPermissions}
                   overlayClassName={"modal-overlay"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Role permissions">
                <Form id={"rolePermissionsForm"} ref={rolePermissionsFormRef} onSubmit={onRolePermissionsFormSubmit}
                      className={"role-form"}>
                    <input type={"hidden"} name={"id"} value={roleState.id}/>
                    <div className={"form-group role-form-table"}>
                        <table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Permission name</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                allPermissions !== null &&
                                allPermissions.map((item, index) => {
                                    return (
                                        (index !== Math.round(allPermissions.length / 2)) ?
                                            <ListRowCheck key={item.id}
                                                          item={item}
                                                          index={index}
                                                          stateChanger={handleOnCheckChange}
                                                          isSelected={checkedIds.includes(item.id)}/>
                                            : <InView as={ListRowCheck} key={item.id}
                                                      item={item}
                                                      index={index}
                                                      stateChanger={handleOnCheckChange}
                                                      isSelected={checkedIds.includes(item.id)}
                                                      onceOnIntersect={requestNextPageForPermissions}/>

                                    )
                                })


                            }
                            </tbody>
                        </table>
                    </div>
                    <div className={"form-buttons-wrapper"}>
                        <button className={"btn btn-primary form-buttons"}>Save</button>
                        <button onClick={toggleModalPermissions} className={"btn btn-danger form-buttons"}>Cancel
                        </button>
                    </div>
                </Form>
            </Modal>
        </div>
    );
}