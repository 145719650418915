import {AxiosResponse} from "axios";
import {rolesUrl, permissionsUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

export function getRoles(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${rolesUrl}${queryString || ''}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    })
}

export function createRole(role, cancelTokenParam?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    fd.append('name', role.name);
    fd.append('description', role.description);
    return client.post(`${rolesUrl}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}

export function updateRole(roleId, roleData, cancelTokenParam?: any): Promise<AxiosResponse> {
    const fd = new FormData();
    fd.append('name', roleData.name);
    fd.append('description', roleData.description);


    return client.put(`${rolesUrl}/${roleId}`, fd, {
        headers: {
            ...headers
        },
        cancelToken: cancelTokenParam
    });
}
export function getPermissions(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${permissionsUrl}${queryString || ''}`, {
        headers: {
            ...headers
        },
        params: {
            page: pageNumber
        },
        cancelToken
    });
}
export function getRolePermissions(roleId, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${rolesUrl}/${roleId}/permissions`, {
        headers: {
            ...headers
        },
        cancelToken
    })
}
export function updateRolePermission(fd, roleId, cancelToken?: any): Promise<AxiosResponse> {

    return client.put(`${rolesUrl}/${roleId}/permissions`, fd, {
        headers: {
            ...headers
        },
        cancelToken
    })
}



