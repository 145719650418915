import React, {useEffect, useState} from 'react';
import {BILL_PROCESSING_STATUSES} from "../../StatusContext";
import {useDispatch, useSelector} from "react-redux";
import {toggleBillForOCR, updateArrayUncheck} from "../../../features/SearchPagination/searchPagination.slice";

function SendBillDocumentToOCRCheckbox(props) {
    const {billsIdsApprovedForOCR, all_selected, bills_versions_uncheck} = useSelector(state => state.searchPagination);
    const dispatch = useDispatch();
    let [state, setState] = useState({
        item: props['item'],
    });

    useEffect(() => {
        setState({item: props['item']})
    }, [props])

    /**
     * Update state about which items have been checked by adding or removing as checked an item
     * @param itemId
     */
    const handleClickedCheckbox = (itemId) => {
        dispatch(toggleBillForOCR(itemId));
    }
    const handleChecked = (checked) => {
        let updatedArray = [...bills_versions_uncheck];
        if (all_selected === true && checked === false) {
            if (!updatedArray.includes(state.item.id)) {
                updatedArray.push(state.item.id);
            }
        }
        if(all_selected === true && checked === true){
            if (updatedArray.includes(state.item.id)) {
                updatedArray = updatedArray.filter(item => item !== state.item.id);
            }
        }
            dispatch(updateArrayUncheck(updatedArray));
    }

    return (
        <label className="check-container"
               title="Check for BULK option"
               style={{
                   color: "#90a4ae",
                   display: state.item.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_CREATED ? "flex" : "none"
               }}
               htmlFor={"bill_bulk_checkbox_" + state.item.id}
        >
            <input type="checkbox"
                   className="link_checkbox"
                   data-itemid={state.item.id}
                   data-processing_status={state.item.processing_status}
                   id={"bill_bulk_checkbox_" + state.item.id}
                   checked={
                       all_selected
                           ? (billsIdsApprovedForOCR[state.item.id] === undefined ? true : billsIdsApprovedForOCR[state.item.id])
                           : billsIdsApprovedForOCR[state.item.id] || false
                   }
                   onChange={(e) => {handleClickedCheckbox(state.item.id);handleChecked(e.target.checked) }}
            />
            <span className="checkmark"/>
        </label>
    );
}

export default React.memo(SendBillDocumentToOCRCheckbox)
