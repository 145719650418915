// @flow
import * as React from 'react';
import toast from "react-hot-toast";
import {AxiosError} from "axios";
import {AxiosResponse} from "axios";
import {sendBillsToOCR} from "../../../services/API/damageBills.service";
import {useDispatch, useSelector} from "react-redux";
import {
    markAsSentToOCS,
    toggleSelectAllButton
} from "../../../features/SearchPagination/searchPagination.slice";


export function BulkBillsButton() {
    const {billsIdsApprovedForOCR, all_selected, bills_versions_uncheck} = useSelector(state => state.searchPagination)
    const dispatch = useDispatch();

    const sendDocumentsForSelectedBills = async () => {
        const checkBills = Object.keys(billsIdsApprovedForOCR).filter(id => billsIdsApprovedForOCR[id])
        await toast.promise(
            sendBillsToOCR(checkBills, all_selected,bills_versions_uncheck),
            {
                loading: 'Sending to OCR', success: (response: AxiosResponse) => {
                    if (response.status !== 200) return;
                    const d = checkBills.length;

                    /**
                     * Hide the checkbox for sending a bill to OCR
                     * after successfully sending those same bills to OCR
                     */
                    dispatch(markAsSentToOCS(checkBills))
                    dispatch(toggleSelectAllButton(false))

                    return `${d > 1 ? 'Bills' : 'Bill'} successfully sent to OCR`
                }, error: (err: AxiosError) => {
                    console.log(err)
                    return err.response?.data?.message || 'Oops'
                }
            })
    };

    return (
        <>
            <button
                className={"mainButton btn btn-primary"}
                style={{float: "left", marginRight: "5px"}}
                onClick={sendDocumentsForSelectedBills}
            >
                <label className="label" style={{height: "13px"}}>Send documents to OCR</label>
            </button>
        </>);
}
