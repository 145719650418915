import {Redirect, Route} from "react-router-dom";
import React from "react";
import {RouteProps} from "react-router";
import {useSelector} from "react-redux";

export function ProtectedRoute(props: RouteProps) {
    // const {state} = useAuth();
    const { loggedUser } = useSelector(state => state.auth)

    if (loggedUser.uid) return <Redirect to="/"/>;
    return <Route {...props} />;
}
