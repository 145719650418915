export const BillDocumentTranslations = {
    en: {
        "payment_recpt_label": "payment recipient",
        "payment_recpt_opt_del": "delete payment recipient",
        "payment_recpt_opt_contractor": "contractor",
        "payment_recpt_opt_partner": "RG contract partner",
        "payment_recpt_opt_other": "Other recipients",
        "damage_contract_id": "contract ID",
        "bill_number": "bill number",
        "contact_from_contractor": "contractor",
        "contact_from_policy": "policyholder",
        "contact_from_workshop": "workshop",
        "contact_from_brk_down": "breakdown service",
        "contact_from_expert": "technical expert",
        "contact_from_overhauler": "overhauler",
        "contact_from_lawyer": "lawyer",
        "claims_modal_search_claims": "search claims",
        "damage_number": "damage number",
        "contract_number": "contract number",
        "chassis_number": "chassis number",
        "back_to_progress": "back to progress",
        "auth_bill_modal_title": "Authorization",
        "auth_bill_modal_button": "Authorize",
        "auth_bill_modal_mark_closed": "Mark damage as closed by this bill",
        "bill_item_type_labor": "labor",
        "bill_item_type_part": "spare parts",
        "bill_item_type_other": "other",
        "bill_status_doc_deleted": "Deleted",
        "bill_status_doc_created": "Document created",
        "bill_status_doc_sent": "Document sent to OCR",
        "bill_status_doc_parsed": "Document parsed by OCR",
        "bill_status_setup": "Manual setup",
        "bill_status_progress": "In progress",
        "bill_status_rfpayment": "Ready for payment",
        "bill_status_authorized": "Authorized",
        "bill_status_resolved": "Booked",
        "bill_status_payed": "Payed",
        "default_email_label": "Default E-mail",
        "locked_by": 'Locked by',
        "rel_access": 'release access',
        "req_access": 'request access',
        "bill_delete": 'Delete',
        "bill_undelete": 'Undelete',
        "iban_default_value": 'No payment due to product type',
        "email_default_value": 'No email available'
    },
    de: {
        "payment_recpt_label": "zahlungsempfänger",
        "payment_recpt_opt_del": "zahlungsempfänger entfernen",
        "payment_recpt_opt_contractor": "vertragsnehmer",
        "payment_recpt_opt_partner": "RG-Vertragspartner",
        "payment_recpt_opt_other": "sonstige empfänger",
        "damage_contract_id": "vertragsvereinbarung",
        "bill_number": "rechnungsnummer",
        "contact_from_contractor": "vertragspartner",
        "contact_from_policy": "versicherungsnehmer",
        "contact_from_workshop": "werkstatt",
        "contact_from_brk_down": "pannenhilfe",
        "contact_from_expert": "sachverständiger",
        "contact_from_overhauler": "instandsetzer",
        "contact_from_lawyer": "anwalt",
        "claims_modal_search_claims": "schaden suchen",
        "damage_number": "schadensnummer",
        "contract_number": "vertragsvereinbarung",
        "chassis_number": "fahrzeugidentnummer",
        "back_to_progress": "nochmal bearbeiten",
        "auth_bill_modal_title": "freigabe",
        "auth_bill_modal_button": "Zahlung freigeben",
        "auth_bill_modal_mark_closed": "Schaden schließen",
        "bill_item_type_labor": "lohn",
        "bill_item_type_part": "teile",
        "bill_item_type_other": "sonstiges",
        "bill_status_doc_deleted": "Gelöschte Dokumente ",
        "bill_status_doc_created": "Hochgeladene Dokument",
        "bill_status_doc_sent": "Versendete Dokumente",
        "bill_status_doc_parsed": "Ausgelesene Dokumente",
        "bill_status_setup": "Manuele Bearbeitung",
        "bill_status_progress": "In Bearbeitung",
        "bill_status_for_payment": "Zur Zahlung bereitgestellt",
        "bill_status_authorized": "Zahlung Autorisiert",
        "bill_status_resolved": "Gebucht",
        "bill_status_payed": "Bezahlt",
        "default_email_label": "Mail Zahlungsempfänger",
        "locked_by": 'Gesperrt von',
        "rel_access": 'Bearbeitung freigeben',
        "req_access": 'Annehmen',
        "bill_delete": 'Löschen',
        "bill_undelete": 'Wiederherstellen',
        "iban_default_value": 'Keine Zahlung aufgrund des Produkts',
        "email_default_value": 'Keine Emailadresse vorhanden'

    }
}
