export const GeneralTranslations = {
    en: {
        "label_search": 'search',
        "label_cancel": 'cancel',
        "label_please": 'please',
        "label_authorize": 'authorize',
        "label_resolve": 'add booking',
        "label_select": 'select'
    },

    de: {
        "label_search": 'suchen',
        "label_cancel": 'abbrechen',
        "label_please": 'bitte',
        "label_authorize": 'Zur Zahlung anweisen',
        "label_resolve": 'Buchung hinzufügen',
        "label_select": 'auswählen'
    }
}
