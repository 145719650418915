import React, {useCallback, useMemo} from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import {createTheme} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import {useDispatch, useSelector} from "react-redux";
import {markAsSold} from "../../../features/DamageBill/bill.slice";
import {adaptV4Theme} from "@mui/material";

export const theme = createTheme(adaptV4Theme({
    overrides: {
        MuiSwitch: {
            root: {
                fontSize: '1rem',
                marginTop: "26px"
            },
        },
    },

}));

const useStyles = makeStyles(() => ({
    label: {
        // height: "14px",
        alignSelf: "stretch",
        flexGrow: 0,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.2px",
        textAlign: "left",
        color: "#a4a9ad",
        width: "auto",
        display: "flex",
        marginLeft: "0px",
        marginTop: "-2px",
    },
    fieldInputSwitch: {
        width: "auto",
        alignSelf: "stretch",
        flexGrow: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        gap: "10px",
        padding: 0,
    },
    frame9: {
        height: "26px",
        alignSelf: "stretch",
        flexGrow: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "15px",
        padding: 0,
    }

}));

const IOSSwitch = withStyles((theme) => ({

    label: {
        height: "14px",
        alignSelf: "stretch",
        flexGrow: 0,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "0.2px",
        textAlign: "left",
        color: "#a4a9ad",
        width: "auto",
        display: "block",
        marginLeft: "0px",
        marginTop: "-2px",
    },
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: "0 !important",
        fontSize: "14px",
        // position: "absolute"
    },
    switchBase: {
        padding: 1,
        "&.Mui-checked": {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#52d869",
            opacity: 1
        },
        "&.Mui-disabled": {
            color: theme.palette.grey[400],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
            border: 'none'
        },
        // '&$checked': {
        //     transform: 'translateX(16px)',
        //     color: theme.palette.common.white,
        //     '& + $track': {
        //         backgroundColor: '#52d869',
        //         opacity: 1,
        //         border: 'none',
        //     },
        // },
        // '&$focusVisible $thumb': {
        //     color: '#52d869',
        //     border: '6px solid #fff',
        // },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


const MemoIOSSwitch = () => {
    const {isSold, isSoldToggleDisabled, isReadOnly} = useSelector(state => state.damageBill)
    const dispatch = useDispatch()

    const handleChange = useCallback((event) => {
        dispatch(markAsSold(event.target.checked))
    }, [dispatch])

    return useMemo(() => {
        return <IOSSwitch checked={isSold} onChange={handleChange}
                          name="checkedSame" disabled={isReadOnly || isSoldToggleDisabled}/>
    }, [isSold, isReadOnly, isSoldToggleDisabled, handleChange]);
}

export default function SameAsBilledByCarDealerToggle() {
    const classes = useStyles();

    // using 'useMemo' as Option 3 from here https://github.com/facebook/react/issues/15156
    // re-render only dependencies are updated
    return (
        <div>
            <FormControl component="fieldset" className={classes.fieldInputSwitch}>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        label={<span style={{fontSize: '14px'}}>{'wie Verkdufer'}</span>}
                        labelPlacement="top"
                        className={classes.label}
                        control={<MemoIOSSwitch/>}
                    />
                </FormGroup>
            </FormControl>
        </div>
    );
}
