import {AxiosResponse} from "axios";
import {AuthResponse, authUrl} from "../ApiService";
import {client, headers} from "../../app/axiosClient";

/**
 * Logs a user in the platform
 * @param username
 * @param password
 * @param cancelTokenParam
 * @returns {Promise<AxiosResponse<AuthResponse>>}
 */
export function authLogin(username: string, password: string, cancelTokenParam?: any): Promise<AxiosResponse<AuthResponse>> {
    const fd = new FormData();
    fd.append('username', username);
    fd.append('password', password);
    return client.post(`${authUrl}/login`, fd, {
        headers: {
            ...headers,
        },
        cancelToken: cancelTokenParam
    })
}

/**
 * Logs user out of the platform
 * @param cancelTokenParam
 * @returns {Promise<AxiosResponse<AuthResponse>>}
 */
export function authLogout(cancelTokenParam?: any): Promise<AxiosResponse<AuthResponse>> {
    const fd = new FormData();
    return client.post(`${authUrl}/logout`, fd, {
        headers: {
            ...headers,
        },
        cancelToken: cancelTokenParam
    })
}

/**
 * Verifies if a user is currently logged in
 * @returns {Promise<AxiosResponse<AuthResponse>>}
 */
export function checkForUser(): Promise<AxiosResponse<AuthResponse>> {
    return client.post(`${authUrl}/accesstokenrefresh`);
}

export function requestForgottenPasswordEmail(toEmailAddress: string, cancelTokenParam?: any): Promise<AxiosResponse> {
    const f = new FormData();
    f.append('email', toEmailAddress);
    return client.post(`${authUrl}/forgottenpassword`, f, {
        headers: {
            ...headers,
        },
        cancelToken: cancelTokenParam
    })
}

export function checkResetPasswordToken(fd: FormData, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.post(`${authUrl}/checktoken`, fd, {
        headers: {
            ...headers,
        },
        cancelToken: cancelTokenParam
    })
}

export function changePassword(token: string, p: string, rP: string, cancelTokenParam?: any): Promise<AxiosResponse> {
    const f = new FormData();
    f.append('token', token);
    f.append('password', p);
    f.append('repeatPassword', rP);
    return client.post(`${authUrl}/changepassword`, f, {
        headers: {
            ...headers,
        },
        cancelToken: cancelTokenParam
    })
}