import * as Constants from "../constants";
import {precisionRound} from "../components/bills/form/FormBillItems";

// These is not used anywhere
// const NOT_PAYING_CODE = 72;
// class DamageItemsCalculations {
//     handworkPriceWithDiscoount = 0;
//     handworkPriceCovered = 0;
//     handworkPrice = 0;
//
//     sparePartPriceWithDiscoount = 0;
//     sparePartPriceCovered = 0;
//     sparePartMaterialDiscount = 0;
//
//     deductionByContract = 0;
//     badStatsDeduction = 0;
//
//     totalWithoutVAT = 0;
//     vat = 0;
//     secondHandPartVAT = 0;
//
//     total = 0;
//
//     item = null;
//
//     constructor(item) {
//         this.item = item;
//
//         this.handworkPriceWithDiscoount = parseFloat(item.handwork_price*((100-item.handwork_discount_in_percent)/100));
//         this.handworkPriceCovered = parseFloat(this.handworkPriceWithDiscoount*((100-item.handwork_covered_in_percent)/100));
//         this.handworkPrice = parseFloat(this.handworkPriceCovered*((100-item.partner_discount_in_percent)/100));
//
//         this.sparePartPriceWithDiscoount = parseFloat(item.spare_part_price*((100-item.spare_part_discount_in_percent)/100));
//         this.sparePartPriceCovered = parseFloat(this.sparePartPriceWithDiscoount*((100-item.spare_part_covered_in_percent)/100));
//         this.sparePartMaterialDiscount = parseFloat(this.sparePartPriceCovered*((100-item.material_discount_in_percent)/100));
//
//         this.deductionByContract = parseFloat((this.handworkPrice + this.sparePartMaterialDiscount)*((item.deduction_by_contract_in_percent)/100));
//         this.badStatsDeduction = parseFloat((this.handworkPrice + this.sparePartMaterialDiscount)*((item.deduction_by_bad_stats_in_percent)/100));
//
//         this.totalWithoutVAT = this.handworkPrice + this.sparePartMaterialDiscount - this.deductionByContract - this.badStatsDeduction;
//         this.vat = this.totalWithoutVAT * ((item.vat_in_percent)/100);
//         this.secondHandPartVAT = this.sparePartMaterialDiscount * ((item.second_hand_vat_in_percent)/100);
//
//         this.total = this.totalWithoutVAT + this.vat + this.secondHandPartVAT;
//
//     }
//
//     getTotal() {
//         return this.total
//     }
//
// }
export function calculateQuantityPriceZero(item) {
    let total;
    if (item.price && item.quantity) {
        let totalWithoutDiscount = precisionRound(item.price * item.quantity, 2);
        total = totalWithoutDiscount * ((100 - item.discount) / 100);
    } else {
        total = 0;
    }
    return total;
}

export function calculateItemTotal(item) {
    let total;
    let totalWithoutDiscount = parseFloat(item.total_for_paying ? item.total_for_paying : calculateQuantityPriceZero(item));
    totalWithoutDiscount = precisionRound(totalWithoutDiscount, 2)
    total = totalWithoutDiscount * ((100 - item.discount) / 100);
    total = precisionRound(total, 2)
    return (isNaN(total) ? 0 : total);
}

export function calculateItemTotalDeduction(item) {
    let totalDeduction = 0;
    if (item.paying_code != null && parseFloat(item.paying_code) > 0) {
        totalDeduction = parseFloat(item.total_for_paying ? item.total_for_paying : calculateQuantityPriceZero(item));
    } else if (item.deduction_in_percent != null && parseInt(item.deduction_in_percent) !== 0) {
        totalDeduction = parseFloat(item.total_for_paying ? item.total_for_paying : calculateQuantityPriceZero(item)) * (item.deduction_in_percent / 100);
    }
    totalDeduction = precisionRound(totalDeduction, 2)

    return (isNaN(totalDeduction) ? 0 : totalDeduction);
}

export function calculateItemTotalAfterCalculation(item, firstVat) {
    let itemTotal = calculateItemTotal(item);
    let itemTotalDeduction = calculateItemTotalDeduction(item);
    let itemTotalAfterDeduction = itemTotal - itemTotalDeduction;
    let itemTotalAfterTax = itemTotalAfterDeduction + itemTotalAfterDeduction * firstVat / 100;

    let secondVatAmount = item.item_type === Constants.ITEM_TYPE_SPARE_PART && item.is_taxable_for_second_tax ? itemTotalAfterTax * 10 / 100 : 0;
    return (itemTotalAfterTax + secondVatAmount);
}

export function convertForeignToDomesticCurrency(foreignValue, rate) {
    if (isNaN(parseFloat(foreignValue)) || isNaN(parseFloat(rate))) return 0;
    else return parseFloat(foreignValue) / parseFloat(rate);
}
