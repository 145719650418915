import React from "react";
import "./switch.css";

export const Switch = ({isOn, handleToggle, onColor}) => {
    return (
        <label style={{background: isOn && onColor}} className="react-switch">
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                type="checkbox"
            />
            <div className="react-switch-button" style={{background: isOn ? '#07aa35' : 'grey'}}/>
        </label>
    );
};