import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Modal from "react-modal";
import {ListRow} from "./ListRow";
import useApi from "../../../hooks/useApi";
import InView from "../../InView";
import {
    createTeamMember, deleteTeamMember, getAllCountries,
    getTeamMembers,
    updateUserCountry
} from "../../../services/API/teams.service";
import {AddMemberForm, ChangeCountryForm} from "./UserForm";
import {useParams} from "react-router-dom";
import {useTablePaginator} from "../../../pages/Pay/useTablePaginator";

const initState = {
    id: 0,
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    title_id: 0,
    email: '',
    mobile: '',
    phone: '',
    address: '',
    zip_code_id: 0,
    language_id: 0,
    is_active: true,
    is_deleted: false
};

export function List() {
    let [userState, setUserState] = useState(initState);
    const {teamId} = useParams();

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const c = useCallback((...args) => createTeamMember(teamId, ...args), [teamId])
    const {data: addTeamData, request: addTeamRequest, error: addTeamError} = useApi(c);
    const {data: deleteTeamData, request: deleteTeamRequest, error: deleteTeamError} = useApi(deleteTeamMember);
    const {data: updateUserData, request: updateUserRequest, error: updateUserError} = useApi(updateUserCountry);

    const {data: allCountries, request: getCountriesRequest} = useApi(getAllCountries);

    const countries = useMemo(() => {
        const data = {}


        if(allCountries) {
            (allCountries?._embedded?.records || []).forEach(r => {
                data[r.country_id] = r.country_name
            })
        }



        return data ;

    }, [allCountries])

    console.log(countries)

    const t = useCallback((...args) => getTeamMembers(teamId, ...args), [teamId])
    const [, hasError, allTeams, hasMore, , refresh, nextPage] = useTablePaginator(t);


    const requestNextPage = useCallback(() => {
        if (hasMore && !hasError) {
            nextPage()
        }
    }, [hasMore, hasError, nextPage]);

    useEffect(() => {
        getCountriesRequest()
    }, [])


    useEffect(() => {
        if (addTeamData && addTeamData.code === 200) {
            refresh();
        } else if (addTeamError) {
            console.log(addTeamError)
        }
    }, [addTeamData, addTeamError, refresh]);

    useEffect(() => {
        if (deleteTeamData && deleteTeamData.code === 200) {
            refresh();
        } else if (deleteTeamError) {
            console.log(deleteTeamError)
        }
    }, [deleteTeamData, deleteTeamError, refresh]);

    useEffect(() => {
        if (updateUserData !== null && updateUserData.code === 200) {
            refresh();
        } else if (updateUserError) {
            alert(updateUserError);
        }
    }, [updateUserData, updateUserError, refresh]);


    const toggleModal = useCallback(() => {
        if (!isOpen) {
            setUserState({...initState});
        }
        setIsOpen(isOpen => !isOpen);
    }, [isOpen]);

    const toggleModalAdd = useCallback(() => {
        if (!isOpenAdd) {
            setUserState({...initState});
        }
        setIsOpenAdd(isOpen => !isOpen);
    }, [isOpenAdd]);


    const onTeamFormSubmit = useCallback((data) => {
        const team = {
            user_id: userState.user_id,
            country_id: data.get('country_id'),
        }
        if (data.get('id') > 0) {
            updateUserRequest(team);
            toggleModal();
        }
    }, [updateUserRequest, toggleModal, userState.user_id]);


    const onAddFormSubmit = useCallback((data) => {
        const team = {
            user_id: data.get('member_id'),
        }

        addTeamRequest({team});
        toggleModalAdd();

    }, [addTeamRequest, toggleModalAdd]);


    const loadForEdit = useCallback((item) => {
        setUserState({...item});
        setIsOpen(true);
    }, []);


    const deleteTeamAction = useCallback((item) => {
        deleteTeamRequest({id: item.team_id, member_id: item.user_id})
    }, []);

    return (<div className={"list-container"}>
        <div className={"toolbox"}>
            <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModalAdd}>Add team member</button>
        </div>
        <div>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Country</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>

                {allTeams !== null && allTeams.map((item, index) => {
                    const i = {
                        ...item,
                        country_name: countries[item.user.working_country_id]
                    }
                    return (index !== Math.round(allTeams.length / 2)) ? <ListRow key={"user_item_" + i.id} item={i}
                                                                                  onEdit={loadForEdit.bind(this, i)}
                                                                                  onDelete={deleteTeamAction.bind(this, i)}
                    /> : <InView as={ListRow} key={"user_item_" + i.id} item={i}
                                 onEdit={loadForEdit.bind(this, i)}
                                 onDelete={deleteTeamAction.bind(this, i)}
                                 onceOnIntersect={requestNextPage}
                    />

                })

                }


                </tbody>
            </table>
        </div>
        <Modal isOpen={isOpenAdd}
               onRequestClose={toggleModalAdd}
               overlayClassName={"modal-overlay"}
               className={"modal-wide"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="New team">
            <AddMemberForm toggleModal={toggleModalAdd} formSubmit={onAddFormSubmit} fields={userState}/>
        </Modal>

        <Modal isOpen={isOpen}
               onRequestClose={toggleModal}
               overlayClassName={"modal-overlay"}
               className={"modal-wide"}
               appElement={document.getElementsByTagName('body')}
               contentLabel="New team">
            <ChangeCountryForm toggleModal={toggleModal} formSubmit={onTeamFormSubmit} fields={userState}/>
        </Modal>
    </div>);
}
