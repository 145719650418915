import {
    map,
    filter,
    switchMap,
    mergeMap,
    takeUntil,
    catchError,
} from 'rxjs/operators'
import {
    advanceFetchBills, advanceFetchBillsFailed, advanceFetchBillsFullFilled,
    fetchBills,
    fetchBillsFailed,
    fetchBillsFullFilled,
    nextPage, toggleSortOrder, updateFilters,
} from "./searchPagination.slice";
import {combineLatest, of} from "rxjs";
import {advanceSearchBills, searchBills} from "../../services/API/damageBills.service";
import {fromAxios} from "../../utils/observables/fromAxios.observable";


export const nextPageEpic = action$ =>
    action$.pipe(
        filter(nextPage.match),
        map(() => fetchBills())
    )

export const filtersUpdatedEpic = action$ =>
    action$.pipe(
        filter((action) => {
            return updateFilters.match(action) || toggleSortOrder.match(action)
        }),
        map(() => fetchBills())
    )

const mapToQueryParamsAndPage = ([keyword, pageNumber, statuses, sortedColumn, sortOrder]) => {
    // let queryParams = '';
    // let binder = '?';
    // if (keyword !== '') {
    //     queryParams = `${binder}filters[0][operator]=eq&filters[0][property]=multiple_property&filters[0][value]=${keyword}`;
    //     binder = '&';
    // }
    // if (statuses.length > 0) {
    //     queryParams += `${binder}filters[1][operator]=in&filters[1][property]=processing_status&filters[1][value]=${statuses.join(',')}`;
    // }
    //
    // if (sortedColumn && sortOrder) {
    //     queryParams += `${binder}sort[0][column]=${sortedColumn}&sort[0][order]=${sortOrder}`;
    // }
    // return [queryParams, pageNumber]

    // return {
    //     filters: [
    //         {
    //             operator: 'eq',
    //             property: 'multiple_property',
    //             value: keyword
    //         },
    //         {
    //             operator: 'in',
    //             property: 'processing_status',
    //             value: statuses.join(',')
    //         }
    //     ],
    //     sort: [
    //         {
    //             column: sortedColumn,
    //             order: sortOrder
    //         }
    //     ],
    //     page: pageNumber
    // }
    return {
        keyword,
        pageNumber,
        statuses,
        sortedColumn,
        sortOrder
    }
}

const mapResponseToPayload = ({data}) => ({
    billsPaginated: data._embedded.records,
    hasMore: data._page_count > data._page,
    totalItems: data._total_items
})

/**
 * Epic used to fetch bills for BillsList
 * @param action$
 * @param state$ is used to populate the request with the proper params
 * @returns {*}
 */
export const fetchBillsEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchBills.match),
        mergeMap(() =>
            combineLatest(
                [
                    of(state$.value.searchPagination.keyword), // make observables of state
                    of(state$.value.searchPagination.pageNumber),
                    of(state$.value.searchPagination.statuses),
                    of(state$.value.searchPagination.sortedColumn),
                    of(state$.value.searchPagination.sortOrder),
                ]
            )
                .pipe(
                    map(mapToQueryParamsAndPage), // map the state to a more convenient format
                    switchMap((obj) =>
                        fromAxios(searchBills, [obj])
                            .pipe(
                                takeUntil( // if another action for fetching bills gets dispatched cancel this one
                                    action$
                                        .pipe(
                                            filter(fetchBills.match)
                                        )
                                ),
                                map(mapResponseToPayload),
                                map((data) => fetchBillsFullFilled(data)), // dispatch action with response data
                                catchError(() => of(fetchBillsFailed())), // dispatch action with error
                            )
                    ),
                )
        )
    );

/**
 * Epic used to fetch bills for BillsList
 * @param action$
 * @returns {*}
 */
export const advancedFetchBillsEpic = (action$) =>
    action$.pipe(
        filter(advanceFetchBills.match),
        switchMap(({payload}) =>
            fromAxios(advanceSearchBills, [payload])
                .pipe(
                    takeUntil( // if another action for fetching bills gets dispatched cancel this one
                        action$
                            .pipe(
                                filter(advanceFetchBills.match)
                            )
                    ),
                    map(mapResponseToPayload),
                    map((data) => advanceFetchBillsFullFilled(data)), // dispatch action with response data
                    catchError(() => of(advanceFetchBillsFailed())), // dispatch action with error
                )
        ),
    );
