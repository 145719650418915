// @flow
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {updateTotalValue} from "../../../features/DamageBill/bill.slice";
import {BillFormContext} from "./BillForm";

export function TotalValue({showConvertedValue, hideConvertedValue}) {

    const dispatch = useDispatch();
    const {paymentTotal, isReadOnly} = useSelector(state => state.damageBill)
    const {billFormRef} = useContext(BillFormContext);


    const isValid = useCallback((v) => {
        const r = new RegExp('^-?\\d*(\[\.\,]\\d{0,2})?$')
        console.log(r.test(v))
        return r.test(v);
    }, [])

    const handleChange = useCallback((e) => {
        const {value: v} = e.target;
        if (v === '') {
            dispatch(updateTotalValue(v));
            return;
        }
        if (isValid(v)) {
            dispatch(updateTotalValue(v));
        }

    }, [isValid])

    function formatValue(e) {
        if (e.target.value.trim() === '') {
            dispatch(updateTotalValue(''))
            return;
        }
        dispatch(updateTotalValue(parseFloat(e.target.value).toFixed(2)));
        if (billFormRef && billFormRef.current) {
            billFormRef.current.requestSubmit();
        }
    }

    //save that could  be implemented on change field
    // const timeoutOn = () => {
    //      setTimeout(()=>{
    //          if (billFormRef && billFormRef.current) {
    //              billFormRef.current.requestSubmit();
    //          }
    //      },2000)
    // }
    // const timeoutOf = () => {
    //     clearTimeout(2000)
    // }


    return (
        <div className={"Field-Input-Drop"} style={{flex: '1 1 170', maxWidth: '170px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>Gesamter Rechnungsbetrag</Form.Label>
                <div className={"Field-Input-Text"} onMouseOver={(e) => showConvertedValue(e, paymentTotal)}
                     onMouseOut={hideConvertedValue}>
                    <Form.Control className={"Input-text"}
                                  value={paymentTotal}
                                  type={'number'}
                                  step={0.01}
                                  name="total_for_payment"
                                  onChange={(e) => handleChange(e)}
                                  onBlur={formatValue}
                                  disabled={isReadOnly}
                        // onKeyDown={timeoutOf}
                        // onKeyUp={timeoutOn}
                    />
                </div>


            </Form.Group>
        </div>

    );
}
