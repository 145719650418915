//@flow
import React, {useCallback, useContext, useEffect, useState} from "react";
import {BillFormContext} from "../BillForm";
import {BILL_PROCESSING_STATUSES} from "../../../StatusContext";
import dateFormat from 'dateformat';
import './form-bills.css';
import {useSelector} from "react-redux";
import useApi from "../../../../hooks/useApi";
import {getBillManualSetupData} from "../../../../services/API/damageBill.service";
import toast from "react-hot-toast";

/**
 * This is used for mapping response data to headers of table
 * @type {{item_code: string, unit: string, quantity: string, tax_type: string, total_amount: string, item_type: string, index: string, description: string, discount: string, price_per_unit: string}}
 */
const TableStructureItems = {
    index: '#',
    item_type: 'Kostenart',
    item_code: 'Nummer',
    description: 'Bezeichnung',
    quantity: 'Anzahl',
    unit: 'Unit',
    price_per_unit: 'Preis',
    discount: 'Nachlass',
    tax_type: 'Steuer',
    total_amount: 'Gesamtbetrag'
}


/**
 * This is used for mapping response data to the attributes needed to be displayed
 * **HINT** we could use a Class or Interface to to that in the future
 */
const mapManualSetupDate = (records) => {
    return Object.keys(records).map(key => records[key])
        .map(({
                  sender_name,
                  sender_address,
                  sender_iban,
                  sender_bic,
                  sender_telephone,
                  sender_email,
                  contract_number,
                  plate_number,
                  chassis_number,
                  acceptance_date,
                  vehicle_mileage_after_reparation,
                  car_model_type,
                  car_owner,
                  sum_of_labour,
                  sum_of_material,
                  sum_of_taxes,
                  net_amount,
                  total_amount,
                  merge_status,
                  id,
                  items
              }) => ({
            id,
            items,
            shop: ['Autohaus', `${sender_name}, ${sender_address}`],
            iban: ['IBAN', sender_iban],
            bic: ['BIC', sender_bic],
            telephone: ['Telefon', sender_telephone],
            email: ['E-Mail', sender_email],
            cno: ['Vertragsvereinbarung', contract_number],
            pno: ['Kennzeichen', plate_number],
            chno: ['Fahrgestellnummer', chassis_number],
            d: ['Annahmedatum', acceptance_date?.date ? dateFormat(acceptance_date?.date, "dd.mm.yyyy") : '-'],
            kms: ['KM-Stand Reparatur', vehicle_mileage_after_reparation],
            model: ['Fahrzeugmodell', car_model_type],
            owner: ['Fahrzeughalter', car_owner],
            sum_of_labour: ['Gesammt Lohn', sum_of_labour],
            sum_of_material: ['Gesammt Material', sum_of_material],
            sum_of_taxes: ['Gesammt Steuer', sum_of_taxes],
            net_amount: ['Gesammt netto', net_amount],
            total_amount: ['Gesammter Rechnungsbetrag', total_amount],
            merge_status: [' ', merge_status === 1 ? 'Original' : merge_status === 2 ? 'Merged' : '']
        }));
}

export function ManualSetUpFilePicker() {

    // Global state
    const {setManualHeaderId, setManualPositionsId} = useContext(BillFormContext);
    const {bill} = useSelector(state => state.damageBill)

    // Inner state
    const [records, setRecords] = useState([]);
    const ids = [];

    const {data, request, error} = useApi(getBillManualSetupData);

    useEffect(() => {
        if (!data) return;
        if (error) {
            toast.error(error, {id: 'getManualDataError'});
        } else {
            if (data.data?.records)
                setRecords(mapManualSetupDate(data.data.records))
            else setRecords([])
        }
    }, [data, error])

    /**
     * Listening to 'bill' changes
     */
    useEffect(() => {
        if (!bill) return;
        if (bill.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_REQUEST_MANUAL_SETUP) {
            request({id: bill.damage_bill_id});
        }
    }, [bill, request])

    const onChangeSelectedHeaderId = useCallback((e) => {
        setManualHeaderId(e.target.value);
    }, [setManualHeaderId])
    const onChangeSelectedPositionsId = useCallback((e) => {
        if (e.target.checked) {
            ids.push(e.target.value);
        } else {
            ids.splice(ids.indexOf(e.target.value), 1);
        }
        setManualPositionsId(ids);
    }, [setManualPositionsId])

    return (
        <div className={'manual-setup-data-option-container'}>

            <h4 style={{margin: '0'}}>Bitte wahlen Sie die richtigen Abrechnungsinformationen aus</h4>
            {records.map((record) => (
                <div key={record.id} className={'manual-setup-data-option-group'}>
                    <h4>Header's Data {record?.merge_status[1] !== '' && `(${record?.merge_status[1]})`}</h4>
                    <div className={'manual-setup-data-option-inner'}>
                        <div className={'manual-setup-data-option'}>
                            {Object.entries(record)
                                .filter(([k]) => k !== 'id' && k !== 'items')
                                .map(([k, v]) => {
                                        return (
                                            <div key={k} className={'manual-setup-data-option-item'}>
                                                <div className={'manual-setup-data-option-item-name'}>{v[0]}</div>
                                                <div
                                                    className={'manual-setup-data-option-item-data'}>{v[1] || '-'}</div>
                                            </div>
                                        )
                                    }
                                )}
                        </div>
                        <input value={record.id}
                               title='Select header data from this json file'
                               onChange={onChangeSelectedHeaderId}
                               type={'radio'}
                               name={'headers_record_id'}/>
                    </div>

                    <div className='manual-setup-data-option-group-divider'/>

                    <h4>Lohn- und Materialpositionen</h4>
                    <div className={'manual-setup-data-option-inner'}>
                        <table>
                            <thead>
                            <tr>
                                {Object.values(TableStructureItems).map(thVal => (
                                    <th key={thVal + record.id}>{thVal}</th>))}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                record?.items && record.items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                Object.keys(TableStructureItems).map(tdKey => (
                                                    tdKey === 'index'
                                                        ? <td key={`number${tdKey}`}>{index + 1}</td>
                                                        : <td key={`number${tdKey}v`}>{item[tdKey]}</td>
                                                ))
                                            }
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                        <input value={record.id}
                               title='Select positions data from this json file'
                               onChange={onChangeSelectedPositionsId}
                               type={'checkbox'}
                               name={'positions_record_ids'}
                               style={{margin: '2px'}}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}
