import * as React from "react";
import {useEffect, useState} from "react";
import * as Constants from '../../../../constants'
import {useSelector} from "react-redux";
import BillDocumentTranslations from "../../../../translations/BillDocument";

export function ItemTypeSelect({stateChanger, setFocusedId, dataTableItem, getNextFocusableField, ...props}) {
    const {isReadOnly} = useSelector(state => state.damageBill)
    const [state, setState] = useState({
        value: props['value']
    });

    useEffect(() => {
        if (state.value !== props['value']) {
            setState({
                value: props['value']
            })
        }
    }, [props, state.value])

    function handleOnChange(e) {
        setFocusedId(getNextFocusableField(dataTableItem))
        setState({
            value: parseInt(e.target.value)
        });
        stateChanger(props['field'], parseInt(e.target.value));
    }

    return (
        <select data-table-item={`${dataTableItem[0]}${dataTableItem[1]}`}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        setFocusedId(getNextFocusableField(dataTableItem))
                    }
                }}
                name={props['name']} value={state.value}
                style={{
                    textTransform: 'capitalize',
                    padding: "5px 1px 5px 5px"
                }}
                onChange={handleOnChange}
                disabled={isReadOnly}
                {...props}>
            <option value={Constants.ITEM_TYPE_LABOR}>{BillDocumentTranslations.de.bill_item_type_labor}</option>
            <option value={Constants.ITEM_TYPE_SPARE_PART}>{BillDocumentTranslations.de.bill_item_type_part}</option>
            <option value={Constants.ITEM_TYPE_OTHER}>{BillDocumentTranslations.de.bill_item_type_other}</option>
        </select>
    );
}
