import * as React from "react";

export const ListRowCheck = React.forwardRef((props,ref) => {
    let index = props['index'];

    function handleEditItem(e) {
        props.stateChanger(props.item.id, e.target.checked);
    }

    return(
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index}>
            <td className={"hash"}><input type={"checkbox"} name={"permission_ids[]"} onChange={handleEditItem}
                defaultChecked={props.isSelected}/></td>
            <td>{props.item.name}</td>
            <td>{props.item.description}</td>
        </tr>
    );

});