// @flow
import * as React from 'react';
import SearchBar from "../../bills/List/SearchBar";
import {useMemo, useState} from "react";
import {BulkBillsButton} from "../../bills/form/BulkBillsButton";
import {UnselectBillsButton} from "../../bills/form/UnselectBillsButton";
import {Link} from "react-router-dom";
import Logout from "../../Logout";
import MultipleBillsUploader from "../../bills/upload/MultipleBillsUploader";
import BillsListItems from "./ListItems";
import "../../Main.css";
import {useSelector, useDispatch} from "react-redux";
import SendAllBillsToOCRCheckbox from "../../bills/List/SendAllBillsToOCRCheckbox";
import Button from "react-bootstrap/Button";
import AdvancedBillsSearch from "../../bills/List/AdvancedBillsSearch";
import collapseButton from "../../../assets/images/collapseButton.svg";
import {toggleSidebar} from "../../../features/SearchPagination/searchPagination.slice";
import {Switch} from '../../bills/switch/Switch'

export function BillsList() {
    const {billsIdsApprovedForOCR, totalItems, countFilteredBills, showSidebar} = useSelector(state => state.searchPagination)
    const bulkButtonsHolderClassName = useMemo(() => {
        return Object.entries(billsIdsApprovedForOCR).filter(([, v]) => v).length > 0 ? `shown-bulk-buttons-holder` : `hidden-bulk-buttons-holder`
    }, [billsIdsApprovedForOCR]);

    const [show, setShow] = useState(false);

    const onToggleSearch = () => setShow(prevShow => setShow(!prevShow));
    const dispatch = useDispatch();
    const styleButton = {
        background: 'transparent',
        border: "none",
        position: "absolute",
        top: '3%',
        left: !showSidebar ? "1.5%" : "312px",
        transform: !showSidebar ? "rotate(180deg)" : "rotate(0deg)"
    }
    const styleContainer = {
        overflow: !showSidebar ? "hidden" : 'unset',
        width: !showSidebar ? '69px' : 'unset',
        minWidth: !showSidebar ? '69px' : 'unset',
        display: 'flex'
    }
    const [chooseClosing, setChooseClosing] = useState(false);

    return (
        <div style={{...styleContainer}}>
            <button onClick={() => {
                dispatch(toggleSidebar())
            }} style={{...styleButton}}>
                <img src={collapseButton} width={32} height={32} alt={'collapseButton'}/></button>
            <div className={"sidebar"}
                 style={{
                     minWidth: !showSidebar ? '300px' : '300px',
                     width: !showSidebar ? 'inherit' : '350px',
                     pointerEvents: !showSidebar ? 'none' : 'unset'
                 }}>

                <div className={bulkButtonsHolderClassName}>
                    <BulkBillsButton/>
                    <UnselectBillsButton/>
                </div>
                <div style={{height: showSidebar ? 'unset' : '61px'}}>
                    {showSidebar && <><label
                        className={'label-rechnungen'}>Rechnungen({countFilteredBills === 0 ? totalItems : countFilteredBills})</label>
                    </>}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        fontSize: '12px',
                        paddingLeft: '15px',
                        alignItems: 'flex-start',
                        paddingTop: '2px'
                    }}>
                    <Switch
                        onColor="#fff"
                        isOn={chooseClosing}
                        handleToggle={() => setChooseClosing(!chooseClosing)}
                    />Do you want to close sidebar after choosing bill?
                </div>

                <div style={{
                    display: 'flex',
                    gap: '7px',
                    justifyContent: 'space-between',
                    padding: '0 12px 0',
                    textAlign: 'left',
                }}>
                    <Link to="/pay">Schäden zur Zahlung freigeben</Link>
                    <Link to="/sepa">Sepa Files</Link>
                </div>
                <SearchBar setShowAdvancedSearch={setShow}/>
                <Button style={{margin: '0px 15px 10px 15px'}} variant="primary" onClick={onToggleSearch}> Erweiterte
                    Suche</Button>
                <SendAllBillsToOCRCheckbox/>
                <div className={"Separator-Stroke"} style={{width: "calc(100% - 28px)", alignSelf: "center"}}/>
                <BillsListItems setShowAdvancedSearch={setShow} chooseClosing={chooseClosing}/>
                <MultipleBillsUploader/>
                <Logout/>
            </div>
            <AdvancedBillsSearch showSearch={show} setShow={setShow}/>
        </div>
    );
}


