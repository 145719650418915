import {Header} from "../../components/general/Header";
import {List} from "../../components/teams/teamList";

export function TeamsPage() {

    return (
        <div style={{background: '#e5ebf4'}} className={"users-container"}>
            <Header title={"Teams"}/>
            <List />
        </div>
    );
}
