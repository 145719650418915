// @flow

import {Input} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateCarDealerSource} from "../../../../features/DamageBill/bill.slice";
import BillDocumentTranslations from "../../../../translations/BillDocument";
import {capitalize} from "../../../../utils/trimIt";
import {usePopper} from "react-popper";
// const carDealerOptions = [
//     BillDocumentTranslations.de.payment_recpt_opt_contractor,
//     BillDocumentTranslations.de.payment_recpt_opt_partner,
//     BillDocumentTranslations.de.payment_recpt_opt_other
// ]
type Props = {
    errorMessage?: string,
};
export function SelectCarDealer({errorMessage: errorMessageProp}: Props) {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<string>(errorMessageProp);

    const {carDealerSource, isReadOnly, bill} = useSelector(state => state.damageBill)
    const damage = useSelector(state => state.damageBill.damage);
    const [validFieldClass, setValidFieldClass] = useState('');
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });

    useEffect(() => {
        setErrorMessage(errorMessageProp)
        return () => {
            setErrorMessage(undefined)
            if (update) update()
        }
    }, [errorMessageProp, update])
    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setErrorMessage(undefined)
        setValidFieldClass('')
        if (update) update()
    }, [bill, update])

    const updateCarDealer = (s: number) => {
        dispatch(updateCarDealerSource(s));
    }

    const buildByTypeHandler = (type: any) => {
        if (type == 0) {
            return capitalize(BillDocumentTranslations.de.payment_recpt_opt_contractor);
        }
        if (type == 2) {
            return capitalize(BillDocumentTranslations.de.payment_recpt_opt_other);
        }
        return capitalize(BillDocumentTranslations.de.payment_recpt_opt_del)
    }

    return (<div className={"Field-Input-Drop"} style={{
        flex: '1 1 200px'
    }}>
        <label className={"Input-label"} style={{margin: '0', textTransform: 'capitalize'}}>{BillDocumentTranslations.de.payment_recpt_label} *</label>
        <div className={"Frame-19"}>
            <div className={"Field-Filters"}  ref={inputRef}>
                <label className={"No-filters"}>
                    {
                        (damage?.hdnr === '690002' && damage?.mandnr === '47') &&
                        <>
                            <input className="Input-text form-control" type="text" value={buildByTypeHandler(carDealerSource)} disabled  style={{textAlign: 'left'}}/>
                            <input type="hidden" name="billed_by_type" value={carDealerSource} />
                        </>
                    }
                    {!(damage?.hdnr === '690002' && damage?.mandnr === '47') && <Input type="select"
                            name="billed_by_type"
                            id="billed_by_type"
                            className={validFieldClass + " Input-text"}
                            value={carDealerSource}
                            disabled={isReadOnly}
                            style={{height: "32px", textTransform: 'capitalize'}}
                            onChange={(e) => updateCarDealer(+e.target.value)}
                    >
                        <option value={-1}>{capitalize(BillDocumentTranslations.de.payment_recpt_opt_del)}</option>
                        <option value={0}
                                key={0}>{capitalize(BillDocumentTranslations.de.payment_recpt_opt_contractor)}</option>
                        <option value={2}
                                key={2}>{capitalize(BillDocumentTranslations.de.payment_recpt_opt_other)}</option>
                        {/*{carDealerOptions.map((item, index) => (<option key={index}*/}
                        {/*                                                value={index}>{capitalize(item)}</option>))}*/}
                    </Input>}

                </label>
                <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                     style={styles.popper} {...attributes.popper}>
                    {errorMessage && errorMessage.isEmpty}
                    <div className="arrow" data-popper-arrow style={styles.arrow} {...attributes.arrow}/>
                </div>
            </div>
        </div>


    </div>)
}
