// @flow
import * as React from 'react';
import {useDispatch} from "react-redux";
import {resetBillsApprovedForOCR} from "../../../features/SearchPagination/searchPagination.slice";

export function UnselectBillsButton() {
    const dispatch = useDispatch();
    return (
        <button
            className={"mainButton btn btn-secondary"}
            style={{float: "left"}}
            onClick={() => dispatch(resetBillsApprovedForOCR())}
        >
            <label className="label" style={{height: "13px"}}>Cancel</label>
        </button>
    );
}
