import {AxiosResponse} from "axios";
import {client, headers} from "../../app/axiosClient";
import {authUrl, countriesUrl} from "../ApiService";
import {getUsers} from "./users.service";

export function getTeams(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${authUrl}/teams${queryString || ''}`, {
        headers: {
            ...headers
        }, params: {
            page: pageNumber
        }, cancelToken
    })
}

export function getTeamMembers(id: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${authUrl}/teams/${id}/members`, {
        headers: {
            ...headers
        }, params: {
            page: pageNumber
        }, cancelToken
    })
}

export function createTeamMember(id: string, user_id: number, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.post(`${authUrl}/teams/${id}/members`, JSON.stringify(user_id), {
        headers: {
            ...headers, 'Content-type': 'application/json'
        }, cancelToken: cancelTokenParam
    });
}

export function deleteTeamMember(id: string, member_id: string, cancelToken?: any): Promise<AxiosResponse> {
    return client.delete(`${authUrl}/teams/${id}/members/${member_id}`, {
        headers: {
            ...headers
        }, cancelToken
    })
}

export function teamCandidates(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${authUrl}/excludeteammembers?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${queryString || ''}`, {
        headers: {
            ...headers
        }, params: {
            page: pageNumber
        }, cancelToken
    })
}

export function getCountries(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${countriesUrl}?languageCode=de&filters[0][property]=country_name&filters[0][operator]=l&filters[0][value]=${queryString}`, {
        headers: {
            ...headers
        }, params: {
            page: pageNumber
        }, cancelToken
    })
}

export function getAllCountries(cancelToken?: any): Promise<AxiosResponse> {
    return client.get(`${countriesUrl}?languageCode=de`, {
        headers: {
            ...headers
        }, cancelToken
    })
}

export function updateUserCountry(user_id, id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.put(`${authUrl}/${user_id}/workingcountry/${id}`, '', {
        headers: {
            ...headers, 'Content-type': 'application/json'
        }, cancelToken: cancelTokenParam
    });
}

export function createTeam(team, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.post(`${authUrl}/teams`, JSON.stringify(team), {
        headers: {
            ...headers, 'Content-type': 'application/json'
        }, cancelToken: cancelTokenParam
    });
}

export function updateTeam(team, id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.put(`${authUrl}/teams/${id}`, JSON.stringify(team), {
        headers: {
            ...headers, 'Content-type': 'application/json'
        }, cancelToken: cancelTokenParam
    });
}

export function deleteTeam(id, cancelTokenParam?: any): Promise<AxiosResponse> {
    return client.delete(`${authUrl}/teams/${id}`, {
        headers: {
            ...headers
        }, cancelToken: cancelTokenParam
    });
}

export function searchTeamLead(queryString: string, pageNumber: number, cancelToken?: any): Promise<AxiosResponse> {
    return getUsers(`?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${queryString}`, pageNumber, cancelToken)
}
