import {Header} from "../../components/general/Header";
import {List} from "../../components/teams/members/memberList";

export function TeamMembersPage() {

    return (
        <div style={{background: '#e5ebf4'}} className={"users-container"}>
            <Header title={"Team members"}/>
            <List/>
        </div>
    );
}
