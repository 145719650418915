// @flow
import {useCallback} from 'react';
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {includeTax, updateTax} from "../../../features/DamageBill/bill.slice";

export function FirstVat() {
    const {isReadOnly, billTax} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();

    function handleChange(e) {
        const {value: v} = e.target
        if (isValid(v)) {
            dispatch(updateTax(v));
            if (v === '') dispatch(includeTax(false));
            else dispatch(includeTax(true));
        }
    }

    function formatValue(e) {
        if (e.target.value.trim() === '') {
            dispatch(updateTax(''));
            return;
        }
        dispatch(updateTax(parseFloat(e.target.value).toFixed(2)));
    }

    const isValid = useCallback((v) => {
        const r = new RegExp('^\\d{0,3}(\[\.\,]\\d{0,2})?$')
        if (v === '') return true;
        if (isNaN(parseFloat(v))) return r.test(v);
        return r.test(v) && parseFloat(v) <= 100
    }, [])

    return (
        <div className={"Field-Input-Drop"} style={{flex: '1 1 70px', maxWidth: '76px'}}>
            <Form.Group>
                <Form.Label className={"Input-label"}>MwSt. in %</Form.Label>
                <div className={"Field-Input-Text"}>
                    <Form.Control className={"Input-text"} name="tax" id="tax" type="number" value={billTax || ''}
                                  step={'0.01'}
                                  onChange={handleChange}
                                  onBlur={formatValue}
                                  disabled={isReadOnly}
                    />
                </div>
            </Form.Group>
        </div>
    );
}
