import {useHistory, useRouteMatch} from "react-router-dom";
import {useCallback} from "react";
import {BILL_PROCESSING_STATUS_LABELS, BILL_PROCESSING_STATUSES} from "../../StatusContext";
import SendBillDocumentToOCRCheckbox from "../../bills/List/SendBillDocumentToOCRCheckbox";
import dateFormat from "dateformat";
import './list_item.css'
import * as React from "react";
import {checkLoggedUser} from "../../../features/Authentication/authSlice";
import {useDispatch} from "react-redux";
import {toggleSidebar} from "../../../features/SearchPagination/searchPagination.slice";

export const SameDamageCount = ({count, style, ...props}) => {
    if (count === null || typeof count === 'undefined' || count <= 1) {
        return null;
    }

    return (
        <span {...props} style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#007BFF',
            borderRadius: '50%',
            fontWeight: '600',
            flex: '0 0 20px',
            width: '20px',
            height: '20px',
            fontSize: '12px',
            lineHeight: '1',
            color: '#fff',
            ...style
        }}>{count}</span>
    );
};

function BillListItem({item, hideAdvancedSearch, chooseClosing}) {
    let {path} = useRouteMatch();
    const dispatch = useDispatch();
    let history = useHistory();
    const redirectToBill = useCallback(async () => {
        await dispatch(checkLoggedUser());
        hideAdvancedSearch();
        history.push(`${path}/${item.damage_bill_id}`);
    }, [item.damage_bill_id, history, path, hideAdvancedSearch, dispatch])

    return (
        <>
            <div style={{width: '30px', height: '100%', display: 'flex', justifyContent: 'center'}}>
                {item.processing_status === BILL_PROCESSING_STATUSES.DOCUMENT_CREATED &&
                <SendBillDocumentToOCRCheckbox item={item}/>}
            </div>
            <div onClick={()=>{redirectToBill(); if(chooseClosing){ dispatch(toggleSidebar());}}} className={'list_item_container_label_container'}>

                <div className={'list_item_label_row'}>
                    <p style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        fontWeight: '600',
                        lineHeight: '1'
                    }}>
                        {"Bill " + (item.damage_bill_id < 10 ? ("0" + item.damage_bill_id) : item.damage_bill_id)}

                        <SameDamageCount count={item.damage_bill.count_of_bills_linked_to_same_damage}/>
                    </p>

                    <div className={'list_item_label_row'} style={{
                        gap: 5
                    }}>

                        <div className={"Ellipse Ellipse-" + item.processing_status}
                             title={BILL_PROCESSING_STATUS_LABELS[`${item.processing_status}`]}
                             style={{float: "left"}}/>


                        <p className={`list_item_label_status status-${item.processing_status}`}>{BILL_PROCESSING_STATUS_LABELS[item.processing_status]}</p>
                    </div>

                </div>

                {

                    (item.bill_number || item.bill_date) && (
                        <div className={'list_item_label_row'}>
                            <p className={'list_item_label_date'}>
                                {item.bill_number}
                            </p>
                            <p className='list_item_label_date'>{
                                (item.bill_date && dateFormat(item.bill_date.date, "dd.mm.yyyy").length > 0 ? (dateFormat(item.bill_date.date, "dd.mm.yyyy")) : (""))
                            }</p>
                        </div>
                    )
                }


            </div>
        </>
    )
}

export default BillListItem;
