// @flow
import React from 'react'
import {Switch, Route, Redirect, useRouteMatch} from "react-router-dom";
import RequestInstructions from "./RequestInstructions";
import SetNewPassword from "./SetNewPassword";

const ResetPasswordPage = () => {
    let {path} = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${path}/request`} component={RequestInstructions}/>
                <Route path={`${path}/new`} component={SetNewPassword}/>
                <Route exact path={`${path}`}>
                    <Redirect to={`${path}/request`}/>
                </Route>
            </Switch>
    </>
    )
}


export default ResetPasswordPage