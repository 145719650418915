import {BsPencilSquare, BsLockFill} from "react-icons/bs";
import * as React from "react";

export const ListRow = React.forwardRef(({index, item, onEdit,  onEditPermissions}, ref) => {

    return(
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index}>
            <td className={"hash"}>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td className={"Last-Table-Cell"}>
                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                    onClick={onEditPermissions} >
                    <BsLockFill
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                </button>
                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                    onClick={onEdit}>
                    <BsPencilSquare
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                </button>
            </td>
        </tr>
    );
})