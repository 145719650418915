// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Input} from "reactstrap";
import {useSelector} from "react-redux";
import BillDocumentTranslations from "../../../translations/BillDocument";
import {GeneralTranslations} from "../../../translations/General/GeneralTranslations";
import {capitalize} from "../../../utils/trimIt";


const options = [
    {value: 1, label: BillDocumentTranslations.de.contact_from_contractor},
    {value: 2, label: BillDocumentTranslations.de.contact_from_policy},
    {value: 3, label: BillDocumentTranslations.de.contact_from_workshop},
    {value: 4, label: BillDocumentTranslations.de.contact_from_brk_down},
    {value: 5, label: BillDocumentTranslations.de.contact_from_expert},
    {value: 6, label: BillDocumentTranslations.de.contact_from_overhauler},
    {value: 7, label: BillDocumentTranslations.de.contact_from_lawyer},
];

export function ContactFrom() {
    const {bill, isReadOnly} = useSelector(state => state.damageBill)
    const [selectedFrom, setSelectedFrom] = useState<number>(bill?.bill_contact_from || null)

    useEffect(() => {
        setSelectedFrom(bill?.bill_contact_from || null)
    }, [bill?.bill_contact_from])

    return (
        <div className={"Field-Input-Drop"} style={{flex: '1 1 170px'}}>
            <label className={" Input-label"} style={{marginBottom: 0}}>Schadenmeldung von</label>
            <div className={"Frame-19"}>
                <div className={"Field-Filters"}>
                    <label className={"No-filters"}>
                        <Input type="select"
                               name="bill_contact_from"
                               id="bill_contact_from"
                               value={options.find(b => b.value === selectedFrom) ? selectedFrom : -1}
                               disabled={isReadOnly}
                               style={{height: "32px", textTransform: 'capitalize'}}
                               onChange={(e) => setSelectedFrom(parseInt(e.target.value))}
                        >
                            <option value={-1}
                                    disabled>{capitalize(GeneralTranslations.de.label_please)} {GeneralTranslations.de.label_select}</option>
                            {
                                options.map((item) => {
                                    if (item.value === bill?.bill_contact_from) {
                                        return (
                                            <option value={item.value}
                                                    key={item.value}>{capitalize(item.label)}</option>
                                        )
                                    } else {
                                        return (
                                            <option value={item.value}
                                                    key={item.value}>{capitalize(item.label)}</option>
                                        )
                                    }
                                })
                            }
                        </Input>
                    </label>
                </div>
            </div>
        </div>
    );
}
