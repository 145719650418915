// @flow
import React, {useState} from "react";
import {Button} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {useDispatch, useSelector} from "react-redux";
import {markBillAuthorizedForPayment, updateBill} from "../../features/DamageBill/bill.actions";
import {GeneralTranslations} from "../../translations/General/GeneralTranslations";
import BillDocumentTranslations from "../../translations/BillDocument";
import {checkLoggedUser} from "../../features/Authentication/authSlice";
import {convertForeignToDomesticCurrency} from "../../utils/damageItemsCalculations";

export function AuthorizeBillButton({formRef}) {
    const {bill, damage, openModal, currency, maxPaymentLimit, paymentTotal} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState<boolean>(openModal);
    const [isMarked, setMarked] = useState<boolean>(false);
    const closeAuthorizationModal = () => setOpen(false);

    const openAuthorizationModal = () => setOpen(true);
    const presaveBill = () => {
        if (formRef && formRef.current) {
            dispatch(
                updateBill(
                    {
                        billId: bill.damage_bill_id,
                        data: (() => {
                            const data = new FormData(formRef.current);
                            const l = {}
                            for (const pair of data.entries()) {
                                l[pair[0]] = pair[1];
                            }
                            return l;
                        })()
                    }
                )
            )
        }
    };
    const saveAndOpenModal = async () => {
        await dispatch(checkLoggedUser());
        if (bill.processing_status === 5) {
            presaveBill();
        }
        const paymentRecipient = formRef.current.querySelector('[name="billed_by_type"]');
        const autohouse = formRef.current.querySelector('[name="billed_by_car_dealer_id"]');
        const iban = formRef.current.querySelector('[name="shop_bank_account_id"]');
        const billNumber = formRef.current.querySelector('[name="bill_number"]');
        const date = formRef.current.querySelector('[name="bill_date"]');
        const contactReason = formRef.current.querySelector('[name="bill_contact_reason"]');
        const km = formRef.current.querySelector('[name="vehicle_mileage_after_reparation"]');

        if (paymentRecipient.value.trim() !== '' && autohouse.value.trim() !== '' && iban.value.trim() !== '' && billNumber.value.trim() !== '' && date.value.trim() !== '' && contactReason.value.trim() && km.value.trim() !== '') {
            openAuthorizationModal();
        }

    }

    const authorize = async () => {
        await dispatch(checkLoggedUser());
        dispatch(markBillAuthorizedForPayment({
            billId: bill.damage_bill_id,
            isMarked
        }));
        closeAuthorizationModal();
    }

    return (
        <>
            <Modal className={''} show={isOpen} onHide={closeAuthorizationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{BillDocumentTranslations.de.auth_bill_modal_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            value={isMarked}
                            onChange={(e) => setMarked(e.target.checked)}
                            type={'checkbox'}
                            id={`default-checkbox`}
                            label={BillDocumentTranslations.de.auth_bill_modal_mark_closed}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"btn-success"}
                            style={{alignSelf: "flex-end", width: 'auto'}}
                            onClick={authorize}>
                        {BillDocumentTranslations.de.auth_bill_modal_button}
                    </Button>
                </Modal.Footer>
            </Modal>

            {maxPaymentLimit >= convertForeignToDomesticCurrency(paymentTotal, currency?.rate) &&
                <Button className={"btn-success"}
                        style={{
                            alignSelf: "flex-end",
                            width: 'auto',
                            textTransform: 'capitalize',
                            display: damage && (damage.release_status === 0 || damage.release_status === 3) ? 'inline-block' : 'none'
                        }}
                        onClick={saveAndOpenModal}>
                    {GeneralTranslations.de.label_authorize}
                </Button>}
        </>
    );
}

