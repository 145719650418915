//@flow
import {createAction, createSlice} from "@reduxjs/toolkit";
import type {Bill} from "../../components/BillContext";
import {BILL_PROCESSING_STATUSES} from "../../components/StatusContext";

export interface AdvancedBillSearchParams {
    company_name: string;
    postcode: string;
    partner_number: number;
    city: string;
    IBAN: string;
    chassis_number: number;
    registration_number: number;
    customer_name: string;
    contract_number: number;
    processing_status: number;
    email_address: string;
}

export interface SearchPaginationState {
    keyword: string;
    statuses: [number];
    pageNumber: number;
    billsPaginated: Bill[];
    hasError: boolean;
    hasMore: boolean;
    loading: false;
    billsIdsApprovedForOCR: number[];
    all_selected: boolean;
    sortedColumn: string;
    sortOrder: 'asc' | 'desc';
    bills_versions_uncheck: number[];
    select_all_button: boolean;
    totalItems: string | number;
    countFilteredBills: string | number;
    advanceSearchParams: AdvancedBillSearchParams;
    showSidebar: boolean;
}

export const initialState: SearchPaginationState = {
    keyword: '',
    statuses: [],
    pageNumber: 1,
    billsPaginated: [],
    hasMore: false,
    hasError: false,
    loading: false,
    billsIdsApprovedForOCR: {},
    all_selected: false,
    sortedColumn: 'date_created',
    sortOrder: 'desc',
    bills_versions_uncheck: [],
    select_all_button: false,
    totalItems: '',
    countFilteredBills: 0,
    advanceSearchParams: null,
    showSidebar: true
}

/*
    For fetching paginated bills
 */
export const fetchBills = createAction('searchPagination/fetchBills');
export const fetchBillsFullFilled = createAction('searchPagination/fetchBills/fulfilled');
export const fetchBillsFailed = createAction('searchPagination/fetchBills/failed');

export const advanceFetchBills = createAction('searchPagination/advanceFetchBills');
export const advanceFetchBillsFullFilled = createAction('searchPagination/advanceFetchBills/fulfilled');
export const advanceFetchBillsFailed = createAction('searchPagination/advanceFetchBills/failed');

/*
    For requesting next page
 */
export const nextPage = createAction('searchPagination/pageUpdated')

/*
    For requesting bills with updated query
 */
export const updateFilters = createAction('searchPagination/filtersUpdated');


export const toggleSortOrder = createAction('searchPagination/toggleSortOrder');


const searchPaginationSlice = createSlice({
    name: 'searchPagination',
    initialState,
    reducers: {
        resetBillsApprovedForOCR(state: SearchPaginationState) {
            state.billsIdsApprovedForOCR = {}
            state.bills_versions_uncheck = []
        },
        markAsSentToOCS(state: SearchPaginationState, {payload}) {
            state.billsPaginated = state.billsPaginated.map<Bill>((bill: Bill) =>
                payload.includes(bill.id.toString()) ? {
                    ...bill,
                    processing_status: BILL_PROCESSING_STATUSES.DOCUMENT_SENT_TO_OCR
                } : bill)

            state.billsIdsApprovedForOCR = {}
            state.bills_versions_uncheck = []
            state.all_selected = false;

        },
        toggleBillForOCR(state: SearchPaginationState, {payload}) {
            state.billsIdsApprovedForOCR[payload] = !state.billsIdsApprovedForOCR[payload]
        },
        toggleAllBillsForOCR(state: SearchPaginationState, {payload}) {
            state.billsIdsApprovedForOCR = payload;
            state.all_selected = !state.all_selected;
            state.bills_versions_uncheck = []
        },
        updateArrayUncheck(state: SearchPaginationState, {payload}) {
            state.bills_versions_uncheck = payload;
        },
        updateStatusOfPaginatedPill(state: SearchPaginationState, {payload}) {
            if (state.statuses.includes(payload.status)) {
                state.billsPaginated = state.billsPaginated.map((bill) => {
                    if (bill.id !== payload.id) return bill;
                    return {
                        ...bill,
                        processing_status: payload.status
                    }
                })
            } else if (state.statuses.length > 0) {
                state.billsPaginated = state.billsPaginated.filter((bill) => (bill.id !== payload.id))
            }

        },
        toggleSelectAllButton(state: SearchPaginationState, {payload}) {
            state.select_all_button = payload;
        },
        saveCountedFilteredBills(state: SearchPaginationState, {payload}) {
            state.countFilteredBills = payload;
        },
        toggleSidebar(state: SearchPaginationState){
            state.showSidebar=!state.showSidebar;
        },
    },

    extraReducers: {
        [fetchBills]: (state) => {
            state.hasMore = false;
        },
        [fetchBillsFullFilled]: (state, action) => {
            state.billsPaginated = [
                ...state.billsPaginated,
                ...action.payload.billsPaginated
            ];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.totalItems = action.payload.totalItems;
        },
        [fetchBillsFailed]: (state) => {
            state.hasError = true;
            state.loading = false;
            state.billsPaginated = [];
        },
        [advanceFetchBills]: (state) => {
            state.hasMore = false;
            state.keyword = '';
            state.statuses = [];
        },
        [advanceFetchBillsFullFilled]: (state, action) => {
            state.billsPaginated = [
                ...action.payload.billsPaginated
            ];
            state.hasMore = action.payload.hasMore;
            state.loading = false;
            state.totalItems = action.payload.totalItems;
        },
        [advanceFetchBillsFailed]: (state) => {
            state.hasError = true;
            state.loading = false;
            state.billsPaginated = [];
        },
        [updateFilters]: (state, {payload}) => {
            if (typeof payload.keyword !== "undefined") state.keyword = payload.keyword;
            if (typeof payload.statuses !== "undefined") state.statuses = payload.statuses;
            // if (typeof payload.sortOrder !== "undefined") state.sortOrder = payload.sortOrder;
            state.billsPaginated = []
            state.loading = true;
            state.pageNumber = 1;
        },
        [toggleSortOrder]: (state) => {
            state.billsPaginated = []
            state.loading = true;
            state.pageNumber = 1;
            state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc';
        },
        // [updateKeyword]: (state, action) => {
        //     state.billsPaginated = []
        //     state.loading = true;
        //     state.pageNumber = 1;
        //     state.keyword = action.payload
        // },
        // [updateStatuses]: (state, action) => {
        //     state.billsPaginated = []
        //     state.loading = true;
        //     state.pageNumber = 1;
        //     state.statuses = action.payload
        // },
        [nextPage]: (state, action) => {
            state.pageNumber += (action.payload || 1)
        }
    }
})

const {reducer} = searchPaginationSlice;
export default reducer;

export const {
    updateStatusOfPaginatedPill,
    markAsSentToOCS,
    toggleBillForOCR,
    toggleAllBillsForOCR,
    resetBillsApprovedForOCR,
    updateArrayUncheck,
    toggleSelectAllButton,
    saveCountedFilteredBills,
    toggleSidebar
} = searchPaginationSlice.actions;
