// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Input} from "reactstrap";
import useApi from "../../../hooks/useApi";
import {useSelector} from "react-redux";
import {
    getCarDealerBankAccounts,
    getExternalProviderAccounts,
} from "../../../services/API/carDealer.service";
import {trimIt} from "../../../utils/trimIt";
import BillDocumentTranslations from "../../../translations/BillDocument";
import {usePopper} from "react-popper";
type Props ={
    errorMessageProp: string;
}
export function AccountNumber({errorMessage: errorMessageProp}:Props) {
    const {bill, shopAutoCompleteId, isReadOnly, carDealerSource} = useSelector(state => state.damageBill)
    const [bankAccounts, setBankAccounts] = useState([]);
    const [selectedBankAccount, setSelectedBankAccount] = useState<number>(bill?.shop_bank_account_id || null)
    const {
        data,
        request,
    } = useApi(carDealerSource === 2 ? getExternalProviderAccounts : getCarDealerBankAccounts);
    const [errorMessage, setErrorMessage] = useState<string>(errorMessageProp);
    const [validFieldClass, setValidFieldClass] = useState('');
    const inputRef = useRef(null);
    const tooltipRef = useRef(null);
    const {styles, attributes, update} = usePopper(inputRef.current, tooltipRef.current, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
            {
                name: 'arrow',
            },
        ],
    });

    useEffect(() => {
        setErrorMessage(errorMessageProp)
        return () => {
            setErrorMessage(undefined)
            if (update) update()
        }
    }, [errorMessageProp, update])
    /**
     * each time we update bill remove validation errors
     */
    useEffect(() => {
        setErrorMessage(undefined)
        setValidFieldClass('')
        if (update) update()
    }, [bill, update])

    useEffect(() => {
        if (!data || !data?._embedded) {
            setBankAccounts([])
            return;
        }
        setBankAccounts(data?._embedded.records)
    }, [data])

    useEffect(() => {
        setSelectedBankAccount(bill?.shop_bank_account_id || null);
    }, [bill?.shop_bank_account_id, shopAutoCompleteId])

    useEffect(() => {
        if (!shopAutoCompleteId) {
            setBankAccounts([])
            setSelectedBankAccount(bill?.shop_bank_account_id || null)
            return;
        }
        request({shopAutoCompleteId});
    }, [shopAutoCompleteId, bill?.shop_bank_account_id, request])


    return (
        <div className={"Field-Input-Drop"} style={{
            flex: '1 1 317px'
        }}>
            <label className={" Input-label"} style={{margin: '0'}}>IBAN - BIC {bill?.does_car_dealer_use_rg_funds===true ? '*' : ''}</label>
            <div className={"Frame-19"}>
                <div className={"Field-Filters"} ref={inputRef}>
                    <label className={"No-filters"}>
                        <Input type="select"
                               name="shop_bank_account_id"
                               id="shop_bank_account_id"
                               className={validFieldClass + " Input-text"}
                               value={bankAccounts.find(b => b.id === selectedBankAccount) ? selectedBankAccount : -1}
                               disabled={isReadOnly}
                               style={{height: "32px"}}
                               onChange={(e) => setSelectedBankAccount(parseInt(e.target.value))}
                        >
                            {bill?.does_car_dealer_use_rg_funds===false ?  <option value={-1} >{BillDocumentTranslations.de.iban_default_value}</option> :  '' }

                            {
                                bankAccounts.map(item => {
                                    if (item.id === bill?.shop_bank_account_id) {
                                        return (
                                            <option key={item.id}
                                                    value={item.id}>{`${trimIt(item.iban)} - ${trimIt(item.bic)}`}</option>
                                        )
                                    }
                                    return (
                                        <option key={item.id}
                                                value={item.id}>{`${trimIt(item.iban)} - ${trimIt(item.bic)}`}</option>
                                    )
                                })
                            }
                        </Input>

                    </label>
                    <div className={`popper-tooltip ${!errorMessage && 'popper-tooltip-hidden'}`} ref={tooltipRef}
                         style={styles.popper} {...attributes.popper}>
                        {errorMessage && errorMessage.isEmpty}
                        <div className="arrow" data-popper-arrow style={styles.arrow} {...attributes.arrow}/>
                    </div>
                </div>
            </div>


        </div>


    );
}
