// @flow
import React, {useRef, useState} from 'react'
import toast, {Toaster} from "react-hot-toast";
import rgisLogo from "../../assets/images/RealGrantLogo.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {inputErrorStyle} from "../Login";
import {Link} from "react-router-dom";
import {requestForgottenPasswordEmail} from "../../services/API/auth.service";

const RequestInstructions = () => {
    const [email, setEmail] = useState('');
    const [failed, setFailed] = useState<boolean>(false);
    const toastIdRef = useRef<string>(1)


    const cleanErrors = () => {
        setFailed(false)
        toast.dismiss(toastIdRef.current)
    }


    const onLoginSubmit = async (e: Event) => {
        e.preventDefault();

        if (email.trim() === '') {
            setFailed(true);
            toast.error('Email address is incorrect')
            return;
        }


        await toast.promise(requestForgottenPasswordEmail(email), {
            loading: 'Requesting ...', success: (res) => {
                return res.data.message || 'Please check your email for instructions'
            }, error: () => {
                return `We couldn't sent an email to you`
            }
        }, {
            style: {
                minWidth: '600px',
            },
            success: {
                duration: 5000,
            },
        })
    }

    return (<>
        <Toaster position={'bottom-center'}/>
        <main style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left',
        }}>
            <div style={{
                width: '100%', padding: '1rem', maxWidth: '400px', display: "flex", flexDirection: 'column'
            }}>

                <img style={{
                    width: '100%', marginBottom: '1.5rem'
                }} alt={'Real Garant logo'} src={rgisLogo}/>
                <Form style={{
                    display: 'flex', flexDirection: 'column', gap: '.3rem'
                }} onSubmit={onLoginSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="email" style={failed ? inputErrorStyle : {}} value={email}
                                      onChange={(e) => {
                                          cleanErrors()
                                          setEmail(e.target.value)
                                      }}/>
                    </Form.Group>
                    <Link to={'/login'}>Back to login</Link>
                    <Button variant="primary" style={{
                        width: '100%'
                    }} type="submit">
                        Send Reset Instructions
                    </Button>
                </Form>
            </div>
        </main>
    </>)
}


export default RequestInstructions