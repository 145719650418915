// @flow
import {Observable} from "rxjs";
import axios from "axios";
import {AxiosResponse} from "axios";

/**
 * Creates observable from an Axios Promise
 * which can als be canceled
 * @param func
 * @param argsArr
 * @returns {Observable<unknown>}
 */
export function fromAxios(func, argsArr) {
    return new Observable<AxiosResponse>(subscriber => {
        // generate new cancel source
        const source = axios.CancelToken.source();
        func(...argsArr, source.token)
            .then(response => {

                // push response to subscribers
                subscriber.next(response);

                // mark as complete
                subscriber.complete();
            })
            .catch(error => {
                subscriber.error(error);
            });
        return () => {
            // cancel request on
            source.cancel()
        };
    });
}
