/**
 * Redux Store setup file
 * Configuring Middleware, and reducers
 */
import {combineReducers, configureStore} from '@reduxjs/toolkit'
import authReducer, {logout} from '../features/Authentication/authSlice'
import {createEpicMiddleware} from 'redux-observable';
import rootEpic from "./rootEpic";
import searchPaginationReducer, {initialState as sPInit} from "../features/SearchPagination/searchPagination.slice";
import billSliceReducer, {initialState as dBInit} from "../features/DamageBill/bill.slice";


/**
 * Provided by redux-observable
 * @type {EpicMiddleware<Action, Action, void, any>}
 */
const epicMiddleware = createEpicMiddleware();


/**
 * Combine all reducers from our slices to a single reducer
 * @type {Reducer<CombinedState<unknown>>}
 */
const combinedReducer = combineReducers({
    auth: authReducer,
    searchPagination: searchPaginationReducer,
    damageBill: billSliceReducer
});


/**
 * Overriding the `combinedReducer` created above
 * We need to override how state behaves
 * @param state
 * @param action
 * @returns {CombinedState<*>}
 */
const rootReducer = (state, action) => {
    /**
     * On auth logout reset everything except the auth state
     * and allow the other reducers to run as they should
     */
    if (action.type === logout.fulfilled.type) {
        const {auth} = state
        state = {
            auth,
            searchPagination: sPInit,
            damageBill: dBInit
        }
    }
    return combinedReducer(state, action);
};



const store = configureStore({
    reducer: rootReducer,

    middleware:
        // Keep default middleware (redux-thunk) and append redux-observable
        (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),

    // TODO: make this available only on development mode
    devTools: true
});

epicMiddleware.run(rootEpic);

export default store;