import React, {useCallback, useContext, useState} from "react";
import {Button} from "react-bootstrap"
import {BillFormContext} from "../BillForm";
import {updateBillManualSelection} from "../../../../features/DamageBill/bill.actions";
import {useDispatch, useSelector} from "react-redux";

/**
 * This button is used for triggering a 'Put' request and update the files user
 * has selected when bill has 'Manual Setup' status
 * @return {JSX.Element}
 *
 */
export function ManualSetupSubmitButton() {

    const {
        manualHeaderId,
        manualPositionsId,
    } = useContext(BillFormContext);
    const {bill} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');


    const onClickBtn = useCallback(async () => {
        // Do not execute if these do no exist
        if (!manualHeaderId || manualPositionsId.length === 0) {setErrorMessage('Error'); return;}
        dispatch(
            updateBillManualSelection(
                {
                    billId: bill?.damage_bill_id,
                    data: {
                        headers_record_id: manualHeaderId,
                        positions_record_ids: manualPositionsId
                    }
                }
            )
        )
        setErrorMessage('');
    }, [dispatch, manualHeaderId, manualPositionsId, bill]);

    return (
        <div>
        <Button className={"mainButton"}
                style={{alignSelf: "flex-end", width: 'auto'}}
                onClick={onClickBtn}>
            Submit selection
        </Button>
        <p style={{color: 'red'}}>{errorMessage}</p>
        </div>
    )

}
