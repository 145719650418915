import * as React from 'react';
import {useEffect} from 'react';
import {BillForm} from "./form/BillForm";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {fetchDamageBill} from "../../features/DamageBill/bill.actions";

export function DamageBill() {
    const dispatch = useDispatch();
    let {billId: billIdParam} = useParams();

    useEffect(() => {
        dispatch(fetchDamageBill(billIdParam))
    }, [dispatch, billIdParam])


    return <BillForm/>;
}
