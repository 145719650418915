import {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import * as React from "react";
import "./Users.css";
import Select from "react-select";
import {useCountries} from "../../hooks/useCountries";
import {qc} from "../../pages/UsersPage";

export function UserForm(props) {
    const [userState, setUserState] = useState(props.userData);
    const {data, request} = useCountries();

    const options = data ? (data._embedded.records.map(({
                                                            country_name, country_id
                                                        }) => ({
        label: country_name, value: country_id
    }))) : []

    const df = options.filter(({value}) => props.userData.working_countries.includes(value))


    useEffect(() => {
        request({locale: 'en'})
    }, [])


    const userFormRef = useRef(null);

    function onUserFormSubmit(e) {
        e.preventDefault();
        const data = new FormData(userFormRef.current);
        const user = {
            id: data.get('id'),
            username: userState.old_username,
            // password: data.get('password'),
            first_name: !!data.get('first_name') ? data.get('first_name') : ' ',
            last_name: !!data.get('last_name') ? data.get('last_name') : ' ',
            email: !!data.get('email') ? data.get('email') : ' ',
            address: !!data.get('address') ? data.get('address') : ' ',
            mobile: !!data.get('mobile') ? data.get('mobile') : ' ',
            phone: !!data.get('phone') ? data.get('phone') : ' ',
            max_payment_limit: data.get('max_payment_limit') === '0' ? '0' : +data.get('max_payment_limit') || ' ',
            country_ids: !!userState.working_countries ? userState.working_countries.join(',') : ' '
        }
        props.formSubmit(user);
        setTimeout(async () => {
            await qc.invalidateQueries('users');
        }, '1500');

    }

    function updateItem(event) {
        let tmpItem = userState;
        tmpItem[event.target.name] = event.target.value;
        setUserState({...tmpItem});
    }

    return (
        <Form id="userForm" ref={userFormRef} onSubmit={onUserFormSubmit}>
            <input type={"hidden"} name={"id"} value={userState.id}/>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Username: <input type={"text"} className={"form-control"} name={"username"}
                                         disabled={true} value={userState.old_username}/>
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Email: <input type={"text"} className={"form-control"} name={"email"}
                                      onChange={updateItem} value={userState.email}/>
                    </div>
                </div>
                {/*<div className={"col-md-6"}>*/}
                {/*    <div className={"form-group"}>*/}
                {/*        Password: <input type={"password"} className={"form-control"} name={"password"}*/}
                {/*                         onChange={updateItem} value={userState.password}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Country:
                        {options.length ? (<Select
                            defaultValue={df}
                            onChange={(selectedItems) => {
                                let ids = selectedItems.map(({value}) => value);
                                setUserState((prev) => ({
                                    ...prev,
                                    working_countries: ids
                                }));
                            }}
                            closeMenuOnSelect={false}
                            isMulti
                            options={options}
                        />) : null}
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Payment Limit: <input type={"number"} min={0} max={999999} className={"form-control"}
                                              name={"max_payment_limit"}
                                              onChange={updateItem} value={userState.max_payment_limit}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        First name: <input type={"text"} className={"form-control"} name={"first_name"}
                                           onChange={updateItem} value={userState.first_name}/>
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Last name: <input type={"text"} className={"form-control"} name={"last_name"}
                                          onChange={updateItem} value={userState.last_name}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Mobile: <input type={"text"} className={"form-control"} name={"mobile"}
                                       onChange={updateItem} value={userState.mobile}/>
                    </div>
                </div>
                <div className={"col-md-6"}>
                    <div className={"form-group"}>
                        Phone: <input type={"text"} className={"form-control"} name={"phone"}
                                      onChange={updateItem} value={userState.phone}/>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div className={"form-group"}>
                        Address: <input type={"text"} className={"form-control"} name={"address"}
                                        onChange={updateItem} value={userState.address}/>
                    </div>
                </div>
            </div>


            <button className={"btn btn-primary form-buttons"}>Save</button>
            <button type={"button"} onClick={props.toggleModal} className={"btn btn-danger form-buttons"}>Cancel
            </button>

        </Form>

    );
}
