import React, {useEffect} from 'react';
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import {
    updateFilters,
    toggleSortOrder, updateStatusOfPaginatedPill, saveCountedFilteredBills
} from "../../../features/SearchPagination/searchPagination.slice";
import Button from "react-bootstrap/Button";
import {BsSortDown, BsSortUp} from "react-icons/bs";
import useApi from "../../../hooks/useApi";
import {getBillFilters} from "../../../services/API/damageBill.service";


export const colourStyles = {
    control: styles => ({...styles, backgroundColor: 'white'}),
    valueContainer: styles => ( {...styles, padding: '4px 8px'}),
    indicatorSeparator: styles => ( {...styles, marginTop: '10px', marginBottom: '10px'}),
    svg: styles => ({...styles, height: '18px', width: '20px' }),
    option: (styles) => {
        return {
            ...styles,
            textAlign: 'left',
        };
    },
};
export const BarStyling = {
    background: "white",
    padding: "0.5rem",
    width: "100%",
    border: "1px solid hsl(0, 0%, 80%)"
};

const SearchBar = ({setShowAdvancedSearch}) => {
    const {bill} = useSelector(state => state.damageBill);
    const {keyword, sortOrder, statuses} = useSelector(state => state.searchPagination);
    const dispatch = useDispatch();
    const {data, request} = useApi(getBillFilters);
    let selectOptionsData = data?.data?.map((item) => {
        return {value: item?.processing_status, label: `${item?.name}  (${item?.value})`}
    });

    useEffect(() => {
        request();
    }, [bill?.processing_status, request])

    /**
     * On first render and everytime the selected bill changes processing status
     * request to fetch again paginated bills with query from last request
     */
    useEffect(() => {
        dispatch(updateFilters({}));
    }, [dispatch])

    useEffect(() => {
        dispatch(updateStatusOfPaginatedPill({
            id: bill?.id,
            status: bill?.processing_status
        }));
    }, [dispatch, bill?.processing_status])

    const onChangeHandler = (keyword) => dispatch(updateFilters({keyword}));

    const onToggle = () => dispatch(toggleSortOrder());

    const onChangeStatusHandler = (selected) => {
        request();
        let statuses = [];
        let count = 0;
        for (let i in selected) {
            statuses[i] = selected[i].value;
            count += Number((selected[i].label.substring(selected[i].label.indexOf('(') + 1, selected[i].label.indexOf(')'))));
        }
        dispatch(saveCountedFilteredBills(count));
        dispatch(updateFilters({statuses}));
    }

    return (
        <>
            <div>
                <div className="col-md-12 select_field" style={{fontSize: '14px'}}>
                    <Select
                        onChange={e => onChangeStatusHandler(e)}
                        placeholder={"Alle"}
                        closeMenuOnSelect={false}
                        isMulti
                        options={selectOptionsData}
                        styles={colourStyles}
                        value={selectOptionsData && selectOptionsData.filter(item => statuses.includes(item.value))}
                        onFocus={() => setShowAdvancedSearch(false)}

                    />
                </div>
            </div>
            <div style={{display: "flex", gap: '0.5rem', padding: '10px 15px'}}>
                <input
                    type={"text"}
                    style={BarStyling}
                    key="random1"
                    value={keyword}
                    onChange={e => onChangeHandler(e.target.value)}
                    placeholder={"Rechnung suchen"}
                    className={"searchBar"}
                    onFocus={() => setShowAdvancedSearch(false)}
                />
                <Button variant="primary"
                        onClick={onToggle}>{sortOrder === 'asc' ? <BsSortUp/> : <BsSortDown/>}</Button>
            </div>
        </>
    );
}

export default SearchBar;
