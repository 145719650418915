// @flow
import axios, {AxiosResponse} from "axios";

export const damageBillUrl = `/api/damagebills`;
export const documentUrl = `${damageBillUrl}/document`;
export const byDocumentUrl = `${damageBillUrl}/bydocument`;
export const ocrUrl = `${damageBillUrl}/ocrdocuments`;
export const sparePartsCoverageUrl=`/api/coverages/spareparts`;
export const damagesUrl = `/api/damages`;
export const contractsUrl = `/api/warrantycontracts`
export const carDealersUrl = `/api/cardealers`;
export const externalProvidersUrl = `${carDealersUrl}/externalproviders`;
export const sepaUrl = '/api/sepafiles';
export const authUrl = '/api/users';
export const countriesUrl = '/api/countries';
export const permissionsUrl = '/api/permissions';
export const rolesUrl = '/api/roles';

export type AuthResponse = AxiosResponse<{ code: number, access_token: string, user_id: number }>

class ApiService {
    name = Date.now()
    client = axios.create({
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            'Content-type': 'multipart/form-data'
        },
    });
    cancel;
    headers: {};
    isRefreshingAccessToken = false;
    refreshSubscribers = [];

    #accessToken = '';

    constructor(lgCb: () => void) {

        this.logout = lgCb;
        /**
         * Configuring interceptor and send accessToken on every request
         */
        this.client.interceptors.request.use(
            (config) => {
                const token = this.accessToken;
                if (token) {
                    config.headers["Authorization"] = 'Bearer ' + token;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        this.client.interceptors.response.use(
            (res) => {
                return res;
            },
            async (err) => {
                if (err?.message === 'cancel') return Promise.reject(err)
                const originalConfig = err.config;
                if (originalConfig.url !== `${authUrl}/login` && originalConfig.url !== `${authUrl}/accesstokenrefresh` && err.response) {
                    // Access Token was expired or doesn't exist
                    if ((err.response.status === 401 || err.response.status === 404) && !originalConfig._retry) {
                        originalConfig._retry = true;
                        if (!this.isRefreshingAccessToken) {
                            this.isRefreshingAccessToken = true;
                            this.client.post(`${authUrl}/accesstokenrefresh`).then((rs: AuthResponse) => {
                                this.isRefreshingAccessToken = false;
                                const {access_token} = rs.data;
                                this.accessToken = access_token;
                                this.onRefreshed(access_token)
                            }).catch(_error => {
                                return Promise.reject(_error);
                            })
                        }

                        return new Promise(async resolve => {
                            this.subscribeTokenRefresh(token => {
                                // replace the expired token and retry
                                originalConfig.headers['Authorization'] = 'Bearer ' + token;
                                resolve(this.client(originalConfig));
                            });
                        });
                    } else if (err.response.status === 401) { // Refresh Token has expired
                        // localStorage.removeItem('jwt')
                        console.log(window)
                        this.logout(Date.now().toString())
                        // window.location.href = '/'; //Will take you to Google.
                    }
                }

                return Promise.reject(err);
            }
        );
    }

    get accessToken() {
        return this.#accessToken;
    }

    set accessToken(token: string) {
        console.log('new token set', token);
        this.#accessToken = token;
    }

    onRefreshed(token: string) {
        this.refreshSubscribers.map(cb => cb.call(this, [token]));
        this.refreshSubscribers = [];
    }

    subscribeTokenRefresh(cb) {
        this.refreshSubscribers.push(cb);
    }

    checkForUser() {
        return this.client.post(`${authUrl}/accesstokenrefresh`);
    }

    createBill(fd: FormData) {
        return this.client.post(byDocumentUrl, fd, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getBill(billId: string) {
        return this.client.get(`${damageBillUrl}/${billId}`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    authorizeBill(billId: string) {
        return this.client.put(`${damageBillUrl}/${billId}/authorize`, new FormData(), {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }


    declineBill(billId: string) {
        return this.client.put(`${damageBillUrl}/${billId}/decline`, new FormData(), {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    prepareBillForPayment(billId: string) {
        return this.client.put(`${damageBillUrl}/${billId}/prepareforpayment`, new FormData(), {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    updateBill(billId: string, fd: FormData) {
        return this.client.put(`${damageBillUrl}/${billId}`, fd, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getBillReport(billId: string) {
        return this.client.get(`${damageBillUrl}/${billId}/invoicereport`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getBillDocs(billId: string) {
        return this.client.get(`${damageBillUrl}/${billId}/documents?filters[0][property]=document_type&filters[0][operator]=eq&document_type&filters[0][value]=1`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getBillDocData(docHash: string) {
        return this.client.get(`${documentUrl}/${docHash}`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }


    getBillManualSetupData(billId: string) {
        return this.client.get(`${damageBillUrl}/${billId}/manuallysetupdata`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    updateBillManualSetupData(billId: string, fd: FormData) {
        return this.client.put(`${damageBillUrl}/${billId}/manuallysetupdata`, fd, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    searchBills(queryString: string, pageNumber: number) {
        return this.client.get(`${damageBillUrl}${queryString}`, {
            params: {
                page: pageNumber
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    searchDeductionCode(q: string) {
        return this.client.get(`${damageBillUrl}/deductioncodes`, {
            params: {
                search: q
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    login(username: string, password: string) {
        const fd = new FormData();
        fd.append('username', username);
        fd.append('password', password);
        return this.client.post(`${authUrl}/login`, fd, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    sendBillsToOCR(billIds: (number[] | string[])) {
        const fd = new FormData();
        billIds.forEach(billId => {
            fd.append('bills_versions[' + billId + ']', billId);
        })
        return this.client.put(ocrUrl, fd, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    requestForgottenPasswordEmail(toEmailAddress: string): Promise<AxiosResponse> {
        const f = new FormData();
        f.append('email', toEmailAddress);
        return this.client.post(`${authUrl}/forgottenpassword`, f, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    checkResetPasswordToken(token: string): Promise<AxiosResponse> {
        const f = new FormData();
        f.append('token', token);
        return this.client.post(`${authUrl}/checktoken`, f, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    changePassword(token: string, p: string, rP: string): Promise<AxiosResponse> {
        const f = new FormData();
        f.append('token', token);
        f.append('password', p);
        f.append('repeatPassword', rP);
        return this.client.post(`${authUrl}/changepassword`, f, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }


    getSepafiles(pageNumber?: number): Promise<AxiosResponse> {
        return this.client.get(`${sepaUrl}`, {
            headers: {
                ...this.headers
            },
            params: {
                page: pageNumber
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getSepaXmlFile(sepaFileId: number): Promise<AxiosResponse<{ file: string, filename: string, downloads_counter: number }>> {
        return this.client.get<{ file: string, filename: string, downloads_counter: number }>(`${sepaUrl}/${sepaFileId}/xml`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getSepaFileDownloads(sepaFileId: number): Promise<AxiosResponse<{ file: string, filename: string, downloads_counter: number }>> {
        return this.client.get<{ file: string, filename: string, downloads_counter: number }>(`${sepaUrl}/${sepaFileId}/downloads`, {
            headers: {
                ...this.headers,
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    sendBillsForPayment(billIds: number[], selectAll: boolean): Promise<AxiosResponse> {
        let billIdsStr = billIds.join(',');
        const d = new FormData();
        d.append('bills', billIdsStr);
        d.append('all_selected', selectAll)
        return this.client.put(`${sepaUrl}`, d, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c),
            timeout: 60000
        })
    }

    getBillsForPayment(pageNumber?: number) {
        return this.client.get(`${damageBillUrl}/preparedforpayment`, {
            headers: {
                ...this.headers
            },
            params: {
                page: pageNumber
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getBillItems(billId: number) {
        return this.client.get(`${damageBillUrl}/${billId}/items`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }




    getDamage(damageId: number) {
        return this.client.get(`${damagesUrl}/${damageId}`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getPreviousDamage(damageId: number, contractId: number) {
        return this.client.get(`${damagesUrl}/${damageId}/${contractId}/previousdamage`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getDamages({
                   damageNumber,
                   contractNumber,
                   chassisNumber
               }: { damageNumber: number, contractNumber: number, chassisNumber: number }): Promise<AxiosResponse> {
        return this.client.get(`${damagesUrl}/search`, {
            headers: {
                ...this.headers
            },
            params: {
                damage_number: damageNumber,
                contract_number: contractNumber,
                chassis_number: chassisNumber
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    bindClaimToBill(damageBillId: number, newDamageId: number): Promise<AxiosResponse> {
        const d = new FormData();
        d.append('damage_id', newDamageId)
        return this.client.put(`${damageBillUrl}/${damageBillId}/updatedamageid`, d, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getWarrantyContract(contractId: number): Promise<AxiosResponse> {
        return this.client.get(`${contractsUrl}/${contractId}`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getWarrantyContractMaterialWageReimbursements(contractId: number, mileAge: number) {
        return this.client.get(`${contractsUrl}/${contractId}/materialwagereimbursments/${mileAge}`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getContractSpentAmount(contractId: number) {
        return this.client.get(`${damagesUrl}/${contractId}/spentamount`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    searchCarDealers(keyword: string) {
        return this.client.get(`${carDealersUrl}?filters[0][property]=name&filters[0][operator]=l&filters[0][value]=${keyword}`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getCarDealer(carDealerId: number) {
        return this.client.get(`${carDealersUrl}/${carDealerId}`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getCarDealerBankAccounts(carDealerId: number) {
        return this.client.get(`${carDealersUrl}/${carDealerId}/bankaccounts`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }

    getCarDealerContacts(carDealerId: number) {
        return this.client.get(`${carDealersUrl}/${carDealerId}/contacts`, {
            headers: {
                ...this.headers
            },
            cancelToken: new axios.CancelToken(c => this.cancel = c)
        })
    }
}

export default ApiService;
