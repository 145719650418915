// @flow
import * as React from 'react';
import {Button} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux";
import {MdDelete} from "react-icons/md";
import {toggleDelete} from "../../features/DamageBill/bill.actions";
import BillDocumentTranslations from "../../translations/BillDocument";


export function DeleteBillButton() {
    const {bill, hasEditRight} = useSelector(state => state.damageBill)
    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(toggleDelete({
            billId: bill.damage_bill_id
        }))
    }
    if(bill?.processing_status !== -1 && !hasEditRight) return null

    return (<Button variant={bill?.processing_status === -1 ? 'outline-danger' : 'danger'}
                    size={'sm'}
                    style={{
                        display: 'flex', alignItems: 'center', marginRight: 'auto',
                    }}
                    onClick={toggle}
    >
        <MdDelete
            style={{
                cursor: 'pointer', width: "20.2px", height: "20.2px", objectFit: "contain",
            }}
        />

        {bill?.processing_status === -1 ? BillDocumentTranslations.de.bill_undelete : BillDocumentTranslations.de.bill_delete}
    </Button>);
}
