import {BsPencilSquare, BsFillPersonLinesFill} from "react-icons/bs";
import * as React from "react";

export const ListRow = React.forwardRef(({index, item, onEdit, onEditRoles},ref) => {
    return(
        <tr ref={ref} className={"Table-Row someClass"} id={"damage-bill-items-" + index}>
            <td>{item.first_name + ' ' + item.last_name}</td>
            <td>{item.email}</td>
            <td></td>
            <td className={"Last-Table-Cell"}>
                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Roles"}
                        onClick={onEditRoles}>
                    <BsFillPersonLinesFill
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                </button>
                <button style={{border: "none", background: "unset", float: "right"}} data-itemid={item.id}
                        title={"Edit"}
                        onClick={onEdit}>
                    <BsPencilSquare
                        style={{
                            color: "gray",
                            background: "unset",
                            margin: "0 0 0 0px"
                        }}
                        className={"Icon-Decline"}/>
                </button>
            </td>
        </tr>
    );

});