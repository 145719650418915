import {useCallback, useEffect, useRef, useState, useMemo} from "react";
import Modal from "react-modal";
import {Form, Button} from "react-bootstrap";
import * as React from "react";
import {UserForm} from "./UserForm";
import useApi from "../../hooks/useApi";
import {
    createUser,
    getUserRoles,
    getUsers,
    updateUser,
    updateUserRoles
} from "../../services/API/users.service";
import "./Users.css";
import {useQuery} from 'react-query'
import {ListRow} from "./ListRow";
import {ListRowCheck} from "../permissions/ListRowCheck";
import InView from "../InView";
import {useSearchPaginator} from "../contract/BillsList/useSearchPaginator";
import {getRoles} from "../../services/API/roles.service";
import toast, {Toaster} from "react-hot-toast";

function getInBetweenPages(first: number, last: number, curr: number) {
    const options = [...Array(last).keys()].map((i) => Number(i + first));
    const front = options.slice(0, 3);
    const back = options.slice(last - 3, last);
    const currFront = options.slice(Math.max(curr - 2, 1), Math.min(curr + 1, last));

    return [...new Set([...front, ...back, curr, ...currFront])].sort((a, b) => a - b);
}

const Pagination: React.FC<{
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    pages?: number;
    hasNext?: object;
}> = ({page, setPage, pages}) => {
    const pagesBetween = useMemo(() => getInBetweenPages(1, pages || 1, page || 1), [
        pages, page
    ]);

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 5
        }}>
            {pagesBetween.map((pageB) => (
                <Button key={pageB} disabled={pageB === page}
                        variant="outline-primary"
                        onClick={() => setPage(pageB)}
                        size={'sm'}>
                    {pageB}
                </Button>
            ))}
        </div>
    );
};
export default Pagination;

const initState = {
    id: 0,
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    title_id: 0,
    email: '',
    mobile: '',
    phone: '',
    address: '',
    zip_code_id: 0,
    language_id: 0,
    is_active: true,
    is_deleted: false,
    working_countries: [],
};

export const useCarDealers = (page: number, filter?: string) => {
    const q = useQuery(
        ['users', filter || 'all', page],
        () => getUsers(filter || '', page),
        {
            keepPreviousData: true,
        },
    );

    return {
        ...q,
        data: q.data?.data,
        pages: q.data?.data._page_count,
        hasNext: q.data?.data._links.next,
        hasPrev: q.data?.data._links.prev,
    };
};


export function List() {
    let [userState, setUserState] = useState(initState);

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const {data: users, pages, hasNext} = useCarDealers(page, keyword);

    // each time filterChanges, page needs to restart from 1
    useEffect(() => {
        setPage(1);
    }, [keyword]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
    const userRolesFormRef = useRef();
    let [checkedIds, setCheckedIds] = useState([]);
    const {request: addUserRequest, error: addUserError, data: addUserData} = useApi(createUser);
    const {request: updateUserRequest, error: updateUserError, data: updateUserData} = useApi(updateUser);
    const {data: addUserRolesData, request: updateUserRolesRequest, error: addUserRolesError} = useApi(updateUserRoles);
    const {data: getUserRolesData, request: getUserRolesRequest, error: getUserRolesError} = useApi(getUserRoles);

    const [d] = useState('')
    const [statuses] = useState([]);
    const [, hasErrorRoles, allRoles, hasMoreRoles, nextPageRoles] = useSearchPaginator(d, statuses, getRoles);

    const requestNextPageForRoles = useCallback(() => {
        if (hasMoreRoles && !hasErrorRoles) {
            nextPageRoles()
        }
    }, [hasMoreRoles, hasErrorRoles, nextPageRoles]);

    const toggleModalRoles = useCallback(() => {
        setIsOpenRolesModal(isOpenRolesModal => !isOpenRolesModal);
    }, []);

    const toggleModal = useCallback(() => {
        if (!isOpen) {
            setUserState({...initState});
        }
        setIsOpen(isOpen => !isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!updateUserData) return;
        console.log(updateUserData)
        if(updateUserData && updateUserData.code === 200) {
            toast.success(updateUserData.message);
            toggleModal(!isOpen);
        }
        else {
            updateUserError && toast.error(updateUserError);
            toast.dismiss(updateUserError);
        }
        }, [updateUserError, updateUserData])

    useEffect(() => {
        if (!addUserData) return;
        if(addUserData && addUserData.code === 200) {
            toast.success(addUserData.message);
            toggleModal(!isOpen);
        }
        else {
            addUserError && toast.error(addUserError);
            toast.dismiss(addUserError);
        }
    }, [addUserError, addUserData])

    useEffect(() => {
        if (addUserRolesData !== null && addUserRolesData.code === 200) {
            toggleModalRoles(true);
        } else if (addUserRolesError) {
            alert(addUserRolesError);
        }
    }, [addUserRolesData, toggleModalRoles, addUserRolesError]);

    useEffect(() => {
        if (!getUserRolesData) return;
        if (getUserRolesError) {
            toast.error(getUserRolesError);
        } else {
            if (!getUserRolesData._embedded) return;
            let tmpCheckedIds = getUserRolesData._embedded.records.map(item => item.id);
            setCheckedIds(tmpCheckedIds);
            toggleModalRoles();
        }
    }, [getUserRolesData, toggleModalRoles, getUserRolesError]);

    const onUserRolesFormSubmit = useCallback((e) => {
        e.preventDefault();
        const data = new FormData(userRolesFormRef.current);
        data.set("role_ids", checkedIds.join());
        updateUserRolesRequest({data, id: data.get("id")});
    }, [updateUserRolesRequest, checkedIds]);

    const onUserFormSubmit = useCallback(async (user) => {
        if (user.id > 0) {
            await updateUserRequest({user, id: user.id});
            // refresh();
        } else {
            await addUserRequest({user});
            // refresh();
        }
    }, [updateUserRequest, addUserRequest]);

    const loadForEdit = useCallback(({password, ...rest}) => {
        setUserState({...rest});
        setIsOpen(true);
    }, []);

    const loadRoles = useCallback((item) => {
        setUserState({...item});
        setCheckedIds([]);
        getUserRolesRequest({id: item.id});
    }, [getUserRolesRequest]);

    const handleOnCheckChange = (id, isChecked) => {
        let tmpCheckedIds = checkedIds;
        if (isChecked) {
            tmpCheckedIds.push(id);
        } else {
            const index = tmpCheckedIds.indexOf(id);
            if (index !== -1) {
                tmpCheckedIds.splice(index, 1);
            }
        }
        setCheckedIds(tmpCheckedIds);
    }

    return (
        <div className={"list-container"}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    position: 'relative',
                    padding: '0.5em',
                    background: 'var(--app-bg-color)',
                    borderRadius: '20px',
                }}
            >
                <div className={"form-group"}>
                    Search by user name: <input type={"text"} className={"form-control"} name={"keyword"}
                                                onChange={(e) => setKeyword(e.target.value)} value={keyword}/>
                </div>
            </div>
            <Toaster />
            {/*<div className={"toolbox"}>*/}
            {/*    <button type="button" className={"Button-Add btn btn-primary"} onClick={toggleModal}>Add user</button>*/}
            {/*</div>*/}
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role(s)</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !!users && users._embedded.records.map((item, index) => {
                            return (
                                <ListRow key={"user_item_" + index} item={item} index={index}
                                         onEdit={loadForEdit.bind(this, item)}
                                         onEditRoles={loadRoles.bind(this, item)}/>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: '1rem 0',
                gap: '1em'
            }}>
                <span>
                    Pages:
                </span>
                <Pagination page={page} setPage={setPage} hasNext={hasNext} pages={pages}/>
            </div>

            <Modal isOpen={isOpen}
                   onRequestClose={toggleModal}
                   overlayClassName={"modal-overlay"}
                   className={"modal-wide"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="User">
                <UserForm toggleModal={toggleModal} formSubmit={onUserFormSubmit} userData={userState}/>
            </Modal>
            <Modal isOpen={isOpenRolesModal}
                   className={"modal-normal modal-height"}
                   onRequestClose={toggleModalRoles}
                   overlayClassName={"modal-overlay"}
                   appElement={document.getElementsByTagName('body')}
                   contentLabel="Role permissions">
                <Form id={"userRolesForm"} ref={userRolesFormRef} onSubmit={onUserRolesFormSubmit}
                      className={"role-form"}>
                    <input type={"hidden"} name={"id"} value={userState.id}/>
                    <div className={"form-group role-form-table"}>
                        <table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Role name</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                allRoles && allRoles.map((item, index) => {
                                        return (
                                            (index !== Math.round(allRoles.length / 2)) ?
                                                <ListRowCheck key={"role_item_" + index} item={item} index={index}
                                                              stateChanger={handleOnCheckChange}
                                                              isSelected={checkedIds.includes(item.id)}/>
                                                : <InView as={ListRowCheck} key={"role_item_" + index} item={item}
                                                          index={index} stateChanger={handleOnCheckChange}
                                                          isSelected={checkedIds.includes(item.id)}
                                                          onceOnIntersect={requestNextPageForRoles}/>

                                        )
                                    }
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className={"form-buttons-wrapper"}>
                        <button className={"btn btn-primary form-buttons"}>Save</button>
                        <button onClick={toggleModalRoles} className={"btn btn-danger form-buttons"}>Cancel</button>
                    </div>
                </Form>
            </Modal>
        </div>
    );
}
