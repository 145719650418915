import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import useApi from "../../../hooks/useApi";
import {useSelector} from "react-redux";
import {sendEmailsPaymentLetter} from "../../../services/API/damageBills.service.js";
import {GeneralTranslations} from "../../../translations/General/GeneralTranslations";
import {useForm} from "react-hook-form";

const PaymentLetterModal = ({
                                show, onClose, changeIndicator
                            }: { show: boolean, onClose: () => void, changeIndicator: () => void }) => {
    const {bill} = useSelector(state => state.damageBill)
    const {handleSubmit} = useForm();
    const {
        data: dataEmail,
        request: requestSendEmails,
        error: errorEmails,
        loading: loadingEmails
    } = useApi(sendEmailsPaymentLetter);

    const [textAreaValue, setTextAreaValue] = useState('');
    const [errors, setErrors] = useState(errorEmails);
    const [successMessage, setSuccessMessage] = useState('');
    const sendEmails = () => {
        requestSendEmails({
            id: bill?.damage_bill_id,
            textValue: textAreaValue
        });
        // dataEmail?.code === 200 && setTextAreaValue('') && setSuccessMessage(dataEmail?.message);
        // dataEmail?.code === 200 ? setErrors(errorEmails) : (setErrors(errorEmails) && setSuccessMessage(''));
    }
    useEffect(() => {
        if (dataEmail?.code === 200) {
            setTextAreaValue('');
            setErrors('');
            setSuccessMessage(dataEmail?.message);
            changeIndicator();
        }
    }, [dataEmail])
    useEffect(() => {
        if (dataEmail?.code !== 200) {
            setErrors(errorEmails);
        }
    }, [errorEmails])
    useEffect(() => {
        setErrors('');
        setTextAreaValue('');
        setSuccessMessage('');
    }, [onClose])

    return (<Modal className={"wide-modal"} show={show} onHide={() => {
        setErrors('');
        setTextAreaValue('');
        setSuccessMessage('');
        onClose();
    }}>
        <Modal.Header closeButton>
            <Modal.Title>Zahlungsbrief erneut senden</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className={"SelectDocument"}
                 style={{
                     display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
                 }}>
                <Form.Group controlId="formBasicEmail" style={{width: '88%'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '0rem'}}>
                    <Form.Label><span style={{
                        textTransform: 'capitalize', fontSize: '1.2rem'
                    }}>Emails</span></Form.Label>

                    <span style={{
                        fontSize: '1rem'
                    }}>* E-Mail-Adressen mit Komma getrennt einfügen</span>
                    </div>
                    <Form.Control as="textarea" rows={3} value={textAreaValue}
                                  onChange={(e) => setTextAreaValue(e.target.value)}/>

                </Form.Group>

                <div style={{
                    gap: '1rem',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {errors && dataEmail?.code !== 200 && <Form.Control.Feedback type="text"
                                                                                 style={{color: 'red'}}>{errors}</Form.Control.Feedback>}

                    {dataEmail?.code === 200 && <Form.Control.Feedback type="text"
                                                                       style={{color: 'green'}}>{successMessage}</Form.Control.Feedback>}
                    <Button
                        variant="primary"
                        disabled={loadingEmails}
                        onClick={handleSubmit(sendEmails)}
                    >{!loadingEmails ? ' Senden und speichern' : 'Send...'}</Button>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" style={{
                textTransform: 'capitalize'
            }} onClick={() => {
                setErrors('');
                setTextAreaValue('');
                setSuccessMessage('');
                onClose();
            }}>{GeneralTranslations.de.label_cancel}</Button>
        </Modal.Footer>
    </Modal>)
}


export default PaymentLetterModal;