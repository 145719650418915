//@flow

class DocumentService {

    static base64ToBlob(base64, type = "application/octet-stream") {
        const binStr = Buffer.from(base64, 'base64').toString('binary');
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], {type: type});
    }

    static requestDownload(base64String, filename = 'rgis-report', type='application/pdf') {
        const pdfBlob = this.base64ToBlob(base64String, type);
        const blobUrl = window.URL.createObjectURL(pdfBlob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.setAttribute('download', filename)
        link.click();
        link.remove();
        URL.revokeObjectURL(blobUrl);
    }
}

export default DocumentService;