//@flow
import {Form} from "react-bootstrap";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import DamageInfo from "../DamageInfo";
import {Loader} from "../../general/Loader";
import {useDispatch, useSelector} from "react-redux";
import {FormBills} from "./FormBills";
import {DeclineBillButton} from "../../billsAdd/DeclineBillButton";
import {AuthorizeBillButton} from "../../billsAdd/AuthorizeBillButton";
import {PrepareBillForPaymentButton} from "../../billsAdd/PrepareBillForPaymentButton";
import {SaveBillB} from "../../billsAdd/SaveBillB";
import {ManualSetupSubmitButton} from "./FormBills/ManualSetupSubmitButton";
import {updateBill} from "../../../features/DamageBill/bill.actions";
import {DeleteBillButton} from "../../billsAdd/DeleteBillButton";
import {BILL_PROCESSING_STATUSES} from "../../StatusContext";
import {ResolveBillButton} from "../../billsAdd/ResolveBillButton";
import {ErrorMessageModal} from "../../general/ErrorMessageModal/ErrorMessageModal";
import {convertForeignToDomesticCurrency} from "../../../utils/damageItemsCalculations";

export const BillFormContext = React.createContext<>({})

export function BillForm() {

    const {
        bill,
        error,
        isLoading,
        allowPaymentButton,
        allowAuthorizeButton,
        allowDeclineButton,
        isManualSetup,
        allowResolveButton,
        isReadOnly, hasEditRight, validationCodes, payout_inconsistencies,
        validation_codes_warning, currency, maxPaymentLimit, paymentTotal
    } = useSelector(state => state.damageBill)
    const damage= useSelector(state => state.damageBill.damage)

    const dispatch = useDispatch();

    const [billFormClassNameForZoomedItems, setBillFormClassNameForZoomedItems] = useState('notZoomedItems');
    const [manualHeaderId, setManualHeaderId] = useState(undefined);
    const [manualPositionsId, setManualPositionsId] = useState([]);

    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if ((validationCodes && validationCodes.length > 0) || payout_inconsistencies !== '' || validation_codes_warning !== '') setShow(true);
    }, [validationCodes, payout_inconsistencies]);

    const billFormRef = useRef<HTMLFormElement>(null);

    const onBillFormSubmit = useCallback(async (e) => {
        e.preventDefault();
        dispatch(
            updateBill(
                {
                    billId: bill.damage_bill_id,
                    data: (() => {
                        const data = new FormData(billFormRef.current);
                        const l = {}
                        for (const pair of data.entries()) {
                            l[pair[0]] = pair[1];
                        }
                        return l;
                    })()
                }
            )
        )
    }, [dispatch, bill]);

    return (<BillFormContext.Provider value={{
        setBillFormClassNameForZoomedItems,
        manualHeaderId,
        manualPositionsId,
        setManualHeaderId,
        setManualPositionsId,
        billFormRef
    }}>
        <Loader isLoading={isLoading && !damage}/>
        <ErrorMessageModal show={show} setShow={setShow} formRef={billFormRef}/>
        {error
            ? <h4>Bill with id '{error}' does not exist</h4>
            : <Form id="billForm"
                    ref={billFormRef}
                    onKeyDown={(e: KeyboardEvent) => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                        }
                    }}
                    onSubmit={onBillFormSubmit}
                    className={billFormClassNameForZoomedItems}>
                <DamageInfo/>

                {/*This is for Bill Content*/}
                <div className={"InvGenerals"}>
                    <FormBills/>
                </div>


                <div className={"billButtonsHolder"}>
                    {bill?.processing_status < BILL_PROCESSING_STATUSES.BILL_PAYED && <DeleteBillButton/>}

                    {hasEditRight &&
                    <>
                        {allowDeclineButton && <DeclineBillButton/>}
                        {allowAuthorizeButton && <AuthorizeBillButton formRef={billFormRef}/>}
                        {allowPaymentButton && maxPaymentLimit < convertForeignToDomesticCurrency(paymentTotal, currency?.rate) && <PrepareBillForPaymentButton formRef={billFormRef}/>}

                        {allowResolveButton && <ResolveBillButton formRef={billFormRef}/>}

                        {!isManualSetup && !isReadOnly && <SaveBillB formRef={billFormRef}/>}

                    </>
                    }

                    {isManualSetup && <ManualSetupSubmitButton/>}
                </div>

            </Form>}
    </BillFormContext.Provider>)

}
